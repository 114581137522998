import OMPiwik from './OMPiwik';

export function track(eventName: string, action: string | number) {
  // if (process.env.NODE_ENV !== 'production') {
  //   console.log('track', eventName, attributes);
  // }

  // use matomo only in production environment
  if (process.env.NODE_ENV === 'production') {
    OMPiwik.push(['trackEvent', eventName, action]);
  }
}
