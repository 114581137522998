import { ConfigurationResponseFacetMap } from './ConfigurationResponseFacetMap';
import { ConfigurationResponseFilterMap } from './ConfigurationResponseFilterMap';
import { ConfigurationResponseHighlightingMap } from './ConfigurationResponseHighlightingMap';
import { ConfigurationResponseQueryMap } from './ConfigurationResponseQueryMap';
import { ConfigurationResponseSortMap } from './ConfigurationResponseSortMap';

export interface SearchConfigurationResponse {
  readonly facetConfigurations: ConfigurationResponseFacetMap;
  readonly filterConfigurations: ConfigurationResponseFilterMap;
  readonly highlightingConfiguration: ConfigurationResponseHighlightingMap;
  readonly queryConfigurations: ConfigurationResponseQueryMap;
  readonly sortConfigurations: ConfigurationResponseSortMap;
  // readonly suggesters: any; // sent in the response but not yet used
}

export const emptySearchConfigurationJson = {
  facetConfigurations: {},
  filterConfigurations: {},
  highlightingConfiguration: {},
  queryConfigurations: {},
  sortConfigurations: {},
};
