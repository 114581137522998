import { call, put, takeLatest } from 'redux-saga/effects';
import * as api from '../../../api';
import { ErrorsEnum, handleRequestError } from '../../../model/api/request';
import { parseSuggestions } from '../../../model/autosuggest/parseSuggestions';
import { actions } from '../slice';

export const autosuggestSagaKey = 'autosuggestSaga';
export function* autosuggestSaga() {
  yield takeLatest(actions.loadSuggestions.type, loadSuggestions);
}

export function* load(action) {
  const { term } = action.payload;
  if (term === '') {
    return { suggestions: [] };
  }

  const jsonResponse = yield call(api.fetchSuggestions, term);
  if (!jsonResponse) {
    return { error: ErrorsEnum.NoDataFound };
  }
  return { suggestions: parseSuggestions(jsonResponse) };
}

function* loadSuggestions(action) {
  try {
    const response = yield call(load, action);
    if (response.error) {
      throw new Error(response.error);
    }
    yield put(actions.loadSuggestionsSuccess(response));
  } catch (error: any) {
    yield call(handleRequestError, actions.loadSuggestionsError, error);
  }
}
