import { createSlice } from 'utils/@reduxjs/toolkit'; // importing from `utils` is more type-safe
import { ConfigState } from './types';

const staticConfig: any = window['appConfig'];

export let RELATIONS_GROUP_SIZE = staticConfig?.relationsGroupSize || 5;
export let HIERARCHY_GROUP_SIZE = staticConfig?.hierarchyGroupSize || 7;
export let INFOPANEL_ROWS = staticConfig?.infopanelRows || 5;
// testing util
export function setMaxRelationsGroup(value: number) {
  RELATIONS_GROUP_SIZE = value;
}
export const DEFAULT_PAGE_SIZE = 25;

export const initialState: ConfigState = {
  api: {
    searchServiceUrl: staticConfig?.apiUrl || '',
    version: staticConfig?.apiVersion || '',
    relationsGroupSize: staticConfig?.relationsGroupSize || 5,
    imageServiceUrl: staticConfig?.imageServiceUrl || '',
  },
  devData: staticConfig?.devData || false,
  matomo: {
    url: staticConfig?.matomoUrl || '',
    siteId: staticConfig?.matomoSiteId || '',
  },
  appMetadata: {
    release: staticConfig?.release || '',
  },
  searchResultsPerPage: staticConfig?.searchResultsPerPage || [25],
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
});

// Exports
export const { name: sliceKey } = configSlice;
export default configSlice.reducer;
