import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { RequestErrorPayload } from '../../model/api/request';
import { AutoSuggestItem } from '../../model/autosuggest';
import { AutoSuggestState } from './types';

export const initialState: AutoSuggestState = {
  request: {
    loading: false,
    error: null,
  },
  term: '',
  suggestions: [],
};

const key = 'autosuggest';
const autoSuggestSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    loadSuggestions(state, action: PayloadAction<{ term: string }>) {
      state.request.loading = true;
      state.request.error = null;
      state.term = action.payload.term;
    },
    loadSuggestionsSuccess(
      state,
      action: PayloadAction<{ suggestions: AutoSuggestItem[] }>,
    ) {
      state.request.loading = false;
      state.request.error = null;
      state.suggestions = action.payload.suggestions;
    },
    loadSuggestionsError(
      state,
      action: PayloadAction<{ error: RequestErrorPayload }>,
    ) {
      state.term = '';
      state.request.loading = false;
      state.request.error = action.payload.error;
    },
  },
});

export const { name: sliceKey, actions } = autoSuggestSlice;
export default autoSuggestSlice.reducer;
