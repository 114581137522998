import styled from 'styled-components/macro';
import { pageContentStyles } from '../PageLayout/style';

export const PositionRelative = styled.div`
  grid-area: page--scrollUp;
  ${pageContentStyles};
  position: relative;
`;

export const PositionAbsolute = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

interface ButtonProps {
  $visible: boolean;
}
export const Button = styled.button<ButtonProps>`
  position: fixed;
  bottom: ${props => props.theme.spacing._32}rem;
  right: ${props => props.theme.spacing._14}rem;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;

  transform: scale(${props => (props.$visible ? 1 : 0)});
  transition: all 0.5s ease-in-out;
  visibility: ${props => (props.$visible ? '$visible' : 'hidden')};
  z-index: 1;

  svg .background {
    transition: fill 0.1s ease-in-out;
  }
  svg .arrow {
    transition: stroke 0.1s ease-in-out;
  }
  &:hover svg {
    .background {
      fill: ${props => props.theme.color.blue_logo};
    }
    .arrow {
      stroke: ${props => props.theme.color.white};
    }
  }
`;
