import {
  ConfigurationResponseTermsFacet,
  FacetResponseItem,
  SearchRequestFacet,
} from '../../api/responses';
import { Facet } from './Facet';
import { Bucket } from '../buckets';
import { TimelineBucket } from '../../timeline/TimelineChart';

export class YearRangeFacet extends Facet {
  protected _numBuckets: number = 0;

  public init(
    facetResponseJson: FacetResponseItem,
    facetRequestJson: SearchRequestFacet,
    config: ConfigurationResponseTermsFacet,
  ): void {
    this._numBuckets = this.buckets.length;
  }

  isEmpty(): boolean {
    if (this._numBuckets === 0) {
      return true;
    }
    return !this.buckets.some((b: Bucket) => b.count > 0);
  }

  public dateToYear(date: string): string {
    // Example value: -9599-01-01T00:00:00Z
    const pattern = /^(-?\d{4})-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z?/;
    const regexp = new RegExp(pattern);
    const result: RegExpMatchArray | null = date.match(regexp);
    if (result && result.length > 1) {
      return result[1];
    }
    return date;
  }

  public getMinYear(): string {
    if (this.isEmpty()) {
      return '-9999';
    }
    return this.dateToYear(this.buckets[0].title);
  }

  public getMaxYear(): string {
    if (this.isEmpty()) {
      return '9999';
    }
    return this.dateToYear(this.buckets[this.buckets.length - 1].title);
  }

  public getBucketsForTimeline(): TimelineBucket[] {
    return this.buckets.map((bucket: Bucket) => {
      const year: string = this.dateToYear(bucket.value);
      return { year: parseInt(year), count: bucket.count };
    });
  }
}
