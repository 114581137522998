import styled from 'styled-components';
import { font_Link } from '../../../../styles';

export const Link = styled.a`
  ${font_Link};
  background-color: ${props => props.theme.color.white};
  position: absolute;
  top: -99999px;
  left: -99999px;

  &:focus {
    top: 10px;
    left: 10px;
  }
`;
