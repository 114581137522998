import { RequestStatusType } from '../../model/api/request';

export enum TimelineNames {
  start = 'start',
  end = 'end',
}
export type TimelineValueType = TimelineNames.start | TimelineNames.end;

export interface TimelineState {
  readonly view: string;
  readonly minMaxRequestStatus: RequestStatusType;
  readonly maxYear: number;
  readonly minYear: number;
  readonly sliders: {
    readonly [relationCode: string]: {
      readonly [TimelineNames.start]: number;
      readonly [TimelineNames.end]: number;
    };
  };
  readonly searchButtonEnabled: boolean;
}
