import styled, { css } from 'styled-components/macro';
import { media } from '../../../styles';
import { IconButton } from '../Button/IconButton';
import { Grid } from '../Grid';

interface OuterWrapperProps {
  $isFocused: boolean;
}
export const OuterWrapper = styled.div<OuterWrapperProps>`
  grid-area: search-box;
  position: relative;
  ${props =>
    props.$isFocused &&
    css`
      box-shadow: 0 0 4px 1px ${props.theme.color.blue_logo};
    `};
`;

export const Wrapper = styled(Grid)`
  padding: ${props => props.theme.spacing._16}rem
    ${props => props.theme.spacing._22}rem;

  background: ${props => props.theme.color.gray_bg_search_box};
  box-shadow: 1px 1px 4px 0 #000000b2 inset;

  grid-column-gap: ${props => props.theme.spacing._12}rem;
  grid-template-columns: 1fr auto auto;
  -ms-grid-columns: 1fr ${props => props.theme.spacing._12}rem auto
    ${props => props.theme.spacing._12}rem auto;

  align-items: center;
  justify-items: end;

  ${media.tablet_portrait_up`
    grid-column-gap: ${props => props.theme.spacing._13}rem;
    -ms-grid-columns: 1fr ${props => props.theme.spacing._13}rem auto
      ${props => props.theme.spacing._13}rem auto;
  `};

  ${media.tablet_landscape_up`
    grid-column-gap: ${props => props.theme.spacing._20}rem;
    -ms-grid-columns: 1fr ${props => props.theme.spacing._20}rem auto
      ${props => props.theme.spacing._20}rem auto;
  `};
`;

export const Button = styled(IconButton)`
  justify-self: end;
  color: ${props => props.theme.color.black};
  &:hover {
    color: ${props => props.theme.color.blue_logo};
  }

  &[aria-hidden='true'] {
    visibility: hidden;
  }
`;

export const Input = styled.input`
  width: 100%;
  justify-self: start;
  background-color: transparent;
  border: none;
  outline: 0;
`;
