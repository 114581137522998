import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from '../../../store';

export const selectEntityTitleData = (state: RootState) =>
  state.entityTitle || initialState;

export const selectEntityTitleById = (id: string) =>
  createSelector([selectEntityTitleData], substate => substate.titles[id]);

export const selectEntityTitleRequestError = createSelector(
  [selectEntityTitleData],
  substate => substate.request.error,
);
