import {
  emptySearchConfigurationJson,
  SearchConfigurationResponse,
} from '../../api/responses';
import { Context } from '../context';

export interface SearchConfigurations {
  [Context.default]: SearchConfigurationResponse;
}

export const initialSearchConfigurations: SearchConfigurations = {
  [Context.default]: emptySearchConfigurationJson,
};

export interface SearchContextConfigurationLoaded {
  [Context.default]: boolean;
}

export const initialSearchConfigurationLoaded: SearchContextConfigurationLoaded =
  {
    [Context.default]: false,
  };
