import { call, put, select, takeLatest } from 'redux-saga/effects';
import { SearchRequestFactory } from '../../../model/search/factories';
import { SearchRequest } from '../../../model/search/request';
import { selectRouterSearch } from '../../Router/selectors';
import { selectFilterConfigurations } from '../../SearchConfiguration';
import { actions } from '../slice';
import { goToSearchPage } from './goToSearchPage';

export const searchTermSagaKey = 'searchTermSaga';
export function* searchTermSaga() {
  yield takeLatest(actions.searchTerm.type, searchTerm);
}

function* searchTerm(action) {
  // matomo.trackEvent(matomo.SEARCH_TERM, action.payload.term);
  const urlQueryStr = yield select(selectRouterSearch);
  const filterConfigurations = yield select(selectFilterConfigurations);
  const searchRequest: SearchRequest =
    SearchRequestFactory.createFromUrlSearchParams(
      urlQueryStr,
      filterConfigurations,
    );
  searchRequest.updateQueryTerm(action.payload.term);

  if (action.payload.resetFilters) {
    searchRequest.resetFilters();
  }

  const searchQuery: string = searchRequest.buildUrlQueryString();

  yield call(goToSearchPage, action.payload.lang, searchQuery);
  yield put(actions.searchTermSuccess());
}
