import { call, put, takeLatest } from 'redux-saga/effects';
import TimelineConfig from '../../../../conf/timeline';
import * as api from '../../../api';
import { SearchResponse } from '../../../model/api/responses';
import { SearchRequestFactory } from '../../../model/search/factories';
import { SearchRequest } from '../../../model/search/request';
import { actions } from '../slice';

export const minMaxYearsSagaKey = 'minMaxYearsSaga';
export function* minMaxYearsSaga() {
  yield takeLatest(actions.fetchMinMaxYears.type, fetchMinMaxYears);
}

function* fetchMinMaxYears() {
  const searchRequest: SearchRequest = SearchRequestFactory.createEmpty();
  searchRequest.updateRows(0);
  const queryString: string = searchRequest.buildUrlQueryString();
  try {
    const response: SearchResponse = yield call(
      api.fetchTimelineMinMaxYears,
      queryString,
    );

    const minYear =
      response.facets[TimelineConfig.facets.minYear].buckets[0].val;
    const maxYear = new Date().getFullYear();

    yield put(
      actions.fetchMinMaxYearsSuccess({
        minYear: parseInt(minYear),
        maxYear: maxYear,
      }),
    );
  } catch (e) {
    // console.error(e);
    yield put(actions.fetchMinMaxYearsError());
  }
}
