import { ConfigurationResponseFacetMap } from '../../../api/responses';
import { UrlQueryBuilder } from '../../request';
import { RequestFacet } from './RequestFacet';
import { RequestFacetFactory } from './RequestFacetFactory';
import { RequestFacetTermsFacet } from './RequestFacetTermsFacet';

export class RequestFacetList {
  protected _facets: RequestFacet[] = [];
  protected _configurations: ConfigurationResponseFacetMap = {};

  get facets(): RequestFacet[] {
    return this._facets;
  }

  set facets(facets: RequestFacet[]) {
    this._facets = facets;
  }

  get configurations(): ConfigurationResponseFacetMap {
    return this._configurations;
  }

  set configurations(configurations: ConfigurationResponseFacetMap) {
    this._configurations = configurations;
  }

  public addFacet(facet: RequestFacet) {
    this.facets.push(facet);
  }

  public addToRequestQuery(
    builder: UrlQueryBuilder,
    withFacets: boolean,
  ): void {
    if (!withFacets) {
      builder.add('facets', 'false');
      return;
    }

    this.facets.forEach((facet: RequestFacet) => {
      facet.addToRequestQuery(builder);
    });
  }

  public getFacet(id: string): RequestFacet {
    const config = this.configurations[id];
    if (!config) {
      throw new Error(`No configuration found for facet id '${id}'`);
    }

    let facet = this._facets.find((facet: RequestFacet) => facet.id === id);
    if (!facet) {
      facet = RequestFacetFactory.createInstanceFrom(config);
      this.facets.push(facet);
    }
    return facet;
  }

  public setFacetLimit(facetId: string, limit: number): RequestFacetList {
    const facet: RequestFacetTermsFacet = this.getFacet(
      facetId,
    ) as RequestFacetTermsFacet;

    if (facet) {
      facet.limit = limit;
    }
    return this;
  }

  public clone(): RequestFacetList {
    const facetList = new RequestFacetList();
    facetList.facets = this._facets.map(
      (f: RequestFacet) => ({ ...f }) as RequestFacet,
    );
    facetList.configurations = { ...this.configurations };
    return facetList;
  }
}
