export class UrlQueryBuilder {
  private _params: string[];

  constructor() {
    this._params = [];
  }

  protected reset() {
    this._params = [];
  }

  public build(): string {
    if (this._params.length === 0) {
      return '';
    }
    return `?${this._params.join('&')}`;
  }

  public add(name: string, value: string | number) {
    this._params.push(`${name}=${value}`);
  }

  public addSingleton(name: string, value: string | number) {
    if (this.hasName(name)) {
      return;
    }
    this.add(name, value);
  }

  public hasName(name: string): boolean {
    return this._params.some(p => p.startsWith(`${name}=`));
  }
}
