import { call, select } from 'redux-saga/effects';
import { selectApiUrl, selectApiVersion } from '../features/Config';
import { Context, SearchContext } from '../model/search/context';
import { API_ENDPOINT } from './constants';

function* getRequestUrl(endpoint: string, context?: SearchContext) {
  const apiBaseUrl = yield select(selectApiUrl());
  const apiVersion = yield select(selectApiVersion());
  return [apiBaseUrl, apiVersion, context, endpoint].filter(Boolean).join('/');
}

export function* getAutosuggestRequestUrl() {
  const baseUrl = yield call(
    getRequestUrl,
    API_ENDPOINT.SUGGEST,
    Context.default,
  );
  return `${baseUrl}/default`;
}

export function* getEntityByIdRequestUrl(id: string) {
  const baseUrl = yield call(getRequestUrl, API_ENDPOINT.ENTITY);
  return `${baseUrl}/${id}`;
}

export function* getEntityTitleByIdRequestUrl(id: string) {
  const baseUrl = yield call(getEntityByIdRequestUrl, id);
  return `${baseUrl}/title`;
}

export function* getHierarchyRequestUrl(id: string) {
  const baseUrl = yield call(getRequestUrl, API_ENDPOINT.HIERARCHY);
  return `${baseUrl}/${id}`;
}

export function* getRelationsRequestUrl(id: string) {
  const baseUrl = yield call(getRequestUrl, API_ENDPOINT.RELATIONS);
  return `${baseUrl}/${id}`;
}

export function* getSearchRequestUrl(context: SearchContext) {
  return yield call(getRequestUrl, API_ENDPOINT.SEARCH, context);
}

export function* getFacetRequestUrl(context: SearchContext, id: string) {
  const baseUrl = yield call(getRequestUrl, API_ENDPOINT.FACET, context);
  return `${baseUrl}/${id}`;
}

export function* getConfigRequestUrl(context: SearchContext) {
  return yield call(getRequestUrl, API_ENDPOINT.CONFIG, context);
}

export function* getAppVersionRequestUrl() {
  const apiBaseUrl = yield select(selectApiUrl());
  return [apiBaseUrl, API_ENDPOINT.VERSION].join('/');
}

export function getImageRequestUrl(serviceUrl: string, id) {
  return `${serviceUrl}${id}`;
}

export function getImageMetaRequestUrl(serviceUrl: string, id) {
  return `${getImageRequestUrl(serviceUrl, id)}/info`;
}
