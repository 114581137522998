import { _t } from '../../../locales/utils';

const scope = 'SearchBox';

export const messages = {
  inputAriaLabel: _t(`${scope}.input.area.ariaLabel`),
  resetButtonAriaLabel: _t(`${scope}.reset.term.button.ariaLabel`),
  resetButtonTooltip: _t(`${scope}.reset.term.button.tooltip`),
  searchButtonAriaLabel: _t(`${scope}.search.button.ariaLabel`),
  searchButtonTooltip: _t(`${scope}.search.button.tooltip`),
};
