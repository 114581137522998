import { call, put, select } from 'redux-saga/effects';
import * as api from '../../../api';
import { ErrorsEnum } from '../../../model/api/request';
import { SearchConfigurationResponse } from '../../../model/api/responses';
import { SearchConfigurationResponseProcessor } from '../../../model/search/configuration/SearchConfigurationResponseProcessor';
import { SearchContext } from '../../../model/search/context';
import { selectCurrentSearchContext } from '../../Search';
import {
  selectIsSearchConfigurationLoaded,
  selectSearchConfiguration,
} from '../selectors';
import { actions } from '../slice';

export function* loadSearchConfigurationIfNotLoaded() {
  try {
    const isConfigurationLoaded: boolean = yield select(
      selectIsSearchConfigurationLoaded,
    );
    if (!isConfigurationLoaded) {
      yield call(loadSearchConfiguration);
    }
    return yield select(selectSearchConfiguration);
  } catch (e: any) {
    throw new Error(ErrorsEnum.NoSearchConfigurations);
  }
}

function* loadSearchConfiguration() {
  const response: SearchConfigurationResponse = yield call(
    api.fetchSearchConfiguration,
  );
  const processedResponse: SearchConfigurationResponse =
    SearchConfigurationResponseProcessor.process(response);

  const searchContext: SearchContext = yield select(selectCurrentSearchContext);
  yield put(
    actions.searchConfigurationLoadedSuccess({
      response: processedResponse,
      searchContext,
    }),
  );
}
