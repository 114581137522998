import styled, { css } from 'styled-components/macro';
import { linkInteractionStyles, linkStyles } from '../Link';

export const resetButtonStyles = css`
  background-color: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 0;
  // outline: none; TODO
`;

export const LinkButton = styled.button`
  ${resetButtonStyles};
  ${linkInteractionStyles};
  ${linkStyles};
`;

export const BlackButton = styled.button`
  ${resetButtonStyles};
  padding: ${props => props.theme.spacing._4}rem
    ${props => props.theme.spacing._14}rem;
  border: 1px solid ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.black};
  color: ${props => props.theme.color.white};
  text-decoration: none;
  &:hover {
    background-color: ${props => props.theme.color.white};
    color: ${props => props.theme.color.black};
  }
`;
