import { RequestFacetList } from '../facets';
import { Filter, FilterList } from '../filters';
import { TermsQuery } from '../query';
import { AbstractSearchRequest } from './AbstractSearchRequest';
import { UrlQueryBuilder } from './UrlQueryBuilder';

export class SearchRequest extends AbstractSearchRequest {
  public constructor(
    query: TermsQuery,
    filters: FilterList,
    facets: RequestFacetList,
    sort: string,
    start: number,
    rows: number,
  ) {
    super(query, facets, filters, sort, rows, start, 0);
  }

  public buildUrlQueryString(
    withPosition: boolean = false,
    withFacets: boolean = true,
  ): string {
    const builder = new UrlQueryBuilder();
    if (this._query) {
      this._query.addToUrlSearchParams(builder);
    }
    this.filterList.addToUrlSearchParams(builder);
    this.facetList.addToRequestQuery(builder, withFacets);

    if (this._rows > 0) {
      builder.add('rows', this._rows);
    }

    if (this._sort && this._sort !== 'score') {
      builder.add('sort', encodeURIComponent(this._sort));
    }

    let start: number = this.start;
    if (withPosition) {
      if (this.position > this.start + this.rows) {
        start = this.position - (this.position % this.rows);
      } else if (this.position < this.start) {
        start = this.position % this.rows;
      } else if (this.position === this.start) {
        start = Math.max(this.start - this.rows, 0);
      }
    }

    if (start > 0) {
      builder.add('start', start);
    }

    if (withPosition && this.position > 0) {
      builder.add('pos', this.position);
    }

    return builder.build();
  }

  public addFilter(filter: Filter, filterValue: any): SearchRequest {
    this.filterList.addFilterValue(filter, filterValue);
    this.resetStart();
    return this;
  }

  public addExclusiveFilter(
    filter: Filter,
    excludeFilterIds: string[],
    filterValue: any,
  ): SearchRequest {
    this.filterList.addExclusiveFilter(filter, excludeFilterIds, filterValue);
    this.resetStart();
    return this;
  }

  public removeFilter(filter: Filter, filterValue: string): SearchRequest {
    this.filterList.removeFilterValue(filter, filterValue);
    this.resetStart();
    return this;
  }

  public resetFilters(): SearchRequest {
    this.filterList.resetFilters();
    this.resetStart();
    return this;
  }

  public setFacetLimit(facetId: string, limit: number): SearchRequest {
    this.facetList.setFacetLimit(facetId, limit);
    return this;
  }

  public setPositionFromResultListIdx(idx: number): void {
    this.position = this.start + idx + 1;
  }

  public resolveStartFromPosition(): void {
    const page = Math.floor((this.position - 1) / this.rows);
    this.start = page > 0 ? page * this.rows : 0;
  }

  public resolveStartFromPage(page: number): void {
    this.start = page > 0 ? (page - 1) * this.rows : 0;
  }

  public updateRows(rows: number): void {
    this.rows = rows;
    this.resetStart();
  }

  public updateSort(sortId: string): void {
    this.sort = sortId;
    this.resetStart();
  }

  public updateQueryTerm(term: string): void {
    if (term && term.startsWith('http')) {
      term = `"${term}"`;
    }
    this.query.term = term;
    this.resetStart();
  }

  public resetTerm(): void {
    this.query.resetTerm();
  }

  public resolveStartAndRowsForEntityPagination() {
    this.start = Math.max(0, this.position - 2);
    this.rows = this.position > 1 ? 3 : 2;
  }

  public clone() {
    return new SearchRequest(
      this.query.clone(),
      this.filterList.clone(),
      this.facetList.clone(),
      this.sort,
      this.start,
      this.rows,
    );
  }
}
