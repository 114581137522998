import {
  ConfigurationResponseFacet,
  ConfigurationResponseTermsFacet,
  SearchRequestFacet,
} from '../../../api/responses';
import { UrlQueryBuilder } from '../../request';
import { RequestFacet } from './RequestFacet';

export class RequestFacetTermsFacet extends RequestFacet {
  protected _step: number;
  protected _limit: number;

  constructor(
    config: ConfigurationResponseFacet,
    responseRequestFacet?: SearchRequestFacet,
  ) {
    super(config.id, config.type);
    let termsConfig = config as ConfigurationResponseTermsFacet;
    this._step = termsConfig.limit;
    this._limit = termsConfig.limit;
    if (responseRequestFacet && responseRequestFacet.limit) {
      this._limit = responseRequestFacet.limit;
    }
  }

  set limit(limit: number) {
    this._limit = limit;
  }

  public addToRequestQuery(builder: UrlQueryBuilder): void {
    if (this._limit > this._step) {
      builder.add(`f.${this._id}.limit`, this._limit);
    }
  }

  get limit(): number {
    return this._limit;
  }

  get step(): number {
    return this._step;
  }
}
