import { UrlQueryBuilder } from '../request';
import { AbstractFilter, Filter } from './AbstractFilter';
import {
  DisplayFilter,
  DisplayFilterGeoNone,
  DisplayFilterType,
} from './DisplayFilter';

export type GeoNoneFilterValue = boolean;

export class GeoNoneFilter extends AbstractFilter<GeoNoneFilterValue> {
  protected _value: GeoNoneFilterValue = false;

  public init(data: any): void {
    this.value =
      data.terms && data.terms.length ? data.terms[0] === 'true' : false;
  }

  get value(): boolean {
    return this._value;
  }

  set value(value: boolean) {
    this._value = value;
  }

  public setValue(value: GeoNoneFilterValue): void {
    this.value = value.toString() === 'true';
  }

  public parseUrlSearchParams(params: URLSearchParams): void {
    this.value = params.has(this.paramName)
      ? params.get(this.paramName) === 'true'
      : false;
  }

  public addToUrlSearchParams(builder: UrlQueryBuilder): void {
    if (this._value) {
      builder.add(this.paramName, this._value.toString());
    }
  }

  public isEmpty(): boolean {
    return !this._value;
  }

  public removeValue(): Filter {
    return this.resetValues();
  }

  public resetValues(): Filter {
    this._value = false;
    return this;
  }

  public addToSelectedFilterTerms(list: DisplayFilter[]): void {
    const filter: DisplayFilterGeoNone = {
      filter: this,
      type: DisplayFilterType.geoNone,
    };
    list.push(filter);
  }

  public isValueSelected(): boolean {
    return this._value;
  }

  public getSelectedFilterTerms(): string[] {
    return [this._value.toString()];
  }

  // public toSelectedTerm(selectedTerm: string): string {
  //   return selectedTerm;
  // }

  // public valuesMatch(filter: Filter): boolean {
  //   if (!filter || !(filter instanceof GeoNoneFilter)) return false;
  //   const f: GeoNoneFilter = filter;
  //   return this._value === f.value;
  // }

  public clone(): Filter {
    const filter = new GeoNoneFilter(this.id, this.type);
    filter.value = this._value;
    return filter;
  }

  protected get paramName(): string {
    return `f.${this.id}`;
  }
}
