import { TFunction } from 'i18next';
import { joinStrings } from './stringUtils';

export function toDate(dateASddMMyy: string, glue: string = '.'): Date {
  const parts = dateASddMMyy.split(glue);
  return new Date(
    parseInt(parts[2], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[0], 10),
  );
}

export function dateToYear(date: string): string {
  // Example value: -9599-01-01T00:00:00Z
  const pattern = /^(-?\d{4})-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z?/;
  const regexp = new RegExp(pattern);
  const result: RegExpMatchArray | null = date.match(regexp);
  if (result && result.length > 1) {
    return result[1];
  }
  return date;
}

export function translateYear(year: number, t: TFunction): string {
  const prefix = year < 0 ? t('Date.before.christ.prefix') : '';
  const suffix = year < 0 ? t('Date.before.christ.suffix') : '';
  return joinStrings([prefix, Math.abs(year).toString(), suffix], ' ');
}
