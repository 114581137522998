import {
  IconBauwerkBig,
  IconBauwerkHierarchy,
  IconBauwerkFacet,
  IconGeografikumBig,
  IconGeografikumHierarchy,
  IconGeografikumFacet,
  IconOrganisationBig,
  IconOrganisationHierarchy,
  IconOrganisationFacet,
  IconPersonBig,
  IconPersonHierarchy,
  IconPersonFacet,
  IconSachbegriffBig,
  IconSachbegriffHierarchy,
  IconSachbegriffFacet,
  IconVeranstaltungBig,
  IconVeranstaltungHierarchy,
  IconVeranstaltungFacet,
  IconWerkBig,
  IconWerkHierarchy,
  IconWerkFacet,
} from '../app/components/Icon/EntityIcon/iconsWithBackground';
import {
  IconBauwerk,
  IconGeografikum,
  IconOrganisation,
  IconPerson,
  IconSachbegriff,
  IconVeranstaltung,
  IconWerk,
} from '../app/components/Icon/EntityIcon/iconsWithoutBackground';
import {
  SvgOrganisationSmall,
  SvgEventsSmall,
  SvgGeoSmall,
  SvgPersonSmall,
  SvgTermsSmall,
  SvgWorkSmall,
  SvgBuildingSmall,
  SvgRelationOpen,
  SvgRelationClose,
} from '../app/components/Icon/graphIcons';

export enum IconCodes {
  icon_b = 'icon_b',
  icon_f = 'icon_f',
  icon_g = 'icon_g',
  icon_p = 'icon_p',
  icon_s = 'icon_s',
  icon_u = 'icon_u',
  icon_gib = 'icon_g_gib',
}

export enum Satzarten {
  Organisation = 'Organisation',
  Veranstaltung = 'Veranstaltung',
  Geografikum = 'Geografikum',
  Person = 'Person',
  Sachbegriff = 'Sachbegriff',
  Werk = 'Werk',
  Bauwerk = 'Bauwerk',
}

export const IconConfiguration = {
  color: {
    [IconCodes.icon_b]: '#2A8300',
    [IconCodes.icon_f]: '#0090AF',
    [IconCodes.icon_g]: '#004FC2',
    [IconCodes.icon_p]: '#C08B02',
    [IconCodes.icon_s]: '#CC0000',
    [IconCodes.icon_u]: '#B83593',
    [IconCodes.icon_gib]: '#510083',
  },
  mapIconCodeToSatzart: {
    [IconCodes.icon_b]: Satzarten.Organisation,
    [IconCodes.icon_f]: Satzarten.Veranstaltung,
    [IconCodes.icon_g]: Satzarten.Geografikum,
    [IconCodes.icon_p]: Satzarten.Person,
    [IconCodes.icon_s]: Satzarten.Sachbegriff,
    [IconCodes.icon_u]: Satzarten.Werk,
    [IconCodes.icon_gib]: Satzarten.Bauwerk,
  },
  mapSatzartToIconCode: {
    [Satzarten.Organisation]: [IconCodes.icon_b],
    [Satzarten.Veranstaltung]: [IconCodes.icon_f],
    [Satzarten.Geografikum]: [IconCodes.icon_g],
    [Satzarten.Person]: [IconCodes.icon_p],
    [Satzarten.Sachbegriff]: [IconCodes.icon_s],
    [Satzarten.Werk]: [IconCodes.icon_u],
    [Satzarten.Bauwerk]: [IconCodes.icon_gib],
  },
  components: {
    noBackground: {
      [IconCodes.icon_b]: IconOrganisation,
      [IconCodes.icon_f]: IconVeranstaltung,
      [IconCodes.icon_g]: IconGeografikum,
      [IconCodes.icon_p]: IconPerson,
      [IconCodes.icon_s]: IconSachbegriff,
      [IconCodes.icon_u]: IconWerk,
      [IconCodes.icon_gib]: IconBauwerk,
    },
    withBackground: {
      [IconCodes.icon_b]: IconOrganisationBig,
      [IconCodes.icon_f]: IconVeranstaltungBig,
      [IconCodes.icon_g]: IconGeografikumBig,
      [IconCodes.icon_p]: IconPersonBig,
      [IconCodes.icon_s]: IconSachbegriffBig,
      [IconCodes.icon_u]: IconWerkBig,
      [IconCodes.icon_gib]: IconBauwerkBig,
    },
    satzartFacet: {
      [IconCodes.icon_b]: IconOrganisationFacet,
      [IconCodes.icon_f]: IconVeranstaltungFacet,
      [IconCodes.icon_g]: IconGeografikumFacet,
      [IconCodes.icon_p]: IconPersonFacet,
      [IconCodes.icon_s]: IconSachbegriffFacet,
      [IconCodes.icon_u]: IconWerkFacet,
      [IconCodes.icon_gib]: IconBauwerkFacet,
    },
    hierarchySmall: {
      [IconCodes.icon_b]: IconOrganisationHierarchy,
      [IconCodes.icon_f]: IconVeranstaltungHierarchy,
      [IconCodes.icon_g]: IconGeografikumHierarchy,
      [IconCodes.icon_p]: IconPersonHierarchy,
      [IconCodes.icon_s]: IconSachbegriffHierarchy,
      [IconCodes.icon_u]: IconWerkHierarchy,
      [IconCodes.icon_gib]: IconBauwerkHierarchy,
    },
    relationsGraphSVG: {
      [IconCodes.icon_b]: SvgOrganisationSmall,
      [IconCodes.icon_f]: SvgEventsSmall,
      [IconCodes.icon_g]: SvgGeoSmall,
      [IconCodes.icon_p]: SvgPersonSmall,
      [IconCodes.icon_s]: SvgTermsSmall,
      [IconCodes.icon_u]: SvgWorkSmall,
      [IconCodes.icon_gib]: SvgBuildingSmall,
      open: SvgRelationOpen,
      close: SvgRelationClose,
    },
  },
};
