import MapConfig from '../../../../conf/map';
import TimelineConfig from '../../../../conf/timeline';
import { TimelineUtils } from '../../../model/timeline/TimelineUtils';
import { DEFAULT_PAGE_SIZE } from '../../Config';
import { SearchView, SearchViews } from '../../GeneralUI';

export function prepareSearchQuery(action) {
  let searchQuery = addRowsIfNotSet(action.payload.searchQuery);
  searchQuery = addMapTimelineFacetsIfMapView(action.payload.view, searchQuery);
  return searchQuery;
}

function addRowsIfNotSet(searchQuery: string) {
  if (searchQuery.includes('rows=')) {
    return searchQuery;
  }
  return searchQuery.length === 0
    ? `?rows=${DEFAULT_PAGE_SIZE}`
    : `${searchQuery}&rows=${DEFAULT_PAGE_SIZE}`;
}

function addMapTimelineFacetsIfMapView(view: SearchView, searchQuery: string) {
  if (view !== SearchViews.mapTimeline) {
    return searchQuery;
  }
  const facetIds: string[] = [...MapConfig.facets];
  facetIds.push(MapConfig.noPositionFacet);
  facetIds.push(TimelineConfig.noRangeFacet);
  TimelineConfig.relations.forEach((relationCode: string) =>
    facetIds.push(TimelineUtils.getTimelineFacetIdHasRelation(relationCode)),
  );

  const facets = facetIds.map(
    (facetId: string) => `fct.${facetId}.enabled=true`,
  );

  const isMobileDevice = /Android|iPhone/i.test(navigator.userAgent);
  if (isMobileDevice) {
    facets.push(`fct.${TimelineConfig.facets.relation}.mobile=true`);
  }

  return [searchQuery, ...facets].join('&');
}
