export function numberToFloat(n) {
  function isFloat(value) {
    if (
      typeof value === 'number' &&
      !Number.isNaN(value) &&
      !Number.isInteger(value)
    ) {
      return true;
    }

    return false;
  }

  if (!isFloat(n)) {
    return parseFloat(n).toFixed(1);
  }

  return n;
}
