import {
  ConfigurationResponseExcludingTermsFilter,
  ConfigurationResponseTermsFilter,
  SearchConfigurationResponse,
} from '../../api/responses';
import { FilterType } from '../filters';

export class SearchConfigurationResponseProcessor {
  protected _config: SearchConfigurationResponse;

  protected constructor(config: SearchConfigurationResponse) {
    this._config = config;
  }

  public static process(
    config: SearchConfigurationResponse,
  ): SearchConfigurationResponse {
    const processor = new SearchConfigurationResponseProcessor(config);
    return processor.process();
  }

  protected process(): SearchConfigurationResponse {
    this.processFacetConfigurations();
    this.processFilterConfigurations();
    return this._config;
  }

  private processFacetConfigurations() {
    Object.keys(this._config.facetConfigurations).forEach(
      (facetId: string, idx: number) => {
        this.setFacetOrder(facetId, idx);
        this.copyFacetNamespaceToFilterConfiguration(facetId);
      },
    );
  }

  private processFilterConfigurations() {
    Object.keys(this._config.filterConfigurations).forEach(
      (filterId: string) => {
        this.createTermsFiltersFromExcludingTermsFilters(filterId);
      },
    );
  }

  private setFacetOrder(facetId: string, idx: number) {
    this._config.facetConfigurations[facetId].index = idx;
  }

  private copyFacetNamespaceToFilterConfiguration(facetId: string) {
    const filterId = this._config.facetConfigurations[facetId].filterId;
    if (
      this._config.facetConfigurations[facetId].hasOwnProperty('namespace') &&
      this._config.filterConfigurations.hasOwnProperty(filterId) &&
      [FilterType.excludingTerms, FilterType.terms].includes(
        this._config.filterConfigurations[filterId].type,
      )
    ) {
      const filterConfig = this._config.filterConfigurations[filterId] as
        | ConfigurationResponseTermsFilter
        | ConfigurationResponseExcludingTermsFilter;
      filterConfig.namespace =
        this._config.facetConfigurations[facetId]['namespace'];
    }
  }

  private createTermsFiltersFromExcludingTermsFilters(baseFilterId: string) {
    const filterConfig = this._config.filterConfigurations[baseFilterId];
    if (filterConfig.type === 'excludingTerms') {
      const excludingTermsFilterId: string = `${baseFilterId}.ex`;
      const excludingTermsFilterConfig: ConfigurationResponseExcludingTermsFilter =
        {
          ...filterConfig,
          id: excludingTermsFilterId,
        };
      const termsFilterConfig: ConfigurationResponseTermsFilter = {
        ...filterConfig,
        type: FilterType.terms,
      };
      this._config.filterConfigurations[excludingTermsFilterId] =
        excludingTermsFilterConfig;
      this._config.filterConfigurations[baseFilterId] = termsFilterConfig;
    }
  }
}
