import { call } from 'redux-saga/effects';
import { request } from '../../utils/request';
import { getRelationsRequestUrl } from './getRequestUrl';

interface Options {
  groupSize: number;
  level: number;
  ids: string[];
}
export function* fetchRelations(id: string, options: Options) {
  const pathname = yield call(getRelationsRequestUrl, id);

  const search: string[] = [];
  search.push(`group=${options.groupSize}`);
  search.push(`level=${options.level}`);
  options.ids.forEach(id => search.push(`id=${id}`));

  const url = `${pathname}?${search.join('&')}`;
  return yield call(request, url);
}
