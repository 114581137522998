import { useTranslation } from 'react-i18next';
import { IconConfiguration } from '../../conf/icons';
import { TranslationKeyBuilder } from '../../utils/locales/TranslationKeyBuilder';

export function useIconTooltipTranslation(icon: string) {
  const { t } = useTranslation(TranslationKeyBuilder.getSatzartNamespace());

  if (!icon || !IconConfiguration.mapIconCodeToSatzart.hasOwnProperty(icon)) {
    return null;
  }

  const tooltipKey = TranslationKeyBuilder.iconTooltip(
    IconConfiguration.mapIconCodeToSatzart[icon],
  );

  return t(tooltipKey);
}
