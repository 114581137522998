export enum MatomoEvents {
  EntityNavbarChangeView = 'entity-navbar-change-view', // Einzelentität: Nutzung Link zum Faktenblatt, Nutzung Link zur Visualisierung
  SearchNavbarChangeView = 'entity-navbar-change-view',
  PaginateSearchResults = 'search-results-paginate',
  FacetPaginate = 'facet-paginate',
  FilterAdd = 'filter-add', // Einschränkung auf Filterattribute: AND-Verknüpfung ; Ausschluss von Filterattributen: AND NOT-Verknüpfung
  FilterRemove = 'filter-remove',
  FiltersResetAll = 'filter-list-reset',
  FilterShowMore = 'filters-list-show-more',
  FiltersShowLess = 'filters-list-show-less',
  InfopanelEeUseLinkToFactsheet = 'infopanel-einzelentitaet-use-factsheet', // Einzelentität: Nutzung Link zur Faktenblatt
  InfopanelEeUseLinkToVisualisation = 'infopanel-einzelentitaet-use-visualisation-link', // Einzelentität: Nutzung Link zur Visualisierung
  InfopanelMeUseLinkToSearch = 'infopanel-mengenentitaeten-use-search-link', // Mengenentität: Nutzung Link zur Trefferliste
  InfopanelOpen = 'infopanel-open', // Aufruf Informationsbox
  HierarchyGraph = 'graph-hierachy',
  MapNoLocation = 'map-no-location', // Klick auf "nicht darstellbare Orte anzeigen"
  MapVisualisation = 'map-visualisation',
  RelationsGraphSlider = 'graph-relations-slide', // Slidernutzung (ggf. auch genutzte Sliderstufe) in Relationenvisualisierung
  RelationsGraphToggle = 'graph-relations-toggle', // Relationenvisualisierung: Nutzung "+/-"-Icon im Graphen
  SearchPage = 'search-update-page',
  SearchRows = 'search-update-rows',
  SearchSort = 'search-update-sort',
  Timeline = 'timeline',
}

export enum MapActions {
  LegendClick = 'legend-click', // Aufruf Legende
  LegendFilter = 'legend-filter', // Ansichtsfilterung über Legender
  MarkerClick = 'marker-click', // Klick auf Einzelmarker
  SearchArea = 'search-area', // Klick auf "In diesem Bereich suchen"
  NoLocation = 'no-location', // Klick auf "nicht darstellbare Orte anzeigen"
}

export enum HierachyActions {
  Legend = 'click-legend', // Aufruf "Ansicht + Legende"
  Node = 'click-node', // Klick auf Relationsknoten zum Ein-/Ausblenden von Relationszweigen
  PlusMinus = 'click-plus-minus', // Hierarchievisualisierung: Nutzung "+/-"-Icon im Graphen
  Slider = 'click-slide', // Nutzung Schieberegler zum Ein-/Ausblenden von Charakteristika
}

export enum TimelineActions {
  BarClick = 'bar-click', // Klick auf Zeitbalken
  Dropdown = 'dropdown-click', // Änderung der Ansicht über Drowdown
  ShowUnknown = 'show-unknown', // Klick auf "Entitäten mit unklarem Zeitbezug anzeigen"
  Slider = 'use-slider', // Nutzung der Schieberegler
  YearInput = 'input-year', // Änderung der Jahresangabe in Eingabefeld
}
