export const API_ENDPOINT = {
  CONFIG: 'config',
  ENTITY: 'gnd',
  HIERARCHY: 'hierarchy',
  RELATIONS: 'relations',
  SEARCH: 'search',
  FACET: 'facet',
  SUGGEST: 'suggest',
  VERSION: 'versioninfo',
};

export enum ERRORS {
  NO_DATA = 'NO_DATA',
}
