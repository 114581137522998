/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as serviceWorker from 'serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import 'sanitize.css/sanitize.css'; // Use consistent styling
import { initMatomo } from './utils/matomo';
import { configureAppStore } from './store/configureStore';
import './locales/i18n'; // Initialize languages
import App from './app';

const { store, history } = configureAppStore();
const piwikHistory = initMatomo(history);

const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const root = createRoot(MOUNT_NODE!);
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <Router history={piwikHistory}>
        <App />
      </Router>
    </HelmetProvider>
  </Provider>,
);

// Hot reloadable translation json files and reducers
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });

  if (process.env.NODE_ENV === 'development') {
    module.hot.accept('./store/reducers', () => {
      const newRootReducer = require('./store/reducers').default;
      store.replaceReducer(newRootReducer);
    });
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
