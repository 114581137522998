import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { FacetResponseItem, SearchResponse } from '../../model/api/responses';
import { RequestErrorPayload } from '../../model/api/request';
import { FacetState } from './types';

export const initialState: FacetState = {
  requests: {},
  facets: {},
};

const facetSlice = createSlice({
  name: 'facets',
  initialState,
  reducers: {
    fetchFacet(
      state,
      action: PayloadAction<{
        facetId: string;
        searchQuery: string;
      }>,
    ) {
      state.requests[action.payload.facetId] = {
        loading: true,
        error: null,
      };
    },
    fetchFacetSuccess(
      state,
      action: PayloadAction<{
        facetId: string;
        jsonResponse: SearchResponse;
      }>,
    ) {
      state.requests[action.payload.facetId].loading = false;

      const { facetId, jsonResponse } = action.payload;
      const facetResponse: FacetResponseItem = jsonResponse?.facets[facetId];
      if (facetResponse) {
        state.facets[action.payload.facetId] = facetResponse;
      }
    },
    fetchFacetError(
      state,
      action: PayloadAction<{
        facetId: string;
        error: RequestErrorPayload;
      }>,
    ) {
      state.requests[action.payload.facetId].loading = false;
      state.requests[action.payload.facetId].error = action.payload.error;
    },
  },
});

export const { name: sliceKey, actions } = facetSlice;
export default facetSlice.reducer;
