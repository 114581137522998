import map from '../../../../conf/map';
import {
  SearchRequestFacet,
  FacetResponseItem,
  ConfigurationResponseTermsFacet,
} from '../../api/responses';
import { Bucket } from '../buckets';
import { TermsFacet } from './TermsFacet';

export interface MapFacetValue {
  name: string;
  id: string;
  lat: number;
  lon: number;
  relationCode: string;
  count: number;
}

export class GeoFacet extends TermsFacet {
  protected _facetId: string = '';
  protected _values: MapFacetValue[] = [];
  protected _filteredValues: MapFacetValue[] = [];
  protected _groupByCoord: Record<string, MapFacetValue[]> = {};
  protected _groupById: Record<string, any> = {};
  protected _groupByCoordSorted: string[] = [];

  public init(
    facetResponseJson: FacetResponseItem,
    facetRequestJson: SearchRequestFacet,
    config: ConfigurationResponseTermsFacet,
  ): void {
    super.init(facetResponseJson, facetRequestJson, config);
    this._values = this._buckets.map((b: Bucket) => {
      // map facet format: 'name/id/rel/lat/lon'
      const parts = b.value.split('/');
      const name = parts[0];
      const id = parts[1];
      const relationCode = parts[2];
      const lat = Number.parseFloat(parts[3]);
      const lon = Number.parseFloat(parts[4]);
      const count = b.count;
      return { name, id, lat, lon, count, relationCode };
    });
    this._facetId = this._configuration.id;
    this._filteredValues = this._values.filter((value: MapFacetValue) =>
      map.markers.relations.includes(value.relationCode),
    );
  }

  get values() {
    return this._filteredValues;
  }

  get groupById() {
    if (!Object.keys(this._groupById).length) {
      this._groupById = this._values.reduce(
        (acc: Record<string, any>, value: MapFacetValue) => {
          const { relationCode, ...rest } = value;
          acc[value.id] = rest;
          return acc;
        },
        {},
      );
    }
    return this._groupById;
  }

  get groupByCoord() {
    if (!Object.keys(this._groupByCoord).length) {
      this._groupByCoord = Object.keys(this.groupById).reduce(
        (acc: Record<string, MapFacetValue[]>, id: string) => {
          const value = this.groupById[id];
          const key = `${value.lat},${value.lon}`;
          if (!acc.hasOwnProperty(key)) {
            acc[key] = [];
          }
          acc[key].push(value);
          return acc;
        },
        {},
      );
    }
    return this._groupByCoord;
  }

  get groupByCoordSorted() {
    if (!this._groupByCoordSorted.length) {
      this._groupByCoordSorted = Object.keys(this.groupByCoord)
        .map((coord: string) => `${this._groupByCoord[coord].length}~~${coord}`)
        .sort()
        .reverse();
    }
    return this._groupByCoordSorted;
  }
}
