import { call, select, takeLatest } from 'redux-saga/effects';
import { MatomoEvents, track } from '../../../../utils/matomo';
import { SearchRequest } from '../../../model/search/request';
import { actions } from '../slice';
import { selectSearchRequest } from '../selectors';
import { goToSearchPage } from './goToSearchPage';

export const sortSagaKey = 'sortSaga';
export function* sortSaga() {
  yield takeLatest(actions.updateSort.type, updateSort);
}

function* updateSort(action) {
  track(MatomoEvents.SearchSort, action.payload.id);

  const searchRequest: SearchRequest = yield select(selectSearchRequest);
  searchRequest.updateSort(action.payload.id);
  const searchQuery: string = searchRequest.buildUrlQueryString();
  yield call(goToSearchPage, action.payload.lang, searchQuery);
}
