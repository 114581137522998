import MapConfig from '../../../../../conf/map';
import {
  ConfigurationResponseFacet,
  SearchRequestFacet,
} from '../../../api/responses';
import { RequestFacet } from './RequestFacet';
import { RequestFacetMapFacet } from './RequestFacetMapFacet';
import { RequestFacetTermsFacet } from './RequestFacetTermsFacet';

export class RequestFacetFactory {
  public static createInstanceFrom(
    config: ConfigurationResponseFacet,
    responseRequestFacet?: SearchRequestFacet,
  ): RequestFacet {
    let instance;

    if (MapConfig.facets.includes(config.id)) {
      instance = RequestFacetMapFacet;
    } else {
      instance = RequestFacetTermsFacet;
    }

    return RequestFacet.create(instance, config, responseRequestFacet);
  }
}
