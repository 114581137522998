export abstract class AbstractBucket {
  protected _value: string;
  protected _count: number;

  public constructor(value: string, count: number) {
    this._value = value;
    this._count = count;
  }

  get value(): string {
    return this._value;
  }

  get count(): number {
    return this._count;
  }

  public static create<B extends AbstractBucket>(
    BucketInstance: new (value: string, count: number) => B,
    value: string,
    count: number,
  ): B {
    const bucket: B = new BucketInstance(value, count);
    return bucket;
  }
}
