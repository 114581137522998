import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { KeyboardKeyCodes } from '../../../utils/KeyboardKeyCodes';
import { RouteUtils } from '../../routes';
import { actions as autoSuggestActions } from '../../features/Autosuggest';
import { SearchView, SearchViews } from '../../features/GeneralUI';
import { actions } from '../../features/Search';
import { SearchBoxState } from './state';

export function useSearchBoxHandlers(
  state: SearchBoxState,
  inputRef: React.RefObject<HTMLInputElement>,
  autoSuggestRef: React.RefObject<HTMLOListElement>,
) {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const location = useLocation();
  const searchShouldResetFilters: boolean = !RouteUtils.isSearchPage(
    location.pathname,
  );
  const searchView: SearchView | undefined = !RouteUtils.isSearchPage(
    location.pathname,
  )
    ? SearchViews.list
    : undefined;

  const hideSuggestions = useCallback(() => {
    state.setShowSuggestions(false);
  }, [state]);

  const searchWithTerm = useCallback(
    (term: string) => {
      hideSuggestions();
      dispatch(
        actions.searchTerm({
          lang: i18n.language,
          term: term.trim(),
          resetFilters: searchShouldResetFilters,
          searchView,
        }),
      );
    },
    [
      dispatch,
      hideSuggestions,
      i18n.language,
      searchShouldResetFilters,
      searchView,
    ],
  );

  const doSearch = useCallback(() => {
    searchWithTerm(state.term);
  }, [searchWithTerm, state.term]);

  const resetAndFocusInput = useCallback(() => {
    state.setTerm('');
    setTimeout(() => {
      inputRef?.current?.focus();
    }, 300);
  }, [inputRef, state]);

  const changeTerm = useCallback(
    event => {
      const value = event.target.value;
      dispatch(autoSuggestActions.loadSuggestions({ term: value }));
      state.setTerm(value);
      if (!state.showSuggestions) {
        state.setShowSuggestions(true);
      }
    },
    [dispatch, state],
  );

  const handleInputKeyUp = useCallback(
    event => {
      if (event.which === KeyboardKeyCodes.ENTER) {
        doSearch();
      }
      if (event.which === KeyboardKeyCodes.ARROW_DOWN) {
        autoSuggestRef?.current?.focus();
      }
    },
    [autoSuggestRef, doSearch],
  );

  const handleInputFocus = useCallback(() => {
    dispatch(autoSuggestActions.loadSuggestions({ term: state.term }));
    state.setInputFocus(true);
    if (!state.showSuggestions) {
      state.setShowSuggestions(true);
    }
  }, [dispatch, state]);

  const handleInputBlur = useCallback(() => {
    state.setInputFocus(false);
  }, [state]);

  const setListFocus = useCallback(
    (on: boolean) => {
      return function () {
        state.setListFocus(on);
      };
    },
    [state],
  );

  return {
    onInputBlur: handleInputBlur,
    onInputFocus: handleInputFocus,
    onListBlur: setListFocus(false),
    onListFocus: setListFocus(true),
    onInputKeyUp: handleInputKeyUp,
    onTermChange: changeTerm,
    onTermReset: resetAndFocusInput,
    onSearch: doSearch,
    onSelectSuggestion: hideSuggestions,
  };
}
