import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { ConfigurationResponseFilterMap } from '../../model/api/responses';
import { Sort } from '../../model/search/sort';
import { initialState as searchInitial, SearchState } from '../Search';
import { SearchConfigurationState } from './types';
import { initialState } from './slice';

// First select the relevant part from the state
const selectSearchConfigurationData = (state: RootState) =>
  state.searchConfiguration || initialState;
const selectSearch = (state: RootState) => state.search || searchInitial;

export const selectSearchConfiguration = createSelector(
  [selectSearchConfigurationData, selectSearch],
  (
    searchConfigurationState: SearchConfigurationState,
    searchState: SearchState,
  ) => searchConfigurationState.searchConfigurations[searchState.searchContext],
);

export const selectIsSearchConfigurationLoaded = createSelector(
  [selectSearchConfigurationData, selectSearch],
  (
    searchConfigurationState: SearchConfigurationState,
    searchState: SearchState,
  ) =>
    searchConfigurationState.isSearchConfigurationLoaded[
      searchState.searchContext
    ],
);

export const selectSortConfigurations = createSelector(
  [selectSearchConfigurationData, selectSearch],
  (
    searchConfigurationState: SearchConfigurationState,
    searchState: SearchState,
  ) => {
    const searchConfigurationJson =
      searchConfigurationState.searchConfigurations[searchState.searchContext];
    if (searchConfigurationJson) {
      const sortConfigurations: Sort[] = Object.keys(
        searchConfigurationJson.sortConfigurations,
      ).map((fieldId: string) =>
        Sort.create(searchConfigurationJson.sortConfigurations[fieldId]),
      );
      return sortConfigurations;
    }
    return [];
  },
);

export const selectFilterConfigurations = createSelector(
  [selectSearchConfigurationData, selectSearch],
  (
    searchConfigurationState: SearchConfigurationState,
    searchState: SearchState,
  ) =>
    searchConfigurationState.searchConfigurations[searchState.searchContext]
      .filterConfigurations,
);

export const selectFilterConfigById = (id: string) =>
  createSelector(
    [selectFilterConfigurations],
    (configs: ConfigurationResponseFilterMap) => configs[id],
  );
