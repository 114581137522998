import { ResponseError } from '../../../../utils/request';
import { ErrorsEnum, RequestErrorPayload } from './types';

export class ErrorPayloadGenerator {
  public getPayload(error: any): RequestErrorPayload {
    if (typeof error === 'string') {
      return this.getPayloadForStringError(error);
    }

    if (
      error?.message === 'Failed to fetch' ||
      error?.message === 'Network error'
    ) {
      return this.getPayloadForNetworkError();
    }

    if (error.hasOwnProperty('response')) {
      return this.getPayloadForResponseError(error as ResponseError);
    }

    if (
      error === ErrorsEnum.NoDataFound ||
      error === ErrorsEnum.NoSearchConfigurations
    ) {
      return this.getPayloadForStringError(error as string);
    }

    return { message: error.message };
  }

  private getPayloadForStringError(error: string): RequestErrorPayload {
    return { message: error };
  }

  private getPayloadForNetworkError(): RequestErrorPayload {
    return { code: 503, message: 'Network error' };
  }

  private getPayloadForResponseError(
    error: ResponseError,
  ): RequestErrorPayload {
    return { code: error.response.status, message: error.response.statusText };
  }
}
