import { convertLanguageJsonToObject } from './translations';

import en from './en/translation.json';
import enAccessibility from './en/accessibility.json';
import enFacet from './en/facet.json';
import enFooter from './en/footer.json';
import enHelmet from './en/helmet.json';
import enHomepage from './en/homepage.json';
import enHierarchy from './en/hierarchy.json';
import enSatzart from './en/satzart.json';
import enSort from './en/sort.json';

import de from './de/translation.json';
import deAccessibility from './de/accessibility.json';
import deFacet from './de/facet.json';
import deFooter from './de/footer.json';
import deHelmet from './de/helmet.json';
import deHomepage from './de/homepage.json';
import deHierarchy from './de/hierarchy.json';
import deSatzart from './de/satzart.json';
import deSort from './de/sort.json';

import uk from './uk/translation.json';
import ukAccessibility from './uk/accessibility.json';
import ukFacet from './uk/facet.json';
import ukFooter from './uk/footer.json';
import ukHelmet from './uk/helmet.json';
import ukHomepage from './uk/homepage.json';
import ukHierarchy from './uk/hierarchy.json';
import ukSatzart from './uk/satzart.json';
import ukSort from './uk/sort.json';

const dateTranslation = { formattedIntlDate: '{{ date, intlDate }}' };

// Create the 'translations' object to provide full intellisense support for the static json files.
convertLanguageJsonToObject(de);
convertLanguageJsonToObject(en);

export const localResources = {
  de: {
    translation: {
      ...de,
      ...dateTranslation,
    },
    accessibility: deAccessibility,
    facet: deFacet,
    footer: deFooter,
    helmet: deHelmet,
    homepage: deHomepage,
    hierarchy: deHierarchy,
    satzart: deSatzart,
    sort: deSort,
  },
  en: {
    translation: {
      ...en,
      ...dateTranslation,
    },
    accessibility: enAccessibility,
    facet: enFacet,
    footer: enFooter,
    helmet: enHelmet,
    homepage: enHomepage,
    hierarchy: enHierarchy,
    satzart: enSatzart,
    sort: enSort,
  },
  uk: {
    translation: {
      ...uk,
      ...dateTranslation,
    },
    accessibility: ukAccessibility,
    facet: ukFacet,
    footer: ukFooter,
    helmet: ukHelmet,
    homepage: ukHomepage,
    hierarchy: ukHierarchy,
    satzart: ukSatzart,
    sort: ukSort,
  },
};
