import { useSelector } from 'react-redux';
import { selectSuggestions } from '../features/Autosuggest';
import { AutoSuggestItem } from '../model/autosuggest';

export function useAutosuggestions(): AutoSuggestItem[] {
  const suggestions = useSelector(selectSuggestions);
  const suggestionsShown = filterToUniqueSuggestions(suggestions);
  return suggestionsShown;
}

function filterToUniqueSuggestions(
  suggestions: AutoSuggestItem[],
): AutoSuggestItem[] {
  const seenIds = {};
  const filteredItems = suggestions.filter(item => {
    if (!item.entityId) {
      return true; // single_term items
    }
    if (seenIds[item.entityId]) {
      return false;
    } else {
      seenIds[item.entityId] = true;
      return true;
    }
  });
  return filteredItems;
}
