import { _t } from '../../../locales/utils';

const scope = 'Footer.version';

export const messages = {
  title: _t('Footer.app.name'),
  dataset: _t(`${scope}.dataset`),
  indexerRelease: _t(`${scope}.release.indexer`),
  portalRelease: _t(`${scope}.release.portal`),
  searchServiceRelease: _t(`${scope}.release.search`),
  footerInfoTitle: _t('Footer.accessible.section.title.info'),
};
