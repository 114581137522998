import { EntityResponseField } from '../api/responses';
import { EntityFields } from './EntityFields';
import { EntityFieldsGetters } from './EntityFieldsGetters';
import { getTitleComplement } from './fieldsUtils';

export class Entity {
  protected _id: string;
  protected _idn: string;
  protected _hasHierarchy: boolean;
  protected _hasRelations: boolean;
  protected _fieldsJson: EntityResponseField[];
  protected _fields: EntityFields;
  protected _getters: EntityFieldsGetters;

  public constructor(json: any /* EntityResponse | SearchResponseDocument */) {
    this._id = json.id;
    this._idn = json.idn;
    this._hasHierarchy = json.hasHierarchy;
    this._hasRelations = json.hasRelations;
    this._fieldsJson = json.record?.recordFields || json.recordFields;
    this._fields = EntityFields.initFromEntityJson(this.fieldsJson);
    this._getters = new EntityFieldsGetters(this._fields);
  }

  get fieldsJson(): EntityResponseField[] {
    return this._fieldsJson;
  }

  get id(): string {
    return this._id;
  }

  get idn(): string {
    return this._idn;
  }

  get fields(): EntityFields {
    return this._fields;
  }

  get hasRelations(): boolean {
    return this._hasRelations;
  }

  get hasHierarchy(): boolean {
    return this._hasHierarchy;
  }

  get internalId(): string {
    return this._getters.internalId;
  }

  get title(): string {
    return this._getters.title;
  }

  get titleComplement(): string {
    return getTitleComplement(this._fieldsJson);
  }

  get icon(): string {
    return this._getters.icon;
  }

  get entityCodes(): string[] {
    return this._getters.entityCodes;
  }

  get recordType(): string {
    return this._getters.recordType;
  }
}
