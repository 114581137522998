import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit'; // importing from `utils` is more type-safe
import { RequestErrorPayload } from '../../model/api/request';
import {
  useInjectReducer,
  useInjectSaga,
} from '../../../utils/redux-injectors';
import { loadServicesVersionData } from './sagas/loadServicesVersionData';
import { VersionsState, ServicesVersionsJson } from './types';

export const initialState: VersionsState = {
  request: {
    loading: false,
    error: null,
  },
  loaded: false,
  services: {
    indexerRelease: '',
    searchRelease: '',
    dumpDate: '',
  },
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const versionsSlice = createSlice({
  name: 'versions',
  initialState,
  reducers: {
    loadServiceVersions(state) {
      state.request.loading = true;
    },
    loadServiceVersionsSuccess(
      state,
      action: PayloadAction<{ json: ServicesVersionsJson }>,
    ) {
      const { json } = action.payload;
      state.loaded = true;
      state.request.loading = false;
      state.services.indexerRelease = json.indexerRelease || '';
      state.services.searchRelease = json.searchRelease || '';
      state.services.dumpDate = json.dumpDate || '';
    },
    loadServiceVersionsError(
      state,
      action: PayloadAction<{ error: RequestErrorPayload }>,
    ) {
      state.request.loading = false;
      state.request.error = action.payload.error;
    },
  },
});

// Exports
export const { actions } = versionsSlice;
export const useVersionsSlice = () => {
  useInjectReducer({ key: versionsSlice.name, reducer: versionsSlice.reducer });
  useInjectSaga({ key: versionsSlice.name, saga: loadServicesVersionData });
  return { actions: versionsSlice.actions };
};
