import mapConfig from '../../../conf/map';

export function initVisibleMapRelationsState(): Record<string, boolean> {
  return mapConfig.markers.relations.reduce(
    (acc: Record<string, boolean>, relationCode: string) => {
      acc[relationCode] = true;
      return acc;
    },
    {},
  );
}
