import {
  ConfigurationResponseFilter,
  SearchRequestFilterBase,
} from '../../api/responses';
import { UrlQueryBuilder } from '../request';
import { DisplayFilter } from './DisplayFilter';
import { FilterType } from './FilterType';

export interface Filter {
  id: string;
  type: FilterType;
  parseUrlSearchParams: (params: URLSearchParams) => void;
  addToUrlSearchParams: (builder: UrlQueryBuilder) => void;
  isEmpty: () => boolean;
  setValue: (value: any) => void;
  removeValue: (value: any) => Filter;
  resetValues: () => Filter;
  isValueSelected: (value: any) => boolean;
  addToSelectedFilterTerms: (list: DisplayFilter[]) => void;
  getSelectedFilterTerms: () => string[];
  clone: () => Filter;
  // valuesMatch: (filter: Filter) => boolean;
  // toSelectedTerm: (selectedTerm: string) => string;
}

export abstract class AbstractFilter<FValue> implements Filter {
  protected _id: string;
  protected _type: FilterType;

  public constructor(id: string, type: FilterType) {
    this._id = id;
    this._type = type;
  }

  public static create<F extends AbstractFilter<any>>(
    FilterInstance: new (id: string, type: FilterType) => F,
    json: SearchRequestFilterBase,
    configuration: ConfigurationResponseFilter,
  ): F {
    const filter: F = new FilterInstance(json.id, configuration.type);
    filter.init(json);
    return filter;
  }

  public abstract init(data: SearchRequestFilterBase): void;
  public abstract parseUrlSearchParams(params: URLSearchParams): void;
  public abstract addToUrlSearchParams(builder: UrlQueryBuilder): void;
  public abstract isEmpty(): boolean;
  public abstract setValue(value: FValue): void;
  public abstract removeValue(value: FValue): Filter;
  public abstract isValueSelected(value: FValue): boolean;
  public abstract resetValues(): Filter;
  public abstract addToSelectedFilterTerms(list: DisplayFilter[]): void;
  public abstract getSelectedFilterTerms(): string[];
  public abstract clone(): Filter;
  // public abstract valuesMatch(filter: Filter): boolean;
  // public abstract toSelectedTerm(selectedTerm: string): string;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get type(): FilterType {
    return this._type;
  }
}
