import { DEFAULT_PAGE_SIZE } from '../../../features/Config';
import { TermsQuery } from '../query';
import { FilterList } from '../filters';
import { RequestFacetList } from '../facets';

export abstract class AbstractSearchRequest {
  protected _facetList: RequestFacetList;
  protected _filterList: FilterList;
  protected _position: number = 0;
  protected _query: TermsQuery;
  protected _rows: number = DEFAULT_PAGE_SIZE;
  protected _sort: string;
  protected _start: number = 0;

  protected constructor(
    query: TermsQuery,
    facets: RequestFacetList,
    filters: FilterList,
    sort: string,
    rows: number,
    start: number,
    position: number,
  ) {
    this._query = query;
    this._facetList = facets;
    this._filterList = filters;
    this._sort = sort;
    this._rows = rows;
    this._start = start;
    this._position = position;
  }

  get facetList(): RequestFacetList {
    return this._facetList;
  }

  set facetList(list: RequestFacetList) {
    this._facetList = list;
  }

  get filterList(): FilterList {
    return this._filterList;
  }

  set filterList(list: FilterList) {
    this._filterList = list;
  }

  get position(): number {
    return this._position;
  }

  set position(position: number) {
    this._position = position;
  }

  get query(): TermsQuery {
    return this._query;
  }

  set query(query: TermsQuery) {
    this._query = query;
  }

  get rows(): number {
    return this._rows;
  }

  set rows(rows: number) {
    this._rows = rows;
  }

  get sort(): string {
    return this._sort;
  }

  set sort(sort: string) {
    this._sort = sort;
  }

  get start(): number {
    return this._start;
  }

  set start(start: number) {
    this._start = start;
  }

  public resetRows(): void {
    this.rows = DEFAULT_PAGE_SIZE;
  }

  public resetStart(): void {
    this.start = 0;
  }
}
