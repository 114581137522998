import { useState } from 'react';
import { useAutosuggestions } from '../../hooks/useAutosuggestions';
import { AutoSuggestItem } from '../../model/autosuggest';

export interface AutoSuggestState {
  selected: number;
  setSelected(idx: number): void;
  suggestions: AutoSuggestItem[];
}

export function useAutoSuggestState() {
  const [selected, setSelected] = useState(0);
  const suggestions = useAutosuggestions();

  return {
    selected,
    setSelected,
    suggestions,
  };
}
