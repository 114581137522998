import { takeLatest } from 'redux-saga/effects';
import { actions } from '../slice';
import { searchEntityPagination } from './searchEntityPagination';
import { doDefaultSearch } from './searchDefault';
import { searchInfopanel } from './searchInfopanel';

export const searchSagaKey = 'searchSaga';
export function* searchSaga() {
  yield takeLatest(actions.search.type, doDefaultSearch);
}

export const searchEntityPaginationSagaKey = 'searchEntityPaginationSaga';
export function* searchEntityPaginationSaga() {
  yield takeLatest(actions.searchEntityPagination.type, searchEntityPagination);
}

export const searchInfopanelSagaKey = 'searchInfopanelSaga';
export function* searchInfopanelSaga() {
  yield takeLatest(actions.searchInfopanel.type, searchInfopanel);
}
