import { TFunction } from 'i18next';
import { LatLonDms } from '../../coordinates';
import { Filter } from './AbstractFilter';

export enum DisplayFilterType {
  term = 'term',
  relationJoin = 'relationJoin',
  geoRelation = 'geoRelation',
  geoBBox = 'geoBBox',
  geoNone = 'geoNone',
  yearRange = 'yearRange',
  yearRangeNone = 'yearRangeNone',
}

export interface DisplayFilter {
  filter: Filter;
  type: string;
}

export interface DisplayFilterTerm extends DisplayFilter {
  type: DisplayFilterType.term;
  term: string | ((t: TFunction) => string);
}

export interface DisplayFilterRelationJoin extends DisplayFilter {
  type: DisplayFilterType.relationJoin;
  entityId: string;
  entityTitle?: string;
  relationCode: string;
  satzart?: string;
  isForward: boolean;
}

export interface DisplayFilterGeoRelation extends DisplayFilter {
  type: DisplayFilterType.geoRelation;
  entityId: string;
  entityTitle?: string;
  relationCode: string;
  coordinates: string;
}

export interface DisplayFilterGeoBBox extends DisplayFilter {
  type: DisplayFilterType.geoBBox;
  dmsBBox: [LatLonDms, LatLonDms];
}

export interface DisplayFilterGeoNone extends DisplayFilter {
  type: DisplayFilterType.geoNone;
}

export interface DisplayFilterYearRange extends DisplayFilter {
  type: DisplayFilterType.yearRange;
  relation: string;
  startYear: number;
  endYear: number;
}

export interface DisplayFilterYearRangeNone extends DisplayFilter {
  type: DisplayFilterType.yearRangeNone;
}
