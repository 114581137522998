import OPiwik from './OMPiwik';

export function initMatomo(history) {
  // if (process.env.NODE_ENV === 'production') {
  let piwikHistory = history;
  const piwik: any = new OPiwik({
    url: window['appConfig'].matomoUrl,
    siteId: window['appConfig'].matomoSiteId,
    disableCookies: true,
    enableLinkTracking: true,
  });
  piwikHistory = piwik.connectToHistory(history as any, true);
  // }
  return piwikHistory;
}
