export type SearchContext = keyof typeof Context;
export enum Context {
  default = 'default',
  pagination = 'pagination',
  infopanel = 'infopanel',
  timelineMinYear = 'timelineMinYear',
}

export const contextPathMapper = {
  [Context.default]: 'search',
};
