import {
  ConfigurationResponseFilterMap,
  SearchRequestFilterMap,
} from '../../api/responses';
import { FilterList } from '../filters';

export class FilterListFactory {
  public static createEmpty(): FilterList {
    return new FilterList();
  }

  public static createFromResponseRequest(
    json: SearchRequestFilterMap,
    config: ConfigurationResponseFilterMap,
  ): FilterList {
    const filterList: FilterList = new FilterList();
    filterList.configurations = config;
    filterList.addFiltersFromResponseRequest(json);
    return filterList;
  }
}
