import { call, select, takeLatest } from 'redux-saga/effects';
import { MatomoEvents, track } from '../../../../utils/matomo';
import { ConfigurationResponseFilterMap } from '../../../model/api/responses';
import { Filter, FilterFactory } from '../../../model/search/filters';
import { SearchRequest } from '../../../model/search/request';
import { selectFilterConfigurations } from '../../SearchConfiguration';
import { actions } from '../slice';
import { selectSearchRequest } from '../selectors';
import { goToSearchPage } from './goToSearchPage';

export const filterSagaKey = 'filterSaga';
export function* filterSaga() {
  yield takeLatest(actions.addFilter.type, addFilter);
  yield takeLatest(actions.removeFilter.type, removeFilter);
  yield takeLatest(actions.resetFilters.type, resetFilters);
}

function* prepare(action) {
  const filterConfigurations: ConfigurationResponseFilterMap = yield select(
    selectFilterConfigurations,
  );
  const filter: Filter = FilterFactory.createInstanceFromId(
    action.payload.filterId,
    filterConfigurations[action.payload.filterId],
  );
  const searchRequest: SearchRequest = yield select(selectSearchRequest);
  return { searchRequest, filter };
}

function* addFilter(action) {
  track(MatomoEvents.FilterAdd, action.payload.filterId);

  const preparedValues = yield call(prepare, action);
  const searchRequest: SearchRequest = preparedValues.searchRequest;
  const filter: Filter = preparedValues.filter;
  const value = action.payload.filterValue;
  searchRequest.addFilter(filter, value);
  const searchQuery: string = searchRequest.buildUrlQueryString();
  yield call(goToSearchPage, action.payload.lang, searchQuery);
}

export function* removeFilter(action) {
  track(
    MatomoEvents.FilterRemove,
    `${action.payload.filterId}:${action.payload.filterValue}`,
  );

  const preparedValues = yield call(prepare, action);
  const searchRequest: SearchRequest = preparedValues.searchRequest;
  const filter: Filter = preparedValues.filter;
  searchRequest.removeFilter(filter, action.payload.filterValue);
  const searchQuery: string = searchRequest.buildUrlQueryString();
  yield call(goToSearchPage, action.payload.lang, searchQuery);
}

export function* resetFilters(action) {
  track(MatomoEvents.FiltersResetAll, MatomoEvents.FiltersResetAll);

  const searchRequest: SearchRequest = yield select(selectSearchRequest);
  searchRequest.resetFilters();
  const searchQuery: string = searchRequest.buildUrlQueryString();
  yield call(goToSearchPage, action.payload.lang, searchQuery);
}
