import {
  ConfigurationResponseFilterMap,
  SearchConfigurationResponse,
  SearchResponseRequest,
} from '../../api/responses';
import { QueryFactory, TermsQuery } from '../query';
import { SearchRequest, SearchRequestBuilder } from '../request';
import {
  RequestFacetListFactory,
  FilterListFactory,
  SortFactory,
} from './index';

export class SearchRequestFactory {
  public static createEmpty(): SearchRequest {
    const builder: SearchRequestBuilder = new SearchRequestBuilder();
    return builder
      .query(QueryFactory.createEmpty() as TermsQuery)
      .facets(RequestFacetListFactory.createEmpty())
      .filters(FilterListFactory.createEmpty())
      .sort(SortFactory.createEmpty())
      .build();
  }

  public static createFromResponseRequest(
    json: SearchResponseRequest,
    configuration: SearchConfigurationResponse,
  ): SearchRequest {
    const query: TermsQuery = QueryFactory.create(json.query) as TermsQuery;
    const filters = FilterListFactory.createFromResponseRequest(
      json.filters,
      configuration.filterConfigurations,
    );
    const facets = RequestFacetListFactory.createFromResponseRequest(
      json.facets,
      configuration.facetConfigurations,
    );

    const builder: SearchRequestBuilder = new SearchRequestBuilder();
    return builder
      .query(query)
      .facets(facets)
      .filters(filters)
      .sort(json.sort.id)
      .rows(json.rows)
      .start(json.start)
      .build();
  }

  public static createFromUrlSearchParams(
    queryString: string,
    filterConfigurations: ConfigurationResponseFilterMap = {},
  ): SearchRequest {
    const request: SearchRequest = SearchRequestFactory.createEmpty();
    const params = new URLSearchParams(queryString);

    if (params.has('start')) {
      request.start = parseInt(
        decodeURIComponent(params.get('start') as string),
        10,
      );
    }

    if (params.has('rows')) {
      request.rows = parseInt(
        decodeURIComponent(params.get('rows') as string),
        10,
      );
    }

    if (params.has('sort')) {
      const sortParam = params.get('sort') as string;
      const sortId = decodeURIComponent(sortParam);
      request.sort = sortId;
    }

    if (params.has('pos')) {
      request.position = Math.max(
        0,
        parseInt(decodeURIComponent(params.get('pos') as string), 10),
      );
    }

    request.query.parseUrlSearchParams(params);
    request.filterList.configurations = filterConfigurations;
    request.filterList.parseUrlSearchParams(params);

    return request;
  }
}
