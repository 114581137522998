import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { SearchResponse } from '../../model/api/responses';
import { Context, SearchContext } from '../../model/search/context';
import { SearchView } from '../GeneralUI';
import {
  initialSearchRequests,
  initialSearchResponses,
  SearchState,
} from './types';

export const initialState: SearchState = {
  searchContext: Context.default,
  searchResponses: initialSearchResponses,
  searchRequests: initialSearchRequests,
  requests: {
    [Context.default]: resetRequest(),
    [Context.pagination]: resetRequest(),
    [Context.infopanel]: resetRequest(),
  },
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const key = 'search';
const searchSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    search(
      state,
      action: PayloadAction<{
        lang: string;
        searchQuery: string;
        view?: SearchView;
      }>,
    ) {
      state.requests[Context.default] = startRequest();
    },
    searchEntityPagination(state) {
      state.requests[Context.pagination] = startRequest();
    },
    searchInfopanel(state, action: PayloadAction<{ searchQuery: string }>) {
      state.requests[Context.infopanel] = startRequest();
    },
    searchSuccess(
      state,
      action: PayloadAction<{
        jsonResponse: SearchResponse;
        context: SearchContext;
      }>,
    ) {
      const { jsonResponse, context } = action.payload;
      state.requests[context].loading = false;
      state.requests[context].error = null;
      state.searchResponses[context] = jsonResponse;
      state.searchRequests[context] = jsonResponse.request;
    },
    searchError(
      state,
      action: PayloadAction<{
        message: string;
        status?: number;
        context: SearchContext;
      }>,
    ) {
      const context = action.payload.context;
      state.requests[context].loading = false;
      state.requests[context].error = action.payload.message;
    },
    searchTerm(
      state,
      action: PayloadAction<{
        lang: string;
        term: string;
        resetFilters: boolean;
        searchView?: SearchView;
      }>,
    ) {
      state.requests.default.loading = true;
      state.requests.default.error = null;
      state.searchRequests.default.query.term = action.payload.term;
    },
    searchTermSuccess(state) {
      state.requests.default.loading = false;
    },
    cleanupSearchTermResponse(state) {
      state.searchResponses[Context.default] = null;
    },
  },
});

export const actions = {
  ...searchSlice.actions,
  // filters
  addFilter: createAction<{
    lang: string;
    filterId: string;
    filterType: string;
    filterValue: string;
  }>(`${key}/addFilter`),
  removeFilter: createAction<{
    lang: string;
    filterId: string;
    filterType: string;
    filterValue: string;
  }>(`${key}/removeFilter`),
  resetFilters: createAction<{
    lang: string;
  }>(`${key}/resetFilters`),

  // sort
  updateSort: createAction<{ lang: string; id: string }>(`${key}/updateSort`),

  // rows
  updateRows: createAction<{ lang: string; rows: number }>(`${key}/updateRows`),

  // search page pagination
  goToPage: createAction<{ lang: string; page: number }>(`${key}/goToPage`),
};

export const { name: sliceKey } = searchSlice;
export default searchSlice.reducer;

function resetRequest() {
  return {
    loading: false,
    error: null,
  };
}

function startRequest() {
  return { loading: true, error: null };
}
