import styled from 'styled-components/macro';
import { media } from '../../../styles';

export const Grid = styled.div`
  display: -ms-grid;
  display: grid;

  grid-template-rows: auto;
  -ms-grid-rows: auto;

  grid-column-gap: ${props => props.theme.grid.gutter.mobile}rem;

  ${media.tablet_portrait_up`
    grid-column-gap: ${props => props.theme.grid.gutter.tablet_portrait}rem;
  `};

  ${media.tablet_landscape_up`
    grid-column-gap: ${props => props.theme.grid.gutter.tablet_landscape}rem;
  `};

  ${media.desktop_up`
    grid-column-gap: ${props => props.theme.grid.gutter.desktop}rem;
  `};
`;
