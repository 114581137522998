import { createGlobalStyle } from 'styled-components/macro';
import normalize from 'styled-normalize';
import { font_Body } from './typography';
import { fontFaces } from './fontFaces';
import { defaultTheme } from './defaultTheme';

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  ${fontFaces};

  html {
    font-size: ${props => props.theme.baseFontSize}px;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    overflow-y: scroll;
    font-family: ${defaultTheme.font.family};
    -webkit-touch-callout: none;
    ${font_Body};
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // :focus {
    //   outline: 2px dotted black
    // }
    // :focus:not(:focus-visible) {
    //   outline: none;
    // }
    // ::-moz-focus-inner {
    //   border: none;
    // }
  }
`;
