import { IconCodes } from '../../../conf/icons';
import {
  AutosuggestResponse,
  AutosuggestResponseItem,
} from '../api/responses/autosuggest/AutosuggestResponse';
import { AutoSuggestItem } from './types';

export function parseSuggestions(json: AutosuggestResponse): AutoSuggestItem[] {
  const suggestions = json.suggestions.map(item => parseItem(item));
  return suggestions;
}

function parseItem(item: AutosuggestResponseItem): AutoSuggestItem {
  if (!item.payload) {
    return item;
  }

  const { text, payload, ...rest } = item;

  const responseTexts = text.split('|');
  const suggestionText = responseTexts[0];
  const additionalInformation =
    responseTexts.length > 1 ? responseTexts[1] : undefined;

  const payloadItems = payload ? payload.split('/') : [];

  return {
    ...rest,
    text: suggestionText,
    entityId: payloadItems[0],
    icon: payloadItems[1] as IconCodes,
    payloadTerm: payloadItems[2],
    additionalInformation,
  };
}
