import React from 'react';
import { useLocation } from 'react-router-dom';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../../../locales/i18n';
import { LinkButton, NoLinkButton } from './style';

interface Props {
  current: string;
  language: string;
}

function LanguageButton(props: Props) {
  const { pathname, search } = useLocation();
  const isActive: boolean = props.current === props.language;

  if (isActive) {
    return (
      <NoLinkButton>
        <span>{props.language}</span>
      </NoLinkButton>
    );
  }

  // remove language part from current pathname
  const regex: RegExp = new RegExp(`/(${SUPPORTED_LANGUAGES.join('|')})/`);
  const pathnameWithoutLanguage: string = pathname.replace(regex, '/');

  // resolve language part
  const languagePart: string =
    props.language === DEFAULT_LANGUAGE ? '' : `/${props.language}`;

  // create final url
  const url: string = `${languagePart}${pathnameWithoutLanguage}`;

  return (
    <LinkButton to={{ pathname: url, search }}>
      <span>{props.language}</span>
    </LinkButton>
  );
}

export default LanguageButton;
