import React from 'react';
import { useTranslation } from 'react-i18next';
import { GNDLogo } from '../GNDLogo';
import { SkipToMainContent } from '../Link/SkipToMainContent';
import { LanguageSwitcher } from '../LanguageSwitcher';
import { SearchBox } from '../SearchBox';
import { VisuallyHidden } from '../VisuallyHidden';
import { messages } from './messages';
import { Header, HeaderGrid } from './style';

export function PageHeader() {
  const { t } = useTranslation();

  return (
    <Header id="page-header">
      <SkipToMainContent />
      <section>
        <VisuallyHidden as="h1">{t(messages.sectionTitle)}</VisuallyHidden>
        <HeaderGrid>
          <GNDLogo />
          <LanguageSwitcher />
          <SearchBox />
        </HeaderGrid>
      </section>
    </Header>
  );
}
