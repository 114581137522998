export type QueryType = keyof typeof QueryTypes;
export enum QueryTypes {
  terms = 'terms',
}

export type QueryConfigurationType = keyof typeof QueryConfigurationTypes;

export enum QueryConfigurationTypes {
  edismax = 'edismax',
}
