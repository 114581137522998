import React from 'react';
import { useTranslation } from 'react-i18next';
import { Routes, RouteUtils } from '../../routes';
import { Logo } from '../Icon';
import { messages } from './messages';
import { Link } from './style';

export function GNDLogo() {
  const { i18n, t } = useTranslation();
  return (
    <Link
      to={{
        pathname: RouteUtils.localizeRoute(i18n.language, Routes.Home),
      }}
    >
      <Logo
        aria-label={t(messages.logoAriaLabel)}
        title={t(messages.logoTooltip)}
      />
    </Link>
  );
}
