import styled, { css } from 'styled-components/macro';
import {
  font_Body,
  font_H1,
  font_H2,
  font_H3,
  font_H4,
  media,
} from '../../../styles';

export const H1 = styled.h1`
  ${font_H1};
  margin: 0 auto ${props => props.theme.spacing._11}rem;

  ${media.desktop_up`
    margin: 0 auto ${props => props.theme.spacing._16}rem;
  `}
`;

export const H2 = styled.h2`
  ${font_H2};
  margin-top: 0;
  margin-bottom: ${props => props.theme.spacing._14}rem;
  ${media.tablet_portrait_up`
    margin-bottom: ${props => props.theme.spacing._20}rem;
  `}
  ${media.desktop_up`
    margin-bottom: ${props => props.theme.spacing._35}rem;
  `}
`;

export const H3 = styled.h3`
  ${font_H3};
  margin-top: 0;
  margin-bottom: ${props => props.theme.spacing._26}rem;
`;

export const H4 = styled.h4`
  ${font_H4};
  margin: 0;
`;

export const P = styled.p`
  ${font_Body};
  display: block;
  margin-top: 0;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const UL = styled.ul`
  margin: 0;
  padding: 0;
`;

export const OL = styled.ol`
  margin: 0;
  padding: 0;
`;

export const LI = styled.li`
  list-style: none;
`;

/* DO NOT ADD smooth behavior!
  It breaks drag-to-scroll with the mouse!
  See hierarchy */
// scroll-behavior: smooth;
export const hideScrollbarStyles = css`
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
`;

export const customScrollbarStyles = css`
  scrollbar-color: ${props => props.theme.color.gray_horizontal_separator}
    transparent;
  scrollbar-width: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 12px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // background: #eeeeee;
    background-color: transparent;
    border-radius: ${props => props.theme.spacing._100}rem;
    position: absolute;
    right: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    // background: #979797;
    background-color: #d9d9d9;
    border-radius: ${props => props.theme.spacing._100}rem;
    position: absolute;
    right: 0;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    // background: #aaa;
    background: ${props => props.theme.color.gray_horizontal_separator};
  }
`;

export const customThinScrollbarStyles = css`
  ${customScrollbarStyles};
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 6px;
  }
`;
