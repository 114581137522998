import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useEventListener } from '../../hooks/useEventListener';
import { IconScrollToTop } from '../Icon';
import { messages } from './messages';
import { PositionRelative, PositionAbsolute, Button } from './style';

export function ToTop() {
  const [isVisible, setVisible] = useState(false);
  const { t } = useTranslation();

  const handleScroll = useCallback(() => {
    const visible: boolean = window.innerHeight / 4 < window.scrollY;
    setVisible(visible);
  }, [setVisible]);
  useEventListener('scroll', handleScroll);

  const handleClick = (): void => {
    // matomo.trackEvent(matomo.SCROLL_TO_TOP);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <PositionRelative>
      <PositionAbsolute>
        <Button
          onClick={handleClick}
          $visible={isVisible}
          aria-label={t(messages.tooltip)}
          title={t(messages.tooltip)}
        >
          <IconScrollToTop />
        </Button>
      </PositionAbsolute>
    </PositionRelative>
  );
}
