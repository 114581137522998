import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { RequestErrorPayload } from '../../model/api/request';
import { EntityTitleState } from './types';

export const initialState: EntityTitleState = {
  request: {
    loading: false,
    error: null,
  },
  titles: {},
};

const key = 'entityTitle';
const entityTitleSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    fetchEntityTitle(state, action: PayloadAction<{ id: string }>) {
      state.request.loading = true;
      state.request.error = null;
    },
    fetchEntityTitleSuccess(
      state,
      action: PayloadAction<{ id: string; title: string }>,
    ) {
      state.request.loading = false;
      state.request.error = null;
      state.titles[action.payload.id] = action.payload.title;
    },
    fetchEntityTitleError(
      state,
      action: PayloadAction<{ error: RequestErrorPayload }>,
    ) {
      state.request.loading = false;
      state.request.error = action.payload.error;
    },
  },
});

export const { name: sliceKey, actions } = entityTitleSlice;
export default entityTitleSlice.reducer;
