export type FacetType = keyof typeof FacetTypes | 'undefined';

export enum FacetTypes {
  terms = 'terms',
  staticTerms = 'staticTerms',
  hierarchicalPath = 'termsHierarchyPath',
  yearRangeRelation = 'yearRangeRelation',
  yearRangeNone = 'yearRangeNone',
}

export const FACET_MISSING_VALUE = '!000facetempty';
