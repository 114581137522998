import styled, { css } from 'styled-components/macro';
import { ReactComponent as BauwerkBig } from './imagesWithBackground/icon-bauwerk-large.svg';
import { ReactComponent as BauwerkHierarchy } from './imagesWithBackground/icon-bauwerk-hierarchy.svg';
import { ReactComponent as BauwerkSmall } from './imagesWithBackground/icon-bauwerk-small.svg';
import { ReactComponent as EreignisBig } from './imagesWithBackground/icon-ereignis-large.svg';
import { ReactComponent as EreignisHierarchy } from './imagesWithBackground/icon-ereignis-hierarchy.svg';
import { ReactComponent as EreignisSmall } from './imagesWithBackground/icon-ereignis-small.svg';
import { ReactComponent as GeografikumBig } from './imagesWithBackground/icon-geografikum-large.svg';
import { ReactComponent as GeografikumHierarchy } from './imagesWithBackground/icon-geografikum-hierarchy.svg';
import { ReactComponent as GeografikumSmall } from './imagesWithBackground/icon-geografikum-small.svg';
import { ReactComponent as OrganisationBig } from './imagesWithBackground/icon-organization-large.svg';
import { ReactComponent as OrganisationHierarchy } from './imagesWithBackground/icon-organization-hierarchy.svg';
import { ReactComponent as OrganisationSmall } from './imagesWithBackground/icon-organization-small.svg';
import { ReactComponent as PersonBig } from './imagesWithBackground/icon-person-large.svg';
import { ReactComponent as PersonHierarchy } from './imagesWithBackground/icon-person-hierarchy.svg';
import { ReactComponent as PersonSmall } from './imagesWithBackground/icon-person-small.svg';
import { ReactComponent as SachbegriffBig } from './imagesWithBackground/icon-sachgebiet-large.svg';
import { ReactComponent as SachbegriffHierarchy } from './imagesWithBackground/icon-sachgebiet-hierarchy.svg';
import { ReactComponent as SachbegriffSmall } from './imagesWithBackground/icon-sachgebiet-small.svg';
import { ReactComponent as VeranstaltungBig } from './imagesWithBackground/icon-event-large.svg';
import { ReactComponent as VeranstaltungHierarchy } from './imagesWithBackground/icon-event-hierarchy.svg';
import { ReactComponent as VeranstaltungSmall } from './imagesWithBackground/icon-event-small.svg';
import { ReactComponent as WerkBig } from './imagesWithBackground/icon-werk-large.svg';
import { ReactComponent as WerkHierarchy } from './imagesWithBackground/icon-werk-hierarchy.svg';
import { ReactComponent as WerkSmall } from './imagesWithBackground/icon-werk-small.svg';

const bigIconStyles = css`
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
`;

const hierarchyIconStyles = css`
  width: 22px;
  height: 22px;
  min-width: 22px;
  min-height: 22px;
`;

const smallIconStyles = css`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
`;

export const IconBauwerkBig = styled(BauwerkBig)`
  ${bigIconStyles}
`;
export const IconEreignisBig = styled(EreignisBig)`
  ${bigIconStyles}
`;
export const IconGeografikumBig = styled(GeografikumBig)`
  ${bigIconStyles}
`;
export const IconOrganisationBig = styled(OrganisationBig)`
  ${bigIconStyles}
`;
export const IconPersonBig = styled(PersonBig)`
  ${bigIconStyles}
`;
export const IconSachbegriffBig = styled(SachbegriffBig)`
  ${bigIconStyles}
`;
export const IconVeranstaltungBig = styled(VeranstaltungBig)`
  ${bigIconStyles}
`;
export const IconWerkBig = styled(WerkBig)`
  ${bigIconStyles}
`;

export const IconBauwerkHierarchy = styled(BauwerkHierarchy)`
  ${hierarchyIconStyles}
`;
export const IconEreignisHierarchy = styled(EreignisHierarchy)`
  ${hierarchyIconStyles}
`;
export const IconGeografikumHierarchy = styled(GeografikumHierarchy)`
  ${hierarchyIconStyles}
`;
export const IconOrganisationHierarchy = styled(OrganisationHierarchy)`
  ${hierarchyIconStyles}
`;
export const IconPersonHierarchy = styled(PersonHierarchy)`
  ${hierarchyIconStyles}
`;
export const IconSachbegriffHierarchy = styled(SachbegriffHierarchy)`
  ${hierarchyIconStyles}
`;
export const IconVeranstaltungHierarchy = styled(VeranstaltungHierarchy)`
  ${hierarchyIconStyles}
`;
export const IconWerkHierarchy = styled(WerkHierarchy)`
  ${hierarchyIconStyles}
`;

export const IconBauwerkFacet = styled(BauwerkSmall)`
  ${smallIconStyles}
`;
export const IconEreignisFacet = styled(EreignisSmall)`
  ${smallIconStyles}
`;
export const IconGeografikumFacet = styled(GeografikumSmall)`
  ${smallIconStyles}
`;
export const IconOrganisationFacet = styled(OrganisationSmall)`
  ${smallIconStyles}
`;
export const IconPersonFacet = styled(PersonSmall)`
  ${smallIconStyles}
`;
export const IconSachbegriffFacet = styled(SachbegriffSmall)`
  ${smallIconStyles}
`;
export const IconVeranstaltungFacet = styled(VeranstaltungSmall)`
  ${smallIconStyles}
`;
export const IconWerkFacet = styled(WerkSmall)`
  ${smallIconStyles}
`;
