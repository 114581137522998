import { AutoSuggestItem, AutoSuggestGroups } from '../../../model/autosuggest';
import React from 'react';
import { sanitizeHTML } from '../../../../utils/sanitizeHTML';
import { escapeHtmlChars } from '../../../model/autosuggest/escapeHtmlChars';
import { AdditionalText } from '../AdditionalText';
import { ItemText, TextPart } from '../style';

interface Props {
  suggestion: AutoSuggestItem;
}

export function AutosuggestText(props: Props) {
  const mainText: string = formatMainText(props.suggestion);
  const additionalText: string | undefined =
    props.suggestion.additionalInformation;

  return (
    <ItemText>
      <TextPart dangerouslySetInnerHTML={{ __html: sanitizeHTML(mainText) }} />
      {additionalText && <AdditionalText text={additionalText} />}
    </ItemText>
  );
}

function formatMainText(suggestion: AutoSuggestItem): string {
  let text: string = '';
  switch (suggestion.group) {
    case AutoSuggestGroups.entity_title:
    case AutoSuggestGroups.term:
      text = escapeHtmlChars(suggestion.text);
      break;
    case AutoSuggestGroups.entity_text:
      text = suggestion?.payloadTerm ?? '';
  }

  if (suggestion.fuzzy) {
    text = `<em>${text}</em>`;
  }

  return text;
}
