import { SearchRequestQuery } from '../../api/responses';
import { AbstractQuery } from './AbstractQuery';
import { TermsQuery } from './TermsQuery';
import { QueryConfigurationTypes } from './QueryType';

export class QueryFactory {
  public static create(json: SearchRequestQuery): AbstractQuery {
    const query = AbstractQuery.create(
      TermsQuery,
      json.id,
      json.type,
      json.empty,
    );
    query.term = json.term;
    return query;
  }

  public static createEmpty(): AbstractQuery {
    const query = AbstractQuery.create(
      TermsQuery,
      'default',
      QueryConfigurationTypes.edismax,
      false,
    );
    query.term = '';
    return query;
  }
}
