import { DEFAULT_PAGE_SIZE } from '../../../features/Config';
import { RequestFacetList } from '../facets';
import {
  RequestFacetListFactory,
  FilterListFactory,
  SearchRequestFactory,
  SortFactory,
} from '../factories';
import { FilterList } from '../filters';
import { QueryFactory, TermsQuery } from '../query';
import { SearchRequest } from './SearchRequest';

export class SearchRequestBuilder {
  private _searchRequest: SearchRequest;

  constructor() {
    this._searchRequest = new SearchRequest(
      QueryFactory.createEmpty() as TermsQuery,
      FilterListFactory.createEmpty(),
      RequestFacetListFactory.createEmpty(),
      SortFactory.createEmpty(),
      0,
      DEFAULT_PAGE_SIZE,
    );
  }

  protected resetRequest() {
    this._searchRequest = SearchRequestFactory.createEmpty();
  }

  public build(): SearchRequest {
    if (this._searchRequest.rows === undefined) {
      this._searchRequest.resetRows();
    }
    if (this._searchRequest.start === undefined) {
      this._searchRequest.resetStart();
    }
    return this._searchRequest;
  }

  public query(query: TermsQuery): SearchRequestBuilder {
    this._searchRequest.query = query;
    return this;
  }

  public facets(facets: RequestFacetList): SearchRequestBuilder {
    this._searchRequest.facetList = facets;
    return this;
  }

  public filters(filters: FilterList): SearchRequestBuilder {
    this._searchRequest.filterList = filters;
    return this;
  }

  public sort(sort: string): SearchRequestBuilder {
    this._searchRequest.sort = sort;
    return this;
  }

  public position(position: number): SearchRequestBuilder {
    this._searchRequest.position = position;
    return this;
  }

  public start(start: number): SearchRequestBuilder {
    this._searchRequest.start = start;
    return this;
  }

  public rows(rows): SearchRequestBuilder {
    this._searchRequest.rows = rows;
    return this;
  }

  public getSearchRequest() {
    const searchRequest = this._searchRequest;
    this.resetRequest();
    return searchRequest;
  }
}
