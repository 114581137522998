import styled, { css } from 'styled-components/macro';

export const visuallyHiddenStyles = css`
  width: 0;
  height: 0;
  position: fixed;
  top: -9999px;
  left: -9999px;
  overflow: hidden;
`;

export const VisuallyHidden = styled.div<{ $disableSelect?: boolean }>`
  ${visuallyHiddenStyles};

  ${props =>
    props.$disableSelect &&
    css`
      user-select: none;
    `};
`;
