import { useLocation } from 'react-router-dom';
import { DEFAULT_PAGE_SIZE } from '../features/Config';

export function useQuery() {
  const search = useLocation().search;
  return new URLSearchParams(search);
}

export function useQueryParam(paramName: string) {
  const params = useQuery();
  return params.has(paramName) ? params.get(paramName) : null;
}

export function useQueryParamPos(): number {
  const posParam: string | null = useQueryParam('pos');
  return posParam ? Math.max(0, parseInt(decodeURIComponent(posParam), 10)) : 0;
}

export function useQueryParamRows(): number {
  const rowsParam: string | null = useQueryParam('rows');
  return rowsParam
    ? Math.max(0, parseInt(decodeURIComponent(rowsParam), 10))
    : DEFAULT_PAGE_SIZE;
}
