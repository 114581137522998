export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

export function joinStrings(list: string[], glue: string) {
  return list.filter(s => !!s).join(glue);
}

export function findDuplicates(list: string[]) {
  return list.filter((item, index) => list.indexOf(item) !== index);
}
