import React from 'react';
import { lazyLoad } from 'utils/loadable';
import { PageLayout } from '../components/PageLayout';

// noFooter prop to avoid loading the versions
export const EntityPage = lazyLoad(
  () => import('./EntityPage'),
  module => module.EntityPage,
  { fallback: <PageLayout noFooter /> },
);

export const HomePage = lazyLoad(
  () => import('./HomePage'),
  module => module.HomePage,
  { fallback: <PageLayout noFooter /> },
);

export const NotFoundPage = lazyLoad(
  () => import('./NotFoundPage'),
  module => module.NotFoundPage,
  { fallback: <PageLayout noFooter /> },
);

export const SearchPage = lazyLoad(
  () => import('./SearchPage'),
  module => module.SearchPage,
  { fallback: <PageLayout noFooter /> },
);

export const ServiceUnavailablePage = lazyLoad(
  () => import('./ServiceUnavailablePage'),
  module => module.ServiceUnavailablePage,
  { fallback: <PageLayout noFooter /> },
);

export const AccessibilityPage = lazyLoad(
  () => import('./AccessibilityPage'),
  module => module.AccessibilityPage,
  { fallback: <PageLayout noFooter /> },
);
