import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from '../../../store';

export const selectAutosuggest = (state: RootState) =>
  state.autosuggest || initialState;

export const selectSuggestions = createSelector(
  [selectAutosuggest],
  substate => substate.suggestions,
);

export const selectAutoSuggestRequestError = createSelector(
  [selectAutosuggest],
  state => state.request.error,
);
