import { DefaultTheme } from 'styled-components/macro';

const baseFontSize: Pick<DefaultTheme, 'baseFontSize'>['baseFontSize'] = 16;

const breakpoints_in_px: Pick<DefaultTheme, 'breakpoints'>['breakpoints'] = {
  mobile: 414,
  tablet_portrait: 768,
  tablet_landscape: 1024,
  desktop: 1280,
  largeY: 1080,
};

export function pxToRem(pxValue) {
  return pxValue / baseFontSize;
}

const grid_in_rem: Pick<DefaultTheme, 'grid'>['grid'] = {
  gutter: {
    mobile: pxToRem(20),
    tablet_portrait: pxToRem(20),
    tablet_landscape: pxToRem(23),
    desktop: pxToRem(36),
  },
};

const page_padding_in_rem: Pick<DefaultTheme, 'pagePadding'>['pagePadding'] = {
  mobile_left: pxToRem(31),
  mobile_right: pxToRem(52),
  tablet_portrait_left: pxToRem(50),
  tablet_portrait_right: pxToRem(65),
  tablet_landscape: pxToRem(80),
  desktop: pxToRem(80),
};

type ToRemFn = (
  min: number,
  max: number,
) => Pick<DefaultTheme, 'spacing'>['spacing'];
const spacing_in_rem: ToRemFn = (min: number, max: number) => {
  return [...Array(max - min + 1).keys()].reduce((acc: {}, key) => {
    const n = min + key;
    acc[`_${n}`] = n / baseFontSize;
    return acc;
  }, {});
};

const font: Pick<DefaultTheme, 'font'>['font'] = {
  family: 'EuclidCircularA, sans-serif',
  weight: {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
  },
  size: spacing_in_rem(10, 36),
};

const color: Pick<DefaultTheme, 'color'>['color'] = {
  black: '#000',
  white: '#FFF',
  blue_logo: '#0068FF',
  blue_bg_hover_details_nav: 'rgba(0,104,255, 0.1)',
  blue_text: '#0066CC',
  gray_autosuggest_separator: '#5F5F5F',
  gray_bg_timeline_bar: '#8E8E8E',
  gray_bg_copy_tooltip: '#5F5F5F',
  gray_bg_search_box: '#F5F5F5',
  gray_border_graph_button: '#CBCBCB',
  gray_gradient_light: '#F9F9F9',
  gray_gradient_dark: '#E1E1E1',
  gray_horizontal_separator: '#979797',
  gray_hr_details_nav: '#C2C2C2',
  gray_language_border: '#D8D8D8',
  gray_language_hover: '#EEEEEE',
  gray_map_legend_off: '#E2E2E2',
  gray_super_dark: '#333333',
  red: '#CC0000',
  pink: '#B83593',
  purple: '#510083',
  yellow: '#D69A00',
};

export const defaultTheme: DefaultTheme = {
  breakpoints: breakpoints_in_px,
  grid: grid_in_rem,
  pagePadding: page_padding_in_rem,
  pageContentMaxWidth: 1900,
  spacing: spacing_in_rem(2, 400),
  baseFontSize,
  font: font,
  color: color,
  zIndex: {
    dropdown: 5,
    overlay: 10,
    // TODO replace all zIndexes
  },
  layout: {
    filterPanelWidth: pxToRem(breakpoints_in_px.mobile),
    infoPanelWidth: pxToRem(350),
  },
  classes: {
    hasInfopanel: 'has-infopanel',
    hasFiltersPanel: 'has-filters-panel',
  },
};
