import React from 'react';
import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES } from '../../../locales/i18n';
import { useLanguageSwitcher } from '../../hooks/useLanguageSwitcher';
import LanguageButton from './LanguageButton';
import { messages } from './messages';
import { Nav, List, ListItem } from './style';

export function LanguageSwitcher() {
  useLanguageSwitcher();
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <Nav aria-label={t(messages.navAriaLabel)}>
      <List aria-label={t(messages.navAriaLabel)}>
        {SUPPORTED_LANGUAGES.map((lang: string) => (
          <ListItem key={lang}>
            <LanguageButton language={lang} current={currentLanguage} />
          </ListItem>
        ))}
      </List>
    </Nav>
  );
}
