import { EntityFields } from './EntityFields';

export class EntityFieldsGetters {
  protected _fields: EntityFields;
  protected _title: string | null = null;
  protected _icon: string | null = null;
  protected _entityCodes: string[] | null = null;
  protected _internalId: string | null = null;
  protected _id: string | null = null;
  protected _recordType: string | null = null;

  public constructor(fields: EntityFields) {
    this._fields = fields;
  }

  get id(): string {
    if (!this._id) {
      const values: string[] = this._fields.getSubfieldValuesForMatchingName(
        '007K',
        '0',
      );
      this._id = values.length > 0 ? values[0] : '';
    }
    return this._id;
  }

  get internalId(): string {
    if (!this._internalId) {
      const values: string[] = this._fields.getSubfieldValuesForMatchingName(
        '003@',
        '0',
      );
      this._internalId = values.length > 0 ? values[0] : '';
    }
    return this._internalId;
  }

  get title(): string {
    if (!this._title) {
      const values: string[] = this._fields.getSubfieldValuesForMatchingName(
        'head',
        'name',
      );
      this._title = values.length > 0 ? values[0] : '';
    }
    return this._title;
  }

  get icon(): string {
    if (this._icon === null) {
      const values: string[] = this._fields.getSubfieldValuesForMatchingName(
        'head',
        'icon',
      );
      this._icon = values.length > 0 ? values[0] : '';
    }
    return this._icon;
  }

  get entityCodes(): string[] {
    if (!this._entityCodes) {
      this._entityCodes = this._fields.getSubfieldValuesForMatchingName(
        '004B',
        'a',
      );
    }
    return this._entityCodes;
  }

  get recordType(): string {
    if (!this._recordType) {
      this._recordType =
        this._fields.getSubfieldValuesForMatchingName('002@', '0')[0] || '';
    }
    return this._recordType;
  }
}
