import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as api from '../../../api';
import { handleRequestError } from '../../../model/api/request';
import { selectEntityTitleById } from '../selectors';
import { actions } from '../slice';

export function* fetchEntityTitleSaga() {
  yield takeLatest(actions.fetchEntityTitle.type, fetchTitle);
}

export function* fetchTitle(action) {
  const { id } = action.payload;
  // fetch only if not cached
  const cachedTitle = yield select(selectEntityTitleById(id));
  const shouldFetch = !cachedTitle;
  if (!shouldFetch) {
    return { title: cachedTitle };
  }
  try {
    const jsonResponse = yield call(api.fetchEntityTitleById, id);
    yield put(
      actions.fetchEntityTitleSuccess({ id, title: jsonResponse.title }),
    );
  } catch (e: any) {
    yield call(handleRequestError, actions.fetchEntityTitleError, e);
  }
}
