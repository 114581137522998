/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';
import { routerReducer } from './history';

import autosuggestReducer, {
  sliceKey as autosuggestKey,
} from '../app/features/Autosuggest/slice';
import configReducer, {
  sliceKey as configKey,
} from '../app/features/Config/slice';
import entityTitleReducer, {
  sliceKey as entityTitleKey,
} from '../app/features/EntityTitle';
import facetsReducer, {
  sliceKey as facetsKey,
} from '../app/features/Facets/slice';
import generalUIReducer, {
  sliceKey as generalUIKey,
} from '../app/features/GeneralUI/slice';
import searchReducer, {
  sliceKey as searchKey,
} from '../app/features/Search/slice';
import searchConfigurationReducer, {
  sliceKey as searchConfigurationKey,
} from '../app/features/SearchConfiguration/slice';
import timelineReducer, {
  sliceKey as timelineSliceKey,
} from '../app/features/Timeline';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  return combineReducers({
    ...injectedReducers,
    // other non-injected reducers can go here...
    router: routerReducer,
    [autosuggestKey]: autosuggestReducer,
    [configKey]: configReducer,
    [facetsKey]: facetsReducer,
    [generalUIKey]: generalUIReducer,
    [searchKey]: searchReducer,
    [searchConfigurationKey]: searchConfigurationReducer,
    [entityTitleKey]: entityTitleReducer,
    [timelineSliceKey]: timelineReducer,
  });
}
