import { call } from 'redux-saga/effects';
import { request } from '../../utils/request';
import { SearchResponse } from '../model/api/responses';
import { Context, SearchContext } from '../model/search/context';
import { getSearchRequestUrl } from './getRequestUrl';

export function* fetchTimelineMinMaxYears(queryString: string) {
  const context: SearchContext = Context.timelineMinYear;
  const pathname = yield call(getSearchRequestUrl, context);

  const url = [pathname, queryString].join('?');
  const response: SearchResponse = yield call(request, url);

  if (!response) {
    throw new Error('No data');
  }
  return response;
}
