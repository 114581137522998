export enum TranslationKey {
  name = 'name',
  tooltip = 'tooltip',
  baseurl = 'baseurl',
  url = 'url',
  fixedurl = 'fixedurl',
}
export enum HelmetTranslationKey {
  title = 'title',
  description = 'description',
}
export class TranslationKeyBuilder {
  public static build(
    code: string,
    namespace: string,
    key: string = TranslationKey.name,
  ) {
    return `${namespace}.${code}.${key}`;
  }

  public static helmet(code: string, key: string = HelmetTranslationKey.title) {
    return TranslationKeyBuilder.build(code, 'helmet', key);
  }

  public static getDefaultNamespace() {
    return 'translation';
  }

  public static getRelationsNamespace() {
    return 'relationCodes';
  }

  public static getSatzartNamespace() {
    return 'satzart';
  }

  public static getSortNamespace() {
    return 'sort';
  }

  public static satzart(satzart: string) {
    return `satzart.${satzart}`;
  }

  public static iconTooltip(satzart: string) {
    return `satzart.${satzart}.icon.tooltip`;
  }

  public static getHierarchyNamespace() {
    return 'hierarchy';
  }

  public static getRelationsJoinFilterNameNamespaces(): string[] {
    return [
      TranslationKeyBuilder.getDefaultNamespace(),
      TranslationKeyBuilder.getSatzartNamespace(),
      TranslationKeyBuilder.getRelationsNamespace(),
    ];
  }
}
