import styled, { css } from 'styled-components';
import { customScrollbarStyles } from '../HTML';

const autosuggestScrollBarStyles = css`
  ${customScrollbarStyles}

  ::-webkit-scrollbar {
    width: ${props => props.theme.spacing._18}rem;
  }

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover {
    border: ${props => props.theme.spacing._6}rem solid
      ${props => props.theme.color.white};
    background-clip: padding-box;
  }
`;

export const AutoSuggestList = styled.ul`
  z-index: 999;
  position: absolute;
  padding: 0px;
  margin-top: ${props => props.theme.spacing._4}rem;
  margin-right: ${props => props.theme.spacing._6}rem;

  background-color: ${props => props.theme.color.white};
  box-shadow: 0px 4px 9px 0px rgba(179, 180, 168, 0.75);

  width: 100%;
  max-height: ${props => props.theme.spacing._300}rem;

  overflow-y: scroll;
  ${autosuggestScrollBarStyles}
`;
interface SelectableProps {
  $selected: boolean;
}

export const ListItem = styled.li<SelectableProps>`
  display: grid;

  grid-template-columns: ${props => props.theme.spacing._24}rem 1fr;
  grid-gap: ${props => props.theme.spacing._12}rem;

  padding-left: ${props => props.theme.spacing._20}rem;
  padding-right: ${props => props.theme.spacing._20}rem;
  padding-top: ${props => props.theme.spacing._3}rem;
  padding-bottom: ${props => props.theme.spacing._3}rem;

  list-style-type: none;

  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.color.blue_bg_hover_details_nav};
  }

  ${props =>
    props.$selected &&
    css`
      background-color: ${props => props.theme.color.blue_bg_hover_details_nav};
    `}
`;

export const ItemText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TextPart = styled.div`
  display: inline;
`;

export const PartSeparator = styled(TextPart)`
  color: ${props => props.theme.color.gray_autosuggest_separator};
`;
