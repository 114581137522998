import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';
import { RootState } from '../../../store';

// First makeSelect the relevant part from the state
const selectAppConfiguration = (state: RootState) =>
  state.config || initialState;

export const selectApiUrl = () =>
  createSelector(selectAppConfiguration, config => config.api.searchServiceUrl);

export const selectImageServiceUrl = createSelector(
  selectAppConfiguration,
  config => {
    const url = config.api.imageServiceUrl;
    return url.endsWith('/') ? url : `${url}/`;
  },
);

export const selectApiVersion = () =>
  createSelector(selectAppConfiguration, config => config.api.version);

export const selectRelationsGroupSize = createSelector(
  selectAppConfiguration,
  config => config.api.relationsGroupSize,
);

export const selectDevDataEnabled = createSelector(
  selectAppConfiguration,
  config => config.devData,
);

export const selectAppVersion = createSelector(
  selectAppConfiguration,
  config => config.appMetadata.release,
);

export const selectSearchRowsConfigurations = createSelector(
  selectAppConfiguration,
  config => config.searchResultsPerPage,
);
