import { SearchRequestTermsFilter } from '../../api/responses';
import { FACET_MISSING_VALUE } from '../facets';
import { AbstractBucket } from './AbstractBucket';

export class Bucket extends AbstractBucket {
  protected _isFilter: boolean = false;

  // TODO: not used: delete and test
  get hasDocs(): boolean {
    return this.count > 0;
  }

  get title(): string {
    return this._value;
  }

  // TODO: not used: delete and test
  get isFilter(): boolean {
    return this._isFilter;
  }

  get isMissing(): boolean {
    return this.value === FACET_MISSING_VALUE;
  }

  public setFilter(filter: SearchRequestTermsFilter): void {
    if (filter.empty) {
      this._isFilter = false;
    }
    this._isFilter = filter.terms.includes(this.value);
  }
}
