import { call, select, takeLatest } from 'redux-saga/effects';
import { MatomoEvents, track } from '../../../../utils/matomo';
import { SearchRequest } from '../../../model/search/request';
import { actions } from '../slice';
import { selectSearchRequest } from '../selectors';
import { goToSearchPage } from './goToSearchPage';

export const rowsSagaKey = 'rowsSaga';
export function* rowsSaga() {
  yield takeLatest(actions.updateRows.type, updateRows);
}

function* updateRows(action) {
  track(MatomoEvents.SearchRows, action.payload.rows);

  const searchRequest: SearchRequest = yield select(selectSearchRequest);
  searchRequest.updateRows(action.payload.rows);
  const searchQuery: string = searchRequest.buildUrlQueryString();
  yield call(goToSearchPage, action.payload.lang, searchQuery);
}
