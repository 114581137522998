import { UrlQueryBuilder } from '../request';
import { QueryConfigurationType } from './QueryType';

export abstract class AbstractQuery {
  protected _id: string;
  protected _type: QueryConfigurationType;
  protected _empty: boolean;

  public constructor(id: string, type: QueryConfigurationType, empty: boolean) {
    this._id = id;
    this._type = type;
    this._empty = empty;
  }

  public static create<Q extends AbstractQuery>(
    QueryInstance: new (
      id: string,
      type: QueryConfigurationType,
      empty: boolean,
    ) => Q,
    id: string,
    type: QueryConfigurationType,
    empty: boolean,
  ): Q {
    const q: Q = new QueryInstance(id, type, empty);
    return q;
  }

  public abstract parseUrlSearchParams(params: URLSearchParams): void;
  public abstract addToUrlSearchParams(builder: UrlQueryBuilder): void;

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get type(): QueryConfigurationType {
    return this._type;
  }

  set type(type: QueryConfigurationType) {
    this._type = type;
  }

  get empty(): boolean {
    return this._empty;
  }

  set empty(empty: boolean) {
    this._empty = empty;
  }
}
