import { Outlet, RouteObject } from 'react-router-dom';
import { SUPPORTED_LANGUAGES } from '../../locales/i18n';
import {
  AccessibilityPage,
  EntityPage,
  HomePage,
  NotFoundPage,
  SearchPage,
  ServiceUnavailablePage,
} from '../pages';

export enum Routes {
  Home = '/',
  Search = 'search',
  SearchView = 'search/:view',
  ServiceUnavailable = 'service-unavailable-test',
  Result = 'gnd/:id',
  ResultView = 'gnd/:id/:view',
  Accessibility = 'accessibility',
  fallback = '*',
}

const baseRoutes: RouteObject[] = [
  { index: true, element: <HomePage /> },
  { path: Routes.Search, element: <SearchPage /> },
  { path: Routes.SearchView, element: <SearchPage /> },
  { path: Routes.Result, element: <EntityPage /> },
  { path: Routes.ResultView, element: <EntityPage /> },
  { path: Routes.Accessibility, element: <AccessibilityPage /> },
];
if (window?.['appConfig']?.devData) {
  baseRoutes.push({
    path: Routes.ServiceUnavailable,
    element: <ServiceUnavailablePage />,
  });
}
baseRoutes.push({ path: Routes.fallback, element: <NotFoundPage /> });

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Outlet />,
    children: [
      ...baseRoutes,
      ...SUPPORTED_LANGUAGES.map((langCode: string) => ({
        path: langCode,
        element: <Outlet />,
        children: baseRoutes,
      })),
    ],
  },
];
