import { put, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import {
  contextPathMapper,
  SearchContext,
} from '../../../model/search/context';
import { getPathStartsWithRegex, RouteUtils } from '../../../routes';
import { SearchView, SearchViews, selectSearchView } from '../../GeneralUI';
import { selectCurrentSearchContext } from '../selectors';

export function* goToSearchPage(
  lang: string,
  queryString: string,
  hash?: string,
) {
  const searchContext: SearchContext = yield select(selectCurrentSearchContext);
  const searchView: SearchView = yield select(selectSearchView);
  const pathView: string =
    searchView === SearchViews.list ? '' : `/${searchView}`;
  const pathname: string = `/${contextPathMapper[searchContext]}${pathView}`;

  if (hasUrlChanged(pathname, queryString, hash)) {
    const url = RouteUtils.localizeRoute(lang, `${pathname}${queryString}`);
    yield put(push(url));
    return true;
  }
  return false;
}

function hasUrlChanged(pathname: string, queryStr: string, hash: string = '') {
  const location = window.location;

  const pathnameRegex = getPathStartsWithRegex(pathname);
  if (!pathnameRegex.test(location.pathname)) {
    return true;
  }
  if (hash !== location.hash) {
    return true;
  }

  const newSearch = new URLSearchParams(location.search);
  newSearch.sort();
  const currentSearch = new URLSearchParams(queryStr);
  currentSearch.sort();

  if (newSearch.toString() !== currentSearch.toString()) {
    return true;
  }

  // console.log('no changes');
  return false;
}
