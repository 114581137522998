import { SearchRequestTermsFilter } from '../../api/responses';
import { Operator, Operators } from '../operators';
import { UrlQueryBuilder } from '../request';
import { AbstractFilter, Filter } from './AbstractFilter';
import {
  DisplayFilter,
  DisplayFilterTerm,
  DisplayFilterType,
} from './DisplayFilter';
import { FilterType } from './FilterType';

export type TermsFilterValue = string;

export class TermsFilter extends AbstractFilter<TermsFilterValue> {
  protected _operator: Operator = Operators.AND;
  protected _terms: string[] = [];

  public static createFromRequestFilter(
    data: SearchRequestTermsFilter,
  ): TermsFilter {
    const filter = new TermsFilter(data.id, FilterType.terms);
    filter.init(data);
    return filter;
  }

  public init(data: any): void {
    this.operator = data.operator ? data.operator : Operators.AND;
    this.terms = data.terms ? [...data.terms] : [];
  }

  get operator(): string {
    return this._operator;
  }

  set operator(value: string) {
    this._operator = Operators[value];
  }

  get terms(): string[] {
    return this._terms;
  }

  set terms(terms: string[]) {
    this._terms = terms;
  }

  get length(): number {
    return this.terms.length;
  }

  set term(term: string) {
    // prevent duplicated terms in array
    for (const termInTerms of this.terms) {
      if (termInTerms === term) {
        return;
      }
    }
    this._terms.push(term);
  }

  public parseUrlSearchParams(params: URLSearchParams): void {
    this.terms = [];
    this.terms = params.getAll(this.paramName);
  }

  public addToUrlSearchParams(builder: UrlQueryBuilder): void {
    for (const value of this._terms) {
      builder.add(this.paramName, encodeURIComponent(value));
    }
  }

  public isEmpty(): boolean {
    return this.terms.length === 0;
  }

  public setValue(value: string): void {
    this.term = value;
  }

  public removeValue(term: string): Filter {
    this._terms = this._terms.filter((t: string) => {
      return !t.includes(term);
    });
    return this;
  }

  public resetValues(): Filter {
    this._terms = [];
    return this;
  }

  public addToSelectedFilterTerms(list: DisplayFilter[]): void {
    for (const term of this.terms) {
      const filter: DisplayFilterTerm = {
        filter: this,
        type: DisplayFilterType.term,
        term,
      };
      list.push(filter);
    }
  }

  public isValueSelected(value: string): boolean {
    return this.terms.includes(value);
  }

  public getSelectedFilterTerms(): string[] {
    return this.terms;
  }

  // public toSelectedTerm(selectedTerm: string): string {
  //   return selectedTerm;
  // }

  // public valuesMatch(filter: Filter): boolean {
  //   if (!filter || !(filter instanceof TermsFilter)) return false;
  //   const f: TermsFilter = filter;
  //   return this.terms === f.terms;
  // }

  public clone(): Filter {
    const filter = new TermsFilter(this.id, this.type);
    filter.operator = this.operator;
    filter.terms = [...this.terms];
    return filter;
  }

  protected get paramName(): string {
    return `f.${this.id}`;
  }
}
