import { IconCodes } from '../../../conf/icons';

export interface AutoSuggestItem {
  text: string;
  term: string;
  entityId?: string;
  icon?: IconCodes;
  payloadTerm?: string;
  additionalInformation?: string;
  group: AutoSuggestGroups;
  fuzzy: boolean;
  weight: number;
}

export enum AutoSuggestGroups {
  term = 'term',
  entity_text = 'entity_text',
  entity_title = 'entity_title',
}
