import { call, put, takeEvery } from 'redux-saga/effects';
import * as api from '../../../api';
import { SearchResponse } from '../../../model/api/responses';
import { handleRequestError } from '../../../model/api/request';
import { actions } from '../slice';

export const facetSagaKey = 'facetSaga';
export function* facetSaga() {
  yield takeEvery(actions.fetchFacet.type, fetchFacet);
}

export function* fetchFacet(action) {
  try {
    const jsonResponse: SearchResponse = yield call(
      api.fetchFacet,
      action.payload.facetId,
      action.payload.searchQuery,
    );

    yield put(
      actions.fetchFacetSuccess({
        facetId: action.payload.facetId,
        jsonResponse,
      }),
    );
  } catch (error: any) {
    yield call(handleRequestError, actions.fetchFacetError, error, {
      facetId: action.payload.facetId,
    });
  }
}
