import factsheetConfig from '../../../conf/factsheet';
import relationsConfig from '../../../conf/infopanel/relations';
import {
  EntityResponse,
  EntityResponseField,
  EntityResponseSubfield,
} from '../api/responses';
import { Utils } from '../factsheet/Utils';

export const SUBFIELDS = {
  gnd_number: '>9',
  relation_code: '4',
  icon: '>icon',
  head_title: 'name',
  head_icon: 'icon',
  recordType: '0',
  recordTypeName: '>0',
  intellectual_creator_name: '>name',
};

/** Accessor for recordFields given an EntityResponse */
export function getRecordFields(json: EntityResponse): EntityResponseField[] {
  return json.record.recordFields;
}

/** Find subfield by name and return value or empty string */
export function getFirstSubfieldValue(
  record: EntityResponseField,
  subfield: string,
) {
  const found = record.subs.find(sub => sub.name === subfield);
  return found ? found.value : '';
}

/** Filter matching subfield names and return the values as a list */
export function filterSubfieldValues(
  record: EntityResponseField,
  values: string[],
) {
  return record.subs
    .filter(sub => values.includes(sub.name))
    .map(item => item.value);
}

/** Get the title and icon of an entity from the RecordFieldsJson data */
export function getHeadRecordData(fieldsJson: EntityResponseField[]) {
  const head: EntityResponseField | undefined = fieldsJson.find(
    rec => rec.key === 'head',
  );
  if (!head) {
    return { title: '', icon: '' };
  }
  const title = head.subs.find(
    (s: EntityResponseSubfield) => s.name === SUBFIELDS.head_title,
  );
  const icon = head.subs.find(
    (s: EntityResponseSubfield) => s.name === SUBFIELDS.head_icon,
  );
  return {
    title: title ? title.value : '',
    icon: icon ? icon.value : '',
  };
}

/** Get the Satzart subfield value from EntityResponse */
export function getRecordType(fieldsJson: EntityResponseField[]) {
  const recordType: EntityResponseField | undefined = fieldsJson.find(
    rec => rec.key === '002@',
  );
  if (!recordType) {
    return '';
  }
  return getFirstSubfieldValue(recordType, SUBFIELDS.recordType);
}

export function getRecordTypeName(fieldsJson: EntityResponseField[]) {
  const recordType: EntityResponseField | undefined = fieldsJson.find(
    rec => rec.key === '002@',
  );
  if (!recordType) {
    return '';
  }
  return getFirstSubfieldValue(recordType, SUBFIELDS.recordTypeName);
}

/** Get the title from EntityResponse */
export function getTitle(json: EntityResponse) {
  const fieldsJson = getRecordFields(json);
  return getHeadRecordData(fieldsJson).title;
}

/** Get the title from EntityResponse */
export function checkId(json: EntityResponse, id: string) {
  return id === json.id || id === json.idn;
}

/** Filter only fields in the configuration, that contain both
 * a link id (subfield >9) and a relation code (subfield 4)
 * >9 contains always the entity id (aka gnd-number) */
export function getRelationsData(
  fieldsJson: EntityResponseField[],
): EntityResponseField[] {
  return fieldsJson.filter(
    (record: EntityResponseField) =>
      relationsConfig.hasOwnProperty(record.key) &&
      record.subs.some(sub => sub.name === SUBFIELDS.relation_code) &&
      record.subs.some(sub => sub.name === SUBFIELDS.gnd_number),
  );
}

/** Filters data as _getRelationsData, that also matches a given entity id */
export function getReverseRelationsData(
  fieldsJson: EntityResponseField[],
  parentId: string,
): EntityResponseField[] {
  return fieldsJson.filter(
    (record: EntityResponseField) =>
      relationsConfig.hasOwnProperty(record.key) &&
      record.subs.some(sub => sub.name === SUBFIELDS.relation_code) &&
      record.subs.some(
        sub => sub.name === SUBFIELDS.gnd_number && sub.value === parentId,
      ),
  );
}

export function getIntellectualCreators(
  fieldsJson: EntityResponseField[],
): string[] {
  const relations = {
    '028R': Utils.pipesToArray(
      factsheetConfig.fields['028R'].label1.geistige_schoepfer.relations,
    ),
    '029R': Utils.pipesToArray(
      factsheetConfig.fields['029R'].label1.geistige_schoepfer.relations,
    ),
    '030R': Utils.pipesToArray(
      factsheetConfig.fields['030R'].label1.geistige_schoepfer.relations,
    ),
    '065R': Utils.pipesToArray(
      factsheetConfig.fields['065R'].label1.geistige_schoepfer.relations,
    ),
  };
  const fields: string[] = Object.keys(relations);

  const intellectualCreators: string[] = [];
  fieldsJson.forEach((record: EntityResponseField) => {
    if (fields.includes(record.key)) {
      const isRelation = record.subs.find(
        sub =>
          sub.name === SUBFIELDS.relation_code &&
          relations[record.key].includes(sub.value),
      );
      if (isRelation) {
        const nameSubfield = record.subs.find(
          sub => sub.name === SUBFIELDS.intellectual_creator_name,
        );
        if (
          nameSubfield &&
          !intellectualCreators.includes(nameSubfield.value)
        ) {
          intellectualCreators.push(nameSubfield.value);
        }
      }
    }
  });
  return intellectualCreators;
}

export function getTitleComplement(fieldsJson: EntityResponseField[]): string {
  if (getHeadRecordData(fieldsJson).icon === 'icon_u') {
    return getIntellectualCreators(fieldsJson).join('; ');
  }
  return '';
}
