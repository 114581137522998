import React from 'react';
import { useIconTooltipTranslation } from '../../../hooks/useIconTooltipTranslation';
import { IconConfiguration } from '../../../../conf/icons';
import { EntityIconSize } from './iconsWithoutBackground';

interface Props {
  icon: string;
  size?: EntityIconSize;
}

export function EntityIcon(props: Props) {
  const tooltip = useIconTooltipTranslation(props.icon);

  if (!IconConfiguration.components.noBackground[props.icon]) {
    return null;
  }
  const Icon = IconConfiguration.components.noBackground[props.icon];

  return <Icon title={tooltip} $size={props.size} />;
}
