import React, { useEffect, useRef } from 'react';
import { AutoSuggestItem } from '../../../model/autosuggest';
import { FacetIcon } from '../../Facet/FacetIcon';
import { AutosuggestText } from '../AutosuggestText';
import { ListItem } from '../style';

interface Props {
  onClick(): void;
  selected: boolean;
  setThisSelected(): void;
  suggestion: AutoSuggestItem;
}

export function AutoSuggestListItem(props: Props) {
  const ref: React.RefObject<HTMLLIElement> = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (props.selected) {
      ref?.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [props.selected]);

  return (
    <ListItem
      ref={ref}
      key={props.suggestion.entityId}
      onClick={props.onClick}
      $selected={props.selected}
      onMouseEnter={props.setThisSelected}
    >
      {props.suggestion.icon ? (
        <FacetIcon iconCode={props.suggestion.icon} />
      ) : (
        <div />
      )}
      <AutosuggestText suggestion={props.suggestion} />
    </ListItem>
  );
}
