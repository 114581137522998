import { call, select, takeLatest } from 'redux-saga/effects';
import { MatomoEvents, track } from '../../../../utils/matomo';
import { ConfigurationResponseFilterMap } from '../../../model/api/responses';
import {
  Filter,
  FilterFactory,
  YearRangeFilter,
  YearRangeFilterValue,
} from '../../../model/search/filters';
import { SearchRequest } from '../../../model/search/request';
import { selectFilterConfigurations } from '../../SearchConfiguration';
import {
  actions as TimelineActions,
  selectTimelineSlider,
  selectTimelineView,
  TimelineNames,
} from '../../Timeline';
import { selectSearchRequest } from '../selectors';
import { goToSearchPage } from './goToSearchPage';

export const timelineFilterSagaKey = 'timelineFilterSaga';
export function* timelineFiltersSaga() {
  yield takeLatest(TimelineActions.filterRange.type, setTimelineFilter);
  yield takeLatest(TimelineActions.resetSlider.type, removeTimelineFilter);
}

function* prepare() {
  const relationCode = yield select(selectTimelineView);
  const filterId: string = YearRangeFilter.getFilterIdForRelation(relationCode);
  const searchRequest: SearchRequest = yield select(selectSearchRequest);
  return { relationCode, filterId, searchRequest };
}

export function* setTimelineFilter(action) {
  const { filterId, relationCode, searchRequest } = yield call(prepare);
  const filter: Filter = yield call(createTimelineFilter, filterId);
  const filterValue: YearRangeFilterValue = yield call(
    createTimelineFilterValue,
    relationCode,
  );
  searchRequest.filterList.addExclusiveFilter(filter, [filterId], filterValue);
  const searchQuery: string = searchRequest.buildUrlQueryString();

  track(MatomoEvents.FilterAdd, filterId);
  yield call(goToSearchPage, action.payload.lang, searchQuery);
}

function* createTimelineFilter(filterId: string) {
  const filterConfigurations: ConfigurationResponseFilterMap = yield select(
    selectFilterConfigurations,
  );
  return FilterFactory.createInstanceFromId(
    filterId,
    filterConfigurations[filterId],
  );
}

function* createTimelineFilterValue(relationCode: string) {
  const slider = yield select(selectTimelineSlider);
  const filterValue: YearRangeFilterValue = {
    relationCode,
    startYear: `${slider[TimelineNames.start]}`,
    endYear: `${slider[TimelineNames.end]}`,
  };
  return filterValue;
}

export function* removeTimelineFilter(action) {
  const { filterId, searchRequest } = yield call(prepare);
  track(MatomoEvents.FilterRemove, `${filterId}`);
  searchRequest.filterList.resetFilterById(filterId);
  const searchQuery: string = searchRequest.buildUrlQueryString();
  yield call(goToSearchPage, action.payload.lang, searchQuery);
}
