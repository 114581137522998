import { call, put } from 'redux-saga/effects';
import * as api from '../../../api';
import { Context } from '../../../model/search/context';
import { SearchResponse } from '../../../model/api/responses';
import { loadSearchConfigurationIfNotLoaded } from '../../SearchConfiguration';
import { actions } from '../slice';
import { addParamFacetsFalse } from './addParamFacetsFalse';
import { handleSearchError } from './handleSearchError';

export function* searchInfopanel(action) {
  try {
    yield call(loadSearchConfigurationIfNotLoaded);

    const { searchQuery } = action.payload;
    const jsonResponse: SearchResponse = yield call(
      api.search,
      addParamFacetsFalse(searchQuery),
      Context.default,
    );

    yield put(
      actions.searchSuccess({ jsonResponse, context: Context.infopanel }),
    );
  } catch (error: any) {
    yield call(handleSearchError, error, Context.infopanel);
  }
}
