import React from 'react';
import { PageHeader } from '../PageHeader';
import { PageFooter } from '../PageFooter';
import { ToTop } from '../ToTop';
import { AsideSync } from './AsideSync';
import { PageGrid, PageMain } from './style';

interface Props {
  noFooter?: boolean;
  searchPage?: boolean;
}

export function PageLayout(props: React.PropsWithChildren<Props>) {
  return (
    <PageGrid>
      <PageHeader />
      <PageMain id="main-content" $searchPage={props.searchPage}>
        {props.children}
      </PageMain>
      {!props.noFooter && <PageFooter />}
      <ToTop />
      <AsideSync />
    </PageGrid>
  );
}
