export enum FilterType {
  terms = 'terms',
  excludingTerms = 'excludingTerms',
  relationJoin = 'relationJoin',
  geoBBox = 'geoBBox',
  geoRelation = 'geoRelation',
  geoNone = 'geoNone',
  yearRange = 'yearRange',
  yearRelation = 'yearRelation',
  yearRangeNone = 'yearRangeNone',
}
