import { RequestState } from '../../model/api/request';
import {
  initialSearchRequestJson,
  SearchResponseRequest,
  SearchResponse,
} from '../../model/api/responses';
import { Context, SearchContext } from '../../model/search/context';

interface SearchResponses {
  [Context.default]: SearchResponse | null;
  [Context.pagination]: SearchResponse | null;
  [Context.infopanel]: SearchResponse | null;
}
export const initialSearchResponses: SearchResponses = {
  [Context.default]: null,
  [Context.pagination]: null,
  [Context.infopanel]: null,
};

interface SearchRequests {
  [Context.default]: SearchResponseRequest;
  [Context.pagination]: SearchResponseRequest;
  [Context.infopanel]: SearchResponseRequest;
}
export const initialSearchRequests: SearchRequests = {
  [Context.default]: initialSearchRequestJson,
  [Context.pagination]: initialSearchRequestJson,
  [Context.infopanel]: initialSearchRequestJson,
};

export interface SearchState {
  readonly requests: {
    [Context.default]: RequestState;
    [Context.pagination]: RequestState;
    [Context.infopanel]: RequestState;
  };
  readonly searchContext: SearchContext;
  readonly searchResponses: SearchResponses;
  readonly searchRequests: SearchRequests;
}
