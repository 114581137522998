import styled from 'styled-components/macro';
import { media } from '../../../../styles';

export const IconWrapper = styled.div`
  margin-right: ${props => props.theme.spacing._14}rem;
  > svg {
    width: 30px;
    height: 30px;
  }
  ${media.tablet_landscape_up`
    margin-right: ${props => props.theme.spacing._11}rem;
    > svg {
      width: 24px;
      height: 24px;
    }
  `}
`;
