import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { initialState } from './slice';

// First makeSelect the relevant part from the state
const selectVersions = (state: RootState) => state.versions || initialState;

export const selectServicesVersions = createSelector(
  [selectVersions],
  state => state.services,
);

export const selectIsLoadingVersions = createSelector(
  [selectVersions],
  state => state.request.loading,
);

export const selectAreVersionsLoaded = createSelector(
  [selectVersions],
  state => state.loaded,
);

export const selectRequestError = createSelector(
  [selectVersions],
  state => state.request.error,
);

export const selectVersionsRequestError = createSelector(
  [selectVersions],
  state => state.request.error,
);
