import styled from 'styled-components/macro';
import { font_Body_Semibold, media } from '../../../styles';
import { Grid } from '../Grid';
import { UL } from '../HTML';
import { contentLayoutShiftRightStyles } from '../PageAside';
import { pageContentStyles } from '../PageLayout/style';

export const Footer = styled.footer`
  grid-area: page--footer;
  background-color: ${props => props.theme.color.white};
  padding-top: ${props => props.theme.spacing._53}rem;
  padding-bottom: ${props => props.theme.spacing._105}rem;

  ${media.tablet_portrait_up`
    padding-bottom: ${props => props.theme.spacing._42}rem;
  `};

  ${media.desktop_up`
    padding-bottom: ${props => props.theme.spacing._55}rem;
  `};

  ${contentLayoutShiftRightStyles};
`;

export const FooterGrid = styled(Grid)`
  ${pageContentStyles};
  align-items: start;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;
  grid-template-areas:
    'version'
    'navigation';

  ${media.desktop_up`
    grid-template-areas: 'version navigation';
    grid-template-columns: auto 1fr;
    -ms-grid-columns: auto ${props =>
      props.theme.grid.gutter.tablet_portrait}rem 1fr;
  `};
`;

export const Versions = styled.section`
  grid-area: version;
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.spacing._22}rem;
  ${media.desktop_up`
    margin-bottom: 0;
  `}
`;

export const Title = styled.span`
  font-weight: ${props => props.theme.font.weight.semibold};
`;

export const List = styled(UL)`
  grid-area: navigation;
  ${font_Body_Semibold};
  display: flex;
  flex-direction: column;
  li {
    margin-bottom: ${props => props.theme.spacing._9}rem;
  }

  ${media.tablet_portrait_up`
    align-items: center;
    flex-flow: row wrap;
    > :not(:last-child) {
      margin-right: ${props => props.theme.spacing._30}rem;
    }
  `};

  ${media.desktop_up`
    justify-self: end;
    li {
      margin-bottom: 0;
    }
  `};
`;
