import { call } from 'redux-saga/effects';
import { request } from '../../utils/request';
import { SearchResponse } from '../model/api/responses';
import { SearchContext } from '../model/search/context';
import { getSearchRequestUrl } from './getRequestUrl';

export function* search(queryParams: string, context: SearchContext) {
  const baseUrl = yield call(getSearchRequestUrl, context);

  const glue = queryParams.startsWith('?') ? '' : `?`;
  const url = [baseUrl, queryParams].join(glue);

  const response: SearchResponse = yield call(request, url);

  if (!response) {
    throw new Error('No data');
  }

  return response;
}
