import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import { initialState } from './slice';
import { TimelineValueType } from './types';

// First makeSelect the relevant part from the state
const selectTimeline = (state: RootState) => state.timeline || initialState;

export const selectTimelineView = createSelector([selectTimeline], substate => {
  return substate.view;
});

export const selectTimelineSlider = createSelector(
  [selectTimeline],
  substate => {
    return substate.sliders[substate.view];
  },
);

export const selectTimelineValue = (name: TimelineValueType) =>
  createSelector([selectTimeline], substate => {
    return substate.sliders[substate.view][name];
  });

export const selectSlidersMinMax = createSelector(
  [selectTimeline],
  substate => {
    return { min: substate.minYear, max: substate.maxYear };
  },
);

export const selectTimelineEarliestYear = createSelector(
  [selectTimeline],
  substate => substate.minYear,
);

export const selectTimelineMinMaxYears = createSelector(
  [selectTimeline],
  substate => {
    return substate.minMaxRequestStatus;
  },
);

export const selectSearchButtonEnabled = createSelector(
  [selectTimeline],
  substate => substate.searchButtonEnabled,
);
