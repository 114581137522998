import TimelineConfig from '../../../../conf/timeline';
import { UrlQueryBuilder } from '../request';
import { AbstractFilter, Filter } from './AbstractFilter';
import { DisplayFilter } from './DisplayFilter';

export interface YearRelationFilterValue {
  relationCode: string;
}

export class YearRelationFilter extends AbstractFilter<YearRelationFilterValue> {
  protected _relationCode: string = '';

  public init(data: any): void {
    this._relationCode = data.relation || '';
  }

  get relationCode(): string {
    return this._relationCode;
  }

  set relationCode(relationCode: string) {
    this._relationCode = relationCode;
  }

  parseUrlSearchParams(params: URLSearchParams): void {
    if (params.has(this.getParamName())) {
      this.relationCode = params.getAll(this.getParamName())[0];
    }
  }

  addToUrlSearchParams(builder: UrlQueryBuilder): void {
    builder.add(this.getParamName(), encodeURIComponent(this._relationCode));
  }

  isEmpty(): boolean {
    return !this._relationCode;
  }

  setValue(value: YearRelationFilterValue): void {
    this._relationCode = value.relationCode;
  }

  removeValue(): Filter {
    return this.resetValues();
  }

  resetValues(): Filter {
    this._relationCode = '';
    return this;
  }

  addToSelectedFilterTerms(list: DisplayFilter[]): void {}

  isValueSelected(value: YearRelationFilterValue): boolean {
    return false;
  }

  getSelectedFilterTerms(): string[] {
    return [];
  }

  clone(): Filter {
    const filter = new YearRelationFilter(this.id, this.type);
    filter.relationCode = this.relationCode;
    return filter;
  }

  protected getParamName(): string {
    return `fct.${TimelineConfig.facets.relation}`;
  }

  public static getYearRelationFilterParamName(): string {
    return `fct.${TimelineConfig.facets.relation}`;
  }

  public static getYearRelationFilterId(): string {
    return TimelineConfig.facets.relation;
  }
}
