import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { defaultTheme } from '../../../styles';
import {
  selectShowFiltersMobileOverlay,
  selectShowInfopanel,
} from '../../features/GeneralUI';

export function AsideSync() {
  const hasInfopanel: boolean = useSelector(selectShowInfopanel);
  const hasFiltersPanel: boolean = useSelector(selectShowFiltersMobileOverlay);

  useLayoutEffect(() => {
    const className = defaultTheme.classes.hasInfopanel;
    if (hasInfopanel && !document.body.classList.contains(className)) {
      document.body.classList.add(className);
    }
    if (!hasInfopanel && document.body.classList.contains(className)) {
      document.body.classList.remove(className);
    }
  }, [hasInfopanel]);

  useLayoutEffect(() => {
    const className = defaultTheme.classes.hasFiltersPanel;
    if (hasFiltersPanel && !document.body.classList.contains(className)) {
      document.body.classList.add(className);
    }
    if (!hasFiltersPanel && document.body.classList.contains(className)) {
      document.body.classList.remove(className);
    }
  }, [hasFiltersPanel]);

  return <div />;
}
