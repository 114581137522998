import { css } from 'styled-components/macro';
import { customScrollbarStyles } from '../HTML';
import { media } from '../../../styles';

const asideContentStyles = css`
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: ${props => props.theme.zIndex.overlay};
  ${customScrollbarStyles}
`;

export const infopanelAsideStyles = css`
  ${asideContentStyles};
  background-color: ${props => props.theme.color.white};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);

  ${media.tablet_portrait_up`
    width: ${props => props.theme.layout.infoPanelWidth}rem;
  `};
`;

export const filtersPanelAsideStyles = css`
  ${asideContentStyles};
  ${media.tablet_portrait_up`
    width: ${props => props.theme.layout.filterPanelWidth}rem;
  `}
`;

export const contentLayoutShiftRightStyles = css`
  transition: margin-left 0.2s ease-in-out;
  body.has-infopanel & {
    ${media.tablet_portrait_up`
      margin-left: ${props => props.theme.layout.infoPanelWidth}rem;
      max-width: calc(100vw - ${props => props.theme.layout.infoPanelWidth}rem);
    `};
  }
  body.has-filters-panel & {
    ${media.tablet_portrait_up`
      margin-left: ${props => props.theme.layout.filterPanelWidth}rem;
      max-width: calc(100vw - ${props =>
        props.theme.layout.filterPanelWidth}rem);
    `};
  }
`;
