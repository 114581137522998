// eslint-disable-next-line import/no-anonymous-default-export
export default {
  relations: ['datb', 'datf', 'datj', 'datl', 'dats', 'datv', 'datw'],
  relationAll: 'all',
  facets: {
    relation: 'year_relation',
    minYear: 'year_min',
    hasRelation: 'has_range_{rel}',
  },
  noRangeFacet: 'yearRange_none',
  filter: 'yearRange',
};
