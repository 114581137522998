import { latLon2Dms, LatLonDecimal, LatLonDms } from '../../coordinates';
import { UrlQueryBuilder } from '../request';
import { AbstractFilter, Filter } from './AbstractFilter';
import {
  DisplayFilter,
  DisplayFilterGeoBBox,
  DisplayFilterType,
} from './DisplayFilter';

// export interface GeoBBoxFilterValue extends FilterValue {
//   bottom: number;
//   left: number;
//   right: number;
//   top: number;
// }
export type GeoBBoxFilterValue = string;

export class GeoBBoxFilter extends AbstractFilter<GeoBBoxFilterValue> {
  protected _bottom: number | undefined;
  protected _left: number | undefined;
  protected _top: number | undefined;
  protected _right: number | undefined;

  public init(data: any): void {
    this._bottom = data.bottom ?? 0.0;
    this._left = data.left ?? 0.0;
    this._top = data.top ?? 0.0;
    this._right = data.right ?? 0.0;
  }

  get bboxValues() {
    return {
      bottom: this._bottom,
      left: this._left,
      top: this._top,
      right: this._right,
    };
  }

  get bottomLeftCoordinates(): LatLonDecimal {
    return { latitude: this._bottom ?? 0, longitude: this._left ?? 0 };
  }

  get topRightCoordinates(): LatLonDecimal {
    return { latitude: this._top ?? 0, longitude: this._right ?? 0 };
  }

  get bboxCoordinatesDms(): [LatLonDms, LatLonDms] {
    return [
      latLon2Dms(
        this.bottomLeftCoordinates.latitude,
        this.bottomLeftCoordinates.longitude,
      ),
      latLon2Dms(
        this.topRightCoordinates.latitude,
        this.topRightCoordinates.longitude,
      ),
    ];
  }

  get filterValue(): string {
    const { left, bottom, right, top } = this.bboxValues;
    return GeoBBoxFilter.buildFilterValue(bottom, left, top, right);
  }

  set left(value) {
    this._left = value;
  }

  set bottom(value) {
    this._bottom = value;
  }

  set right(value) {
    this._right = value;
  }

  set top(value) {
    this._top = value;
  }

  public addToUrlSearchParams(builder: UrlQueryBuilder): void {
    if (this.isEmpty()) {
      return;
    }
    builder.add(
      this.paramName,
      encodeURIComponent(
        GeoBBoxFilter.buildFilterValue(
          this._bottom,
          this._left,
          this._top,
          this._right,
        ),
      ),
    );
  }

  public static buildFilterValue(bottom, left, top, right): string {
    return `[${bottom},${left},${top},${right}]`;
  }

  public parseUrlSearchParams(params: URLSearchParams): void {
    const paramValue: string[] = params.getAll(this.paramName);
    if (paramValue.length === 0) {
      return;
    }

    const [bottom, left, top, right] = paramValue[0]
      .replace('[', '')
      .replace(']', '')
      .split(',');
    this._bottom = bottom ? Number.parseFloat(bottom) : undefined;
    this._left = left ? Number.parseFloat(left) : undefined;
    this._top = top ? Number.parseFloat(top) : undefined;
    this._right = right ? Number.parseFloat(right) : undefined;
  }

  public isEmpty(): boolean {
    return !this._left || !this._bottom;
  }

  public setValue(value: string): void {
    const [bottom, left, top, right] = value
      .replace('[', '')
      .replace(']', '')
      .split(',');
    this._bottom = bottom ? Number.parseFloat(bottom) : undefined;
    this._left = left ? Number.parseFloat(left) : undefined;
    this._top = top ? Number.parseFloat(top) : undefined;
    this._right = right ? Number.parseFloat(right) : undefined;
  }

  public removeValue(value: string): Filter {
    return this.resetValues();
  }

  public resetValues(): Filter {
    this._left = undefined;
    this._bottom = undefined;
    this._right = undefined;
    this._top = undefined;
    return this;
  }

  public addToSelectedFilterTerms(list: DisplayFilter[]): void {
    const filter: DisplayFilterGeoBBox = {
      filter: this,
      type: DisplayFilterType.geoBBox,
      dmsBBox: this.bboxCoordinatesDms,
    };
    list.push(filter);
  }

  public isValueSelected(value: string): boolean {
    return this.filterValue === value;
  }

  public getSelectedFilterTerms(): string[] {
    return [this.filterValue];
  }

  // public toSelectedTerm(selectedTerm: string): string {
  //   return this.filterValue;
  // }

  // public valuesMatch(filter: Filter): boolean {
  //   if (!filter || !(filter instanceof GeoBBoxFilter)) return false;
  //   const f: GeoBBoxFilter = filter;
  //   return this.filterValue === f.filterValue;
  // }

  public clone(): Filter {
    const filter = new GeoBBoxFilter(this.id, this.type);
    const coordinates = this.bboxValues;
    filter.left = coordinates.left;
    filter.bottom = coordinates.bottom;
    filter.right = coordinates.right;
    filter.top = coordinates.top;
    return filter;
  }

  protected get paramName(): string {
    return `f.${this.id}`;
  }
}
