import { IconCodes } from '../../../../conf/icons';
import React from 'react';
import { resolveSatzartFacetIconProps } from '../../../model/icons/iconUtils';
import { IconWrapper } from './style';

interface Props {
  iconCode: IconCodes;
}

export function FacetIcon(props: Props) {
  const { color, Icon } = resolveSatzartFacetIconProps(props.iconCode);

  return (
    <IconWrapper
      data-name={`satzart-${props.iconCode}`}
      data-icon={props.iconCode}
    >
      <Icon style={{ color: color }} aria-hidden />
    </IconWrapper>
  );
}
