export function escapeHtmlChars(text: string): string {
  let escaped: string = text
    .replaceAll(/</g, '&lt;')
    .replaceAll(/>/g, '&gt;')
    .replaceAll(/&lt;b&gt;/g, '<b>')
    .replaceAll(/&lt;\/b&gt;/g, '</b>')
    .replaceAll(/&lt;em&gt;/g, '<em>')
    .replaceAll(/&lt;\/em&gt;/g, '</em>')
    .replaceAll(/&lt;i&gt;/g, '<i>')
    .replaceAll(/&lt;\/i&gt;/g, '</i>');
  return escaped;
}
