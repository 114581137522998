import { call, put, select } from 'redux-saga/effects';
import { Context } from '../../../model/search/context';
import {
  loadSearchConfigurationIfNotLoaded,
  selectSearchConfiguration,
} from '../../SearchConfiguration';
import {
  SearchConfigurationResponse,
  SearchResponse,
} from '../../../model/api/responses';
import * as api from '../../../api';
import { SearchRequest } from '../../../model/search/request';
import { SearchRequestFactory } from '../../../model/search/factories';
import { selectRouterHash } from '../../Router/selectors';
import { actions } from '../slice';
import { goToSearchPage } from './goToSearchPage';
import { handleSearchError } from './handleSearchError';
import { prepareSearchQuery } from './doSearch';

export function* doDefaultSearch(action) {
  const searchQuery = prepareSearchQuery(action);
  yield call(doSearchQuery, action.payload.lang, searchQuery);
}

export function* doSearchQuery(lang: string, searchQuery: string) {
  try {
    yield call(loadSearchConfigurationIfNotLoaded);

    const jsonResponse: SearchResponse = yield call(
      api.search,
      searchQuery,
      Context.default,
    );

    yield put(
      actions.searchSuccess({ jsonResponse, context: Context.default }),
    );

    const { queryString, hash } = yield call(
      resolveQueryStringAndHash,
      jsonResponse,
    );
    yield call(goToSearchPage, lang, queryString, hash);
  } catch (error: any) {
    yield call(handleSearchError, error, Context.default);
  }
}

function* resolveQueryStringAndHash(jsonResponse: SearchResponse) {
  const searchConfiguration: SearchConfigurationResponse = yield select(
    selectSearchConfiguration,
  );
  const searchRequest: SearchRequest =
    SearchRequestFactory.createFromResponseRequest(
      jsonResponse.request,
      searchConfiguration,
    );

  const queryString: string = searchRequest.buildUrlQueryString();
  let urlHash: string = yield select(selectRouterHash);

  return { queryString, hash: urlHash };
}
