import { TermsFilter } from './TermsFilter';
import { Operators } from '../operators';
import { FilterType } from './FilterType';

export class ExcludingTermsFilter extends TermsFilter {
  public constructor(id: string, type: FilterType) {
    super(id, type);
    if (!id.endsWith('.ex')) {
      this.id = `${id}.ex`;
    }
  }

  public init(data: any): void {
    this.operator = data.operator ? data.operator : Operators.AND;
    this.terms = data.excludedTerms ? [...data.excludedTerms] : [];
  }

  protected get paramName(): string {
    if (!this.id.endsWith('.ex')) {
      this.id = `${this.id}.ex`;
    }
    return `f.${this.id}`;
  }
}
