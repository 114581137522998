import { numberToFloat } from '../../../../utils/numberToFloat';
import { UrlQueryBuilder } from '../request';
import { AbstractFilter, Filter } from './AbstractFilter';
import {
  DisplayFilter,
  DisplayFilterGeoRelation,
  DisplayFilterType,
} from './DisplayFilter';

export interface GeoRelationFilterValue {
  entityId: string;
  coordinates: string;
  relationCode?: string;
}

export class GeoRelationFilter extends AbstractFilter<GeoRelationFilterValue> {
  protected _entityId: string = '';
  protected _coordinates: string = '';
  protected _relationCode?: string;

  public init(data: any): void {
    this._entityId = data.entityId ?? '';
    this._coordinates = data.coordinates ?? '';
    this._relationCode = data.relationCode;
  }

  get values() {
    return {
      entityId: this._entityId,
      coordinates: this._coordinates,
      relationCode: this._relationCode,
    };
  }

  get filterValue(): string {
    return GeoRelationFilter.buildFilterValue(
      this._entityId,
      this._coordinates,
      this._relationCode,
    );
  }

  set entityId(value) {
    this._entityId = value;
  }

  set coordinates(value) {
    this._coordinates = value;
  }

  set relationCode(value) {
    this._relationCode = value;
  }

  public static buildFilterValue(
    entityId: string,
    coordinates: string,
    relationCode?: string,
  ): string {
    const filterData: any = {
      entity: entityId,
      coord: coordinates,
    };
    if (relationCode) {
      filterData.code = relationCode;
    }
    return Object.keys(filterData)
      .map(key => `f.geo_relation.${key}=${filterData[key]}`)
      .join('&');
  }

  public addToUrlSearchParams(builder: UrlQueryBuilder): void {
    if (this.isEmpty()) {
      return;
    }

    builder.add(
      this.getParamName('entity'),
      encodeURIComponent(this._entityId),
    );
    builder.add(
      this.getParamName('coord'),
      encodeURIComponent(this._coordinates),
    );
    if (!!this._relationCode) {
      builder.add(
        this.getParamName('code'),
        encodeURIComponent(this._relationCode),
      );
    }
  }

  public parseUrlSearchParams(params: URLSearchParams): void {
    this.entityId = params.get(this.getParamName('entity')) || '';
    this.coordinates = params.get(this.getParamName('coord')) || '';
    this.relationCode = params.get(this.getParamName('code')) || '';
  }

  public isEmpty(): boolean {
    return !this._entityId.length || !this._coordinates.length;
  }

  public setValue(value: GeoRelationFilterValue): void {
    this._entityId = value.entityId;
    this._coordinates = value.coordinates;
    this._relationCode = value.relationCode;
  }

  public removeValue(value: GeoRelationFilterValue): Filter {
    return this.resetValues();
  }

  public resetValues(): Filter {
    this._entityId = '';
    this._coordinates = '';
    this._relationCode = undefined;
    return this;
  }

  public addToSelectedFilterTerms(list: DisplayFilter[]): void {
    const filter: DisplayFilterGeoRelation = {
      filter: this,
      type: DisplayFilterType.geoRelation,
      entityId: this._entityId,
      relationCode: this._relationCode ?? '',
      coordinates: this._coordinates,
    };
    list.push(filter);
  }

  public isValueSelected(value: GeoRelationFilterValue): boolean {
    return this.entityId.includes(value);
  }

  public getSelectedFilterTerms(): string[] {
    return [this.entityId];
  }

  // public toSelectedTerm(selectedTerm: string): string {
  //   return this.entityId;
  // }

  // public valuesMatch(filter: Filter): boolean {
  //   if (!filter || !(filter instanceof GeoRelationFilter)) return false;
  //   const f: GeoRelationFilter = filter;
  //   return (
  //     this._entityId === f.values.entityId &&
  //     this._coordinates === f.values.coordinates &&
  //     this._relationCode === f.values.relationCode
  //   );
  // }

  public clone(): Filter {
    const filter = new GeoRelationFilter(this.id, this.type);
    filter.entityId = this._entityId;
    filter.coordinates = this._coordinates;
    filter.relationCode = this._relationCode;
    return filter;
  }

  protected getParamName(part: 'entity' | 'coord' | 'code'): string {
    return `f.${this.id}.${part}`;
  }

  public createFilterValueFromOLFeature(
    feature,
    relationCode?: string,
  ): GeoRelationFilterValue {
    const entityId = feature.get('id');
    const coordinates = feature.getGeometry().getCoordinates();
    return {
      entityId,
      relationCode,
      coordinates: `[${numberToFloat(coordinates[1])},${numberToFloat(
        coordinates[0],
      )}]`,
    };
  }
}
