import { DEFAULT_PAGE_SIZE } from '../../../../features/Config';
import { QueryConfigurationTypes } from '../../../search/query';
import { SearchRequestQuery } from './SearchRequestQuery';
import { SearchRequestSort } from './SearchRequestSort';
import { SearchRequestFacetMap } from './SearchRequestFacetMap';
import { SearchRequestFilterMap } from './SearchRequestFilterMap';

export interface SearchResponseRequest {
  readonly query: SearchRequestQuery;
  readonly start: number;
  readonly rows: number;
  readonly sort: SearchRequestSort;
  readonly facets: SearchRequestFacetMap;
  readonly filters: SearchRequestFilterMap;
}

export const initialSearchRequestJson: SearchResponseRequest = {
  query: {
    id: 'default',
    type: QueryConfigurationTypes.edismax,
    term: '',
    empty: true,
  },
  start: 0,
  rows: DEFAULT_PAGE_SIZE,
  sort: {
    id: 'score',
    title: 'Relevanz',
  },
  facets: {},
  filters: {},
};
