import { call } from 'redux-saga/effects';
import { request } from '../../utils/request';
import { HIERARCHY_GROUP_SIZE } from '../features/Config';
import { getHierarchyRequestUrl } from './getRequestUrl';
import { ERRORS } from './constants';

export function* fetchHierarchy(id: string) {
  const pathname = yield call(getHierarchyRequestUrl, id);

  const search: string[] = [];
  search.push(`group=${HIERARCHY_GROUP_SIZE}`);

  const url = `${pathname}?${search.join('&')}`;
  const response = yield call(request, url);
  if (!response) {
    throw new Error(ERRORS.NO_DATA);
  }
  if (response.error) {
    throw response.error;
  }
  return response;
}
