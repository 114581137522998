import { put } from 'redux-saga/effects';
import { ErrorPayloadGenerator } from './ErrorPayloadGenerator';
import { RequestErrorPayload } from './types';

export function* handleRequestError(
  action,
  error: any,
  remainingPayload: any = {},
) {
  const payloadGenerator: ErrorPayloadGenerator = new ErrorPayloadGenerator();
  const payload: RequestErrorPayload = payloadGenerator.getPayload(error);
  yield put(action({ error: payload, ...remainingPayload }));
}
