import { SearchResponseFacetBucket } from '../../api/responses';
import { AbstractBucket } from './AbstractBucket';
import { Bucket } from './Bucket';

export class BucketFactory {
  public static create(json: SearchResponseFacetBucket): Bucket {
    return AbstractBucket.create(Bucket, json.val, json.count);
  }

  public static createMissing(count?: number): Bucket | null {
    if (count === undefined) {
      return null;
    }
    return AbstractBucket.create(Bucket, 'none', count);
  }
}
