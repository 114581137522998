import { FacetType } from '../../../search/facets';

export type ConfigurationResponseFacetMap = Record<
  string,
  ConfigurationResponseFacet
>;

export interface ConfigurationResponseFacet {
  collapsed: boolean;
  defaultEnabled: boolean;
  filterId: string;
  id: string;
  // method: string;
  index: number;
  title: string;
  type: FacetType;
}

export const emptyFacetConfigurationJson: ConfigurationResponseFacet = {
  id: '',
  defaultEnabled: false,
  title: '',
  type: 'undefined',
  filterId: '',
  index: -1,
  collapsed: false,
};

export interface ConfigurationResponseTermsFacet
  extends ConfigurationResponseFacet {
  limit: number;
  missing: boolean;
  showEmptyBuckets: boolean;
  sort: string;
  namespace?: string;
}
