import { Direction, Directions } from '../../d3js/types';
import { UrlQueryBuilder } from '../request';
import { AbstractFilter, Filter } from './AbstractFilter';
import {
  DisplayFilter,
  DisplayFilterRelationJoin,
  DisplayFilterType,
} from './DisplayFilter';

export interface RelationJoinFilterValue {
  entityId: string;
  relationCode: string;
  satzart?: string;
  direction: Direction;
}

export class RelationJoinFilter extends AbstractFilter<RelationJoinFilterValue> {
  protected _entityId: string = '';
  protected _relationCode: string = '';
  protected _satzart: string | null = null;
  protected _direction: string = Directions.forward;

  public init(data: any): void {
    this.entityId = data.entityId || '';
    this.relationCode = data.relationCode || '';
    this.satzart = data.satzart || null;
    this.direction = data.direction || '';
  }

  get entityId(): string {
    return this._entityId;
  }

  set entityId(value: string) {
    this._entityId = value;
  }

  get relationCode(): string {
    return this._relationCode;
  }

  set relationCode(value: string) {
    this._relationCode = value;
  }

  get satzart(): string | null {
    return this._satzart;
  }

  set satzart(value: string | null) {
    this._satzart = value;
  }

  get direction(): string {
    return this._direction;
  }

  set direction(value: string) {
    this._direction = value;
  }

  public static buildFilterValue(
    entityId: string,
    relationCode: string,
    satzart: string | null,
    direction: string,
  ): string {
    // f.relation.entity=4029050-5&f.relation.code=berc&f.relation.satzart=Person&f.relation.dir=BACKWARD
    const filterData: any = {
      entity: entityId,
      code: relationCode,
      satzart,
      dir: direction,
    };
    return Object.keys(filterData)
      .filter(key => filterData[key] !== null)
      .map(key => `f.relation.${key}=${filterData[key]}`)
      .join('&');
  }

  public parseUrlSearchParams(params: URLSearchParams): void {
    this.entityId = params.get(this.getParamName('entity')) || '';
    this.relationCode = params.get(this.getParamName('code')) || '';
    this.satzart = params.get(this.getParamName('satzart')) || null;
    this.direction = params.get(this.getParamName('dir')) || '';
  }

  public addToUrlSearchParams(builder: UrlQueryBuilder): void {
    if (!this.isEmpty()) {
      builder.add(
        this.getParamName('entity'),
        encodeURIComponent(this.entityId),
      );
      builder.add(
        this.getParamName('code'),
        encodeURIComponent(this.relationCode),
      );
      if (this.satzart !== null) {
        builder.add(
          this.getParamName('satzart'),
          encodeURIComponent(this.satzart),
        );
      }
      builder.add(this.getParamName('dir'), encodeURIComponent(this.direction));
    }
  }

  public isEmpty(): boolean {
    return (
      !this.entityId.length ||
      !this.relationCode.length ||
      !this.direction.length
    );
  }

  public setValue(value: RelationJoinFilterValue): void {
    this.entityId = value.entityId;
    this.relationCode = value.relationCode;
    this.satzart = value.satzart || null;
    this.direction = value.direction.toUpperCase();
  }

  public removeValue(term: RelationJoinFilterValue): Filter {
    return this.resetValues();
  }

  public resetValues(): Filter {
    this._entityId = '';
    this._relationCode = '';
    this._satzart = null;
    this._direction = '';
    return this;
  }

  public addToSelectedFilterTerms(list: DisplayFilter[]): void {
    const filter: DisplayFilterRelationJoin = {
      filter: this,
      type: DisplayFilterType.relationJoin,
      entityId: this.entityId,
      relationCode: this.relationCode,
      satzart: this.satzart || undefined,
      isForward: this.direction === 'FORWARD',
    };
    list.push(filter);
  }

  public isValueSelected(value: RelationJoinFilterValue): boolean {
    return this.entityId.includes(value.entityId);
  }

  public getSelectedFilterTerms(): string[] {
    return [this.entityId];
  }

  // public toSelectedTerm(selectedTerm: string): string {
  //   return this.entityId;
  // }

  // public valuesMatch(filter: Filter): boolean {
  //   if (!filter || !(filter instanceof RelationJoinFilter)) return false;
  //   const f: RelationJoinFilter = filter;
  //   return (
  //     this.entityId === f.entityId &&
  //     this.relationCode === f.relationCode &&
  //     this.satzart === f.satzart &&
  //     this.direction === f.direction
  //   );
  // }

  public clone(): Filter {
    const filter = new RelationJoinFilter(this.id, this.type);
    filter.entityId = this.entityId;
    filter.relationCode = this.relationCode;
    filter.satzart = this.satzart;
    filter.direction = this.direction;
    return filter;
  }

  protected getParamName(part: 'entity' | 'code' | 'satzart' | 'dir'): string {
    return `f.${this.id}.${part}`;
  }
}
