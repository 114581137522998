import React, { ForwardedRef } from 'react';
import { AutoSuggestItem } from '../../model/autosuggest';
import { AutoSuggestListItem } from './AutosuggestItem';
import { useAutoSuggestHandlers } from './handlers';
import { useAutoSuggestState } from './state';
import { AutoSuggestList } from './style';

interface AutoSuggestProps {
  isFocused: boolean;
  onBlur: () => void;
  onFocus: () => void;
  onSuggestionSelected(): void;
  term: string;
}

export const AutoSuggest = React.forwardRef<HTMLOListElement, AutoSuggestProps>(
  (props: AutoSuggestProps, ref: ForwardedRef<HTMLOListElement>) => {
    const state = useAutoSuggestState();
    const handlers = useAutoSuggestHandlers(state, props.onSuggestionSelected);

    return (
      <AutoSuggestList
        tabIndex={0}
        onMouseEnter={props.onFocus}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        onMouseLeave={props.onBlur}
        onKeyUp={handlers.onKeyUp}
        onKeyDown={handlers.onKeyDown}
        ref={ref}
      >
        {state.suggestions.map((suggestion: AutoSuggestItem, index: number) => {
          const isItemSelected = props.isFocused && state.selected === index;
          return (
            <AutoSuggestListItem
              key={suggestion.entityId ?? suggestion.term}
              suggestion={suggestion}
              selected={isItemSelected}
              setThisSelected={handlers.setIndexSelected(index)}
              onClick={handlers.onSuggestionClick(suggestion)}
            />
          );
        })}
      </AutoSuggestList>
    );
  },
);
