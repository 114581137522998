import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectRequestTerm } from '../../features/Search';
import { useQueryParam } from '../../hooks/useQuery';

export interface SearchBoxState {
  term: string;
  setTerm: (term: string) => void;
  focus: boolean;
  setInputFocus: (focus: boolean) => void;
  setListFocus: (focus: boolean) => void;
  showSuggestions: boolean;
  setShowSuggestions(show: boolean): void;
}

export function useSearchBoxState() {
  const termInSearchRequest: string | undefined =
    useSelector(selectRequestTerm);
  const termInUrlParam = useQueryParam('term');
  const [term, setTerm] = useState(termInUrlParam ?? '');
  const [inputFocus, setInputFocus] = useState(false);
  const [listFocus, setListFocus] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const focus: boolean = inputFocus || listFocus;

  // initialize term with the current state
  useEffect(() => {
    if (termInSearchRequest !== undefined) {
      setTerm(termInSearchRequest);
    }
  }, [termInSearchRequest]);

  useEffect(() => {
    if (showSuggestions && !inputFocus && !listFocus) {
      setShowSuggestions(false);
    }
  }, [inputFocus, listFocus, showSuggestions]);

  return {
    term,
    setTerm,
    focus,
    setInputFocus,
    listFocus,
    setListFocus,
    showSuggestions,
    setShowSuggestions,
  };
}
