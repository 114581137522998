import { ConfigurationResponseSort } from '../../api/responses';

export class Sort {
  protected _id: string;

  protected constructor(id: string) {
    this._id = id;
  }

  public static create(json: ConfigurationResponseSort): Sort {
    const id: string = json.id ? json.id : 'score';
    return new Sort(id);
  }

  get id(): string {
    return this._id;
  }

  set id(id: string) {
    this._id = id;
  }
}
