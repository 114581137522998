import {
  ConfigurationResponseFilter,
  ConfigurationResponseFilterMap,
  SearchRequestFilterMap,
  SearchRequestTermsFilter,
} from '../../api/responses';
import { UrlQueryBuilder } from '../request';
import { Filter } from './AbstractFilter';
import { DisplayFilter } from './DisplayFilter';
import { FilterFactory } from './FilterFactory';

export class FilterList {
  protected _filters: Filter[] = [];
  protected _configurations: ConfigurationResponseFilterMap = {};

  get filters(): Filter[] {
    return this._filters;
  }

  set filters(filters: Filter[]) {
    this._filters = filters;
  }

  get configurations(): ConfigurationResponseFilterMap {
    return this._configurations;
  }

  set configurations(configurations: ConfigurationResponseFilterMap) {
    this._configurations = configurations;
  }

  public isEmpty(): boolean {
    return this._filters.length === 0;
  }

  public hasFilterTerms(): boolean {
    for (const filter of this._filters) {
      if (!filter.isEmpty()) {
        return true;
      }
    }
    return false;
  }

  public getSelectedFilterTerms(): DisplayFilter[] {
    const selectedFilterTerms: DisplayFilter[] = [];
    for (const filter of this.filters) {
      filter.addToSelectedFilterTerms(selectedFilterTerms);
    }
    return selectedFilterTerms;
  }

  public hasSelectedFilterTerms(): boolean {
    return this.getSelectedFilterTerms().length > 0;
  }

  public getFilter(filter: Filter): Filter {
    for (const f of this._filters) {
      if (f.id === filter.id) {
        return f;
      }
    }
    const newFilter: Filter = FilterFactory.createInstanceFromId(
      filter.id,
      this.configurations[filter.id],
    );
    this._filters.push(newFilter);
    return newFilter;
  }

  public getFilterById(filterId: string): Filter {
    for (const f of this._filters) {
      if (f.id === filterId) {
        return f;
      }
    }
    const newFilter: Filter = FilterFactory.createInstanceFromId(
      filterId,
      this.configurations[filterId],
    );
    return newFilter;
  }

  public addFilterValue(filter: Filter, value: any): FilterList {
    const f: Filter = this.getFilter(filter);
    f.setValue(value);
    return this;
  }

  public addExclusiveFilter(
    filter: Filter,
    excludeFilterIds: string[],
    filterValue: any,
  ) {
    excludeFilterIds.forEach((filterId: string) => {
      this.resetFilterById(filterId);
    });
    this.addFilterValue(filter, filterValue);
  }

  public removeFilterValue(filter: Filter, value: string): FilterList {
    const f: Filter = this.getFilter(filter);
    f.removeValue(value);
    return this;
  }

  public resetFilters(): FilterList {
    this.filters = [];
    return this;
  }

  public resetFilterById(id: string): FilterList {
    if (this.isFilterNameSelected(id)) {
      const filter = this.getFilterById(id);
      filter.resetValues();
    }
    return this;
  }

  public isFilterNameSelected(id: string): boolean {
    return this._filters.some(f => f.id === id);
  }

  public isValueSelected(id: string, value: string): boolean {
    if (!this.isFilterNameSelected(id)) return false;
    const f: Filter = this.getFilterById(id);
    return f.isValueSelected(value);
  }

  public parseUrlSearchParams(params: URLSearchParams): void {
    const filterIds = Object.keys(this.configurations);
    filterIds.forEach((filterId: string) => {
      if (this.isFilterNameInUrl(params, filterId)) {
        const filter: Filter = FilterFactory.createInstanceFromId(
          filterId,
          this.configurations[filterId],
        );
        filter.parseUrlSearchParams(params);
        this.filters.push(filter);
      }
    });
  }

  public addToUrlSearchParams(builder: UrlQueryBuilder): void {
    this.filters.forEach((filter: Filter) => {
      filter.addToUrlSearchParams(builder);
    });
  }

  protected isFilterNameInUrl(
    params: URLSearchParams,
    filterId: string,
  ): boolean {
    if (filterId === 'relation') {
      return (
        params.has(`f.${filterId}.entity`) &&
        params.has(`f.${filterId}.code`) &&
        params.has(`f.${filterId}.dir`)
      );
    }

    if (filterId === 'geo_relation') {
      return (
        params.has(`f.${filterId}.entity`) && params.has(`f.${filterId}.coord`)
      );
    }

    if (filterId.startsWith('yearRange_')) {
      return (
        params.has(`f.${filterId}.start`) || params.has(`f.${filterId}.end`)
      );
    }

    return params.has(`f.${filterId}`);
  }

  public clone(): FilterList {
    const filterList = new FilterList();
    filterList.filters = this.filters.map((f: Filter) => f.clone());
    filterList.configurations = { ...this._configurations };
    return filterList;
  }

  public addFiltersFromResponseRequest(json: SearchRequestFilterMap): void {
    Object.keys(json).forEach((fieldId: string) => {
      const filter = json[fieldId];

      if (filter.hasOwnProperty('excludedTerms')) {
        const termsAndExcludeTermsFilters =
          FilterFactory.createTermsAndExcludeTermsFiltersFrom(
            filter as SearchRequestTermsFilter,
            this._configurations,
          );
        termsAndExcludeTermsFilters.forEach((f: Filter) =>
          this.filters.push(f),
        );
      } else {
        const filterConfig: ConfigurationResponseFilter =
          this._configurations[fieldId];
        this.filters.push(
          FilterFactory.createInstanceFrom(filter, filterConfig),
        );
      }
    });
  }
}
