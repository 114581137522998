import { Simulation, SimulationLinkDatum, SimulationNodeDatum } from 'd3-force';

export type D3Simulation = Simulation<GraphNode, GraphLink | undefined>;

export interface GraphNode extends SimulationNodeDatum {
  id: string;
  data: NodeData;
  relations?: NodeData[];
}

export interface GraphLink extends SimulationLinkDatum<GraphNode> {
  id: string;
  data: RelationData;
  source: GraphNode;
  target: GraphNode;
}

export enum NodeTypes {
  child = 'child',
  relation = 'relation',
  root = 'root',
  set = 'set',
}
export type NodeType = keyof typeof NodeTypes;

export enum Directions {
  backward = 'backward',
  forward = 'forward',
}
export type Direction = keyof typeof Directions;

export interface RelationData {
  relation: string;
  from: string;
  to: string;
  direction: Direction;
  origin: string;
}

export type NodeData = DefaultNodeData | SetNodeData | RelationNodeData;

export interface DefaultNodeData {
  id: string;
  type: NodeTypes.root | NodeTypes.child;
  name: string;
  nameComplement?: string;
  satzart: string;
  totalInOutRelations: number;
  icon: string;
}

export interface SetNodeData {
  id: string;
  type: NodeTypes.set;
  satzart: string;
  total: number;
  icon: string;
  relationCode: string;
}

export interface RelationNodeData {
  id: string;
  type: NodeTypes.relation;
  relations: RelationData[];
  relation: string;
  direction: Direction;
  parent: string;
}
