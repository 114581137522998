import { call, select } from 'redux-saga/effects';
import { request } from '../../utils/request';
import { selectCurrentSearchContext } from '../features/Search';
import { SearchConfigurationResponse } from '../model/api/responses';
import { SearchContext } from '../model/search/context';
import { getConfigRequestUrl } from './getRequestUrl';

export function* fetchSearchConfiguration() {
  const searchContext: SearchContext = yield select(selectCurrentSearchContext);
  const url = yield call(getConfigRequestUrl, searchContext);
  const response: SearchConfigurationResponse = yield call(request, url);
  return response;
}
