import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { KeyboardKeyCodes } from '../../../utils/KeyboardKeyCodes';
import { EntityViews } from '../../features/GeneralUI';
import { actions } from '../../features/Search';
import { AutoSuggestGroups, AutoSuggestItem } from '../../model/autosuggest';
import { PermalinkBuilder } from '../../routes';
import { AutoSuggestState } from './state';

export function useAutoSuggestHandlers(
  state: AutoSuggestState,
  onSuggestionSelected: () => void,
) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onKeyUp = event => {
    if (event.which === KeyboardKeyCodes.ARROW_DOWN) {
      state.setSelected(getNext(state.selected, state.suggestions));
    }
    if (event.which === KeyboardKeyCodes.ARROW_UP) {
      state.setSelected(getPrevious(state.selected, state.suggestions));
    }
    if (event.which === KeyboardKeyCodes.ENTER) {
      const suggestion = state.suggestions[state.selected];
      resolveSuggestionSelection(suggestion);
    }
  };

  const onKeyDown = event => {
    if (event.which === KeyboardKeyCodes.ARROW_DOWN) {
      event.preventDefault();
    }
    if (event.which === KeyboardKeyCodes.ARROW_UP) {
      event.preventDefault();
    }
  };

  const onSuggestionClick = (suggestion: AutoSuggestItem) => () => {
    resolveSuggestionSelection(suggestion);
  };

  const setIndexSelected = (index: number) => () => {
    state.setSelected(index);
  };

  function navigateToSuggestion(suggestion: AutoSuggestItem) {
    const link = buildLink(suggestion, i18n);
    onSuggestionSelected();
    navigate(link);
  }

  function searchSuggestionTerm(suggestion: AutoSuggestItem) {
    dispatch(
      actions.searchTerm({
        lang: i18n.language,
        term: suggestion.term.trim(),
        resetFilters: true,
      }),
    );
    onSuggestionSelected();
  }

  function resolveSuggestionSelection(suggestion: AutoSuggestItem) {
    switch (suggestion.group) {
      case AutoSuggestGroups.entity_text:
      case AutoSuggestGroups.entity_title:
        navigateToSuggestion(suggestion);
        break;
      case AutoSuggestGroups.term:
        searchSuggestionTerm(suggestion);
    }
  }

  return {
    onKeyDown,
    onKeyUp,
    onSuggestionClick,
    setIndexSelected,
  };
}

// Todo add handlers for different suggestion groups
function buildLink(suggestion: AutoSuggestItem, i18n: any): string {
  const link = PermalinkBuilder.entity({
    langCode: i18n.language,
    id: suggestion.entityId ?? '',
    search: '',
    view: EntityViews.factsheet,
  });
  return link;
}

function getNext(currentIndex: number, suggestions: any[]) {
  if (currentIndex === suggestions.length - 1) {
    return 0;
  }
  return currentIndex + 1;
}

function getPrevious(currentIndex: number, suggestions: any[]) {
  if (currentIndex === 0) {
    return suggestions.length - 1;
  }
  return currentIndex - 1;
}
