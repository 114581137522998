import styled from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';
import { media } from '../../../styles';

export const Link = styled(ReactRouterLink)`
  grid-area: logo;
  justify-self: start;

  margin-top: ${props => props.theme.spacing._20}rem;
  ${media.tablet_portrait_up`
    margin-top: 0;
    margin-right: ${props => props.theme.spacing._32}rem;
  `};
`;
