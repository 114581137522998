import { call } from 'redux-saga/effects';
import { request } from '../../utils/request';
import { SearchResponse } from '../model/api/responses';
import { Context } from '../model/search/context';
import { getFacetRequestUrl } from './getRequestUrl';

export function* fetchFacet(id: string, queryParams: string) {
  const baseUrl: string = yield call(getFacetRequestUrl, Context.default, id);

  const glue: string = queryParams.startsWith('?') ? '' : '?';
  const url: string = [baseUrl, queryParams].join(glue);

  const response: SearchResponse = yield call(request, url);

  if (!response) {
    throw new Error('No data');
  }

  return response;
}
