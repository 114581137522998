import { SearchResponseDocument } from '../api/responses';
import { Entity } from './Entity';

export class SearchResult {
  protected _id: string;
  protected _entity: Entity;

  public constructor(json: SearchResponseDocument) {
    this._id = json.id;
    this._entity = new Entity({
      id: json.id,
      record: JSON.parse(json.json) as SearchResponseDocument,
    });
  }

  get id(): string {
    return this._id;
  }

  get entity(): Entity {
    return this._entity;
  }
}
