import {
  ConfigurationResponseFacetMap,
  SearchRequestFacet,
  SearchRequestFacetMap,
} from '../../api/responses';
import { RequestFacetList, RequestFacetFactory } from '../facets';

export class RequestFacetListFactory {
  public static createEmpty(): RequestFacetList {
    return new RequestFacetList();
  }

  public static createFromResponseRequest(
    json: SearchRequestFacetMap,
    config: ConfigurationResponseFacetMap,
  ): RequestFacetList {
    const facetList: RequestFacetList = new RequestFacetList();
    facetList.configurations = config;

    Object.keys(json).forEach((facetId: string) => {
      const facetJson: SearchRequestFacet = json[facetId];
      const facetConfig = config[facetId];

      const requestFacet = RequestFacetFactory.createInstanceFrom(
        facetConfig,
        facetJson,
      );
      facetList.addFacet(requestFacet);
      // if (facetJson.offset > facetConfig.limit) {
      // }
    });

    return facetList;
  }
}
