/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { GlobalStyle, defaultTheme } from '../styles';
import { routes as myRoutes } from './routes';

function App() {
  const { t, i18n } = useTranslation('helmet');
  const element = useRoutes(myRoutes);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Helmet
        defaultTitle={t('helmet:helmet.title.default')}
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta
          name="description"
          content={t('helmet:helmet.description.default')}
        />
      </Helmet>
      {element}
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
