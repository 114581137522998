import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit'; // importing from `utils` is more type-safe
import { SearchConfigurationResponse } from '../../model/api/responses';
import {
  initialSearchConfigurations,
  initialSearchConfigurationLoaded,
} from '../../model/search/configuration';
import { SearchContext } from '../../model/search/context';
import { SearchConfigurationState } from './types';

export const initialState: SearchConfigurationState = {
  searchConfigurations: initialSearchConfigurations,
  isSearchConfigurationLoaded: initialSearchConfigurationLoaded,
};

// The Immer library used by createSlice and createReducer will return an immutable state,
// so we can write code that "mutates" the state inside our reducer
const searchConfigurationSlice = createSlice({
  name: 'searchConfiguration',
  initialState,
  reducers: {
    searchConfigurationLoadedSuccess(
      state,
      action: PayloadAction<{
        response: SearchConfigurationResponse;
        searchContext: SearchContext;
      }>,
    ) {
      state.isSearchConfigurationLoaded[action.payload.searchContext] = true;
      state.searchConfigurations[action.payload.searchContext] =
        action.payload.response;
    },
  },
});

// Exports
export const { name: sliceKey, actions } = searchConfigurationSlice;
export default searchConfigurationSlice.reducer;
