import i18next, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { localResources } from './resources';

export const DEFAULT_LANGUAGE = 'de';
export const SUPPORTED_LANGUAGES: string[] = [DEFAULT_LANGUAGE, 'en', 'uk'];

const customLocalBackend = {
  type: 'backend',
  init: function (services, backendOptions, i18nextOptions) {
    /* use services and options */
  },
  read: function (language, namespace, callback) {
    callback(null, localResources[language][namespace]);
  },
};

// declare custom type options so the return is always a string.
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // load translation files dynamically
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: DEFAULT_LANGUAGE, // overrides language detection
    fallbackLng: DEFAULT_LANGUAGE,
    supportedLngs: SUPPORTED_LANGUAGES,
    load: 'languageOnly',
    debug:
      process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    backend: {
      backends: [
        HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the customLocalBackend will try to return the correct resources
        customLocalBackend,
      ],
      backendOptions: [
        {
          loadPath: '/locales/{{lng}}/{{ns}}.json',
        },
        {},
      ],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function (value, format, lng) {
        if (format === 'intlDate') {
          return new Intl.DateTimeFormat(lng, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }).format(value);
        }
        return value;
      },
    },
    react: {
      useSuspense: false,
    },
    nsSeparator: ':',
    keySeparator: '.', // use false for a flat json; default value is '.'
    returnNull: false,
  } as InitOptions);
