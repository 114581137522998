import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import TimelineConfig from '../../../conf/timeline';
import { RequestStatus } from '../../model/api/request';
import { TimelineNames, TimelineState } from './types';

export const initialState: TimelineState = {
  view: TimelineConfig.relationAll,
  minMaxRequestStatus: RequestStatus.Unprocessed,
  minYear: -9999,
  maxYear: 9999,
  sliders: [TimelineConfig.relationAll, ...TimelineConfig.relations].reduce(
    (acc: any, relation: string) => {
      acc[relation] = {
        [TimelineNames.start]: -9999,
        [TimelineNames.end]: 9999,
      };
      return acc;
    },
    {},
  ),
  searchButtonEnabled: false,
};

const key = 'timeline';
const timelineSlice = createSlice({
  name: key,
  initialState,
  reducers: {
    changeView(state, action: PayloadAction<{ relation: string }>) {
      state.view = action.payload.relation;
    },
    fetchMinMaxYears(state) {
      state.minMaxRequestStatus = RequestStatus.InProgress;
    },
    fetchMinMaxYearsSuccess(
      state,
      action: PayloadAction<{ minYear: number; maxYear: number }>,
    ) {
      state.minMaxRequestStatus = RequestStatus.Complete;
      state.minYear = action.payload.minYear;
      state.maxYear = action.payload.maxYear;
      Object.keys(state.sliders).forEach((relation: string) => {
        state.sliders[relation][TimelineNames.start] = Math.max(
          action.payload.minYear,
          state.sliders[relation][TimelineNames.start],
        );
        state.sliders[relation][TimelineNames.end] = Math.min(
          action.payload.maxYear,
          state.sliders[relation][TimelineNames.end],
        );
      });
    },
    fetchMinMaxYearsError(state) {
      state.minMaxRequestStatus = RequestStatus.Error;
    },
    resetSlider(state, action: PayloadAction<{ lang: string }>) {
      state.sliders[state.view][TimelineNames.start] = state.minYear;
      state.sliders[state.view][TimelineNames.end] = state.maxYear;
      state.searchButtonEnabled = false;
    },
    setSliderValues(
      state,
      action: PayloadAction<{
        start: number;
        end: number;
      }>,
    ) {
      state.sliders[state.view][TimelineNames.start] = action.payload.start;
      state.sliders[state.view][TimelineNames.end] = action.payload.end;
    },
    enableSearchButton(state) {
      state.searchButtonEnabled = true;
    },
    disableSearchButton(state) {
      state.searchButtonEnabled = false;
    },
    filterRange(
      state,
      action: PayloadAction<{ start: number; end: number; lang: string }>,
    ) {
      state.sliders[state.view][TimelineNames.start] = action.payload.start;
      state.sliders[state.view][TimelineNames.end] = action.payload.end;
      state.searchButtonEnabled = false;
    },
  },
});

export const { name: sliceKey, actions } = timelineSlice;
export default timelineSlice.reducer;
