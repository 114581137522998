import {
  emptyTermsFilter,
  ConfigurationResponseFacetMap,
  SearchRequestFacetMap,
  SearchResponseFacet,
  ConfigurationResponseFilterMap,
  SearchRequestFilterBase,
  SearchRequestFilterMap,
} from '../../api/responses';
import { Facet } from './Facet';
import { FacetFactory } from './FacetFactory';

interface FacetCreatorData {
  responseFacets: SearchResponseFacet;
  responseRequestFacets: SearchRequestFacetMap;
  facetConfigurations: ConfigurationResponseFacetMap;
  requestFilters: SearchRequestFilterMap;
  filterConfigurations: ConfigurationResponseFilterMap;
}
export function responseFacetsProcessor(data: FacetCreatorData): Facet[] {
  const facets: Facet[] = [];
  Object.keys(data.responseFacets).forEach((fieldId: string) => {
    const facetConfiguration = data.facetConfigurations[fieldId];
    if (!facetConfiguration) {
      return;
    }
    const filter: SearchRequestFilterBase =
      data.requestFilters[fieldId] || emptyTermsFilter;
    facets.push(
      FacetFactory.createInstanceFrom(
        data.responseFacets[fieldId],
        data.responseRequestFacets[fieldId],
        facetConfiguration,
        filter,
        data.filterConfigurations,
      ),
    );

    // sort facets by index
    facets.sort((f1, f2) =>
      f1.configuration.index < f2.configuration.index
        ? -1
        : f1.configuration.index > f2.configuration.index
        ? 1
        : 0,
    );
  });
  return facets;
}
