// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sections: [
    { label1: 'geistige_schoepfer' },
    { label1: 'andere_namen_titel_synonyme' },
    { label1: 'beschreibende_angaben' },
    { label1: 'geografischer_bezug' },
    { label1: 'gnd_systematik' },
  ],
  content: {
    geistige_schoepfer: [
      { relations: 'aut1|kom1|kue1' },
      { relations: 'auta|koma|kuen' },
      { relations: 'arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd' },
    ],
    andere_namen_titel_synonyme: [{ subfield: '-4' }, { relations: '*' }],
    beschreibende_angaben: [
      { relations: 'datb|datf|datj|datl|dats|datv' },
      { relations: 'berc' },
      { relations: 'beru' },
      { label2: 'sprachencodes' },
    ],
  },
  fields: {
    '022@': {
      label1: 'andere_namen_titel_synonyme',
      content: [
        { ns: 'label2', label2: 'andere_titel', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'a|t|g|m|n|p|o|s|x|f|r|l|h' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '028@': {
      label1: 'andere_namen_titel_synonyme',
      content: [
        { ns: 'label2', label2: 'andere_namen', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'd' },
        { type: 'text', subfield: 'c' },
        { type: 'text', subfield: 'a|P' },
        { type: 'text', subfield: 'n|l|g|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '028R': {
      label1: 'geistige_schoepfer',
      content: {
        ns: 'relationCodes',
        relations:
          'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|P|a|d|c|n|l|g|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
    },
    '029@': {
      label1: 'andere_namen_titel_synonyme',
      content: [
        { ns: 'label2', label2: 'andere_namen', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'a|n|g|b|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '029R': {
      label1: 'geistige_schoepfer',
      content: {
        ns: 'relationCodes',
        relations:
          'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|a|n|g|b|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
    },
    '030@': {
      label1: 'andere_namen_titel_synonyme',
      content: [
        { ns: 'label2', label2: 'andere_namen', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'a|n|d|c|g|b|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '030R': {
      label1: 'geistige_schoepfer',
      content: {
        ns: 'relationCodes',
        relations:
          'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|a|n|d|c|g|b|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
    },
    '041@': {
      label1: 'andere_namen_titel_synonyme',
      content: [
        { ns: 'label2', label2: 'synonyme_infopanel', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [{ type: 'text', subfield: 'a|g|x' }],
      collapseMultipleEntries: true,
    },
    '041R': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'relationCodes',
        relations: 'berc|beru',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|a|g|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '042A': {
      label1: 'gnd_systematik',
      content: {
        ns: 'label2',
        label2: 'gnd_systematik',
      },
      render: [
        {
          type: 'text',
          subfield: 'a',
          tooltips: [{ subfield: 'a', ns: 'gndSystematic' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '042B': {
      label1: 'geografischer_bezug',
      content: {
        ns: 'label2',
        label2: 'laender',
      },
      render: [
        { type: 'text', subfield: 'a' },
        { type: 'additional', subfield: 'a', ns: 'countryCodes' },
      ],
    },
    '042C': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'label2',
        label2: 'sprachencodes',
      },
      render: [
        { type: 'text', subfield: 'a' },
        { type: 'additional', subfield: 'a', ns: 'languageCodes' },
      ],
    },
    '060R': {
      label1: 'beschreibende_angaben',
      content: {
        ns: 'relationCodes',
        relations: 'datb|datf|datj|datl|dats|datv',
      },
      render: [
        { type: 'text', subfield: 'M|c' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
    },
    '065@': {
      label1: 'andere_namen_titel_synonyme',
      content: [
        { ns: 'label2', label2: 'andere_namen', subfield: '-4' },
        { ns: 'relationCodes', relations: '*' },
      ],
      render: [
        { type: 'text', subfield: 'a|g|z|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
      collapseMultipleEntries: true,
    },
    '065R': {
      label1: {
        geografischer_bezug: {
          relations: 'orta|ortb|ortc|ortf|ortg|orth|orts|ortv|punk',
        },
        geistige_schoepfer: {
          relations:
            'aut1|kom1|kue1|auta|koma|kuen|arch|bilh|chre|comp|desi|dich|erfi|foto|kart|libr|vfrd',
        },
      },
      content: {
        ns: 'relationCodes',
        relations: '*',
      },
      render: [
        { type: 'link', id: '>9', text: '>name|a|g|z|x' },
        {
          type: 'additional',
          subfield: '4',
          tooltips: [{ subfield: '4', ns: 'relationCodes' }],
        },
      ],
    },
  },
  label3: [],
};
