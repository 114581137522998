import { UrlQueryBuilder } from '../request';
import { AbstractQuery } from './AbstractQuery';

export class TermsQuery extends AbstractQuery {
  protected _term: string = '';

  get term(): string {
    return this._term;
  }

  set term(value: string) {
    this._term = value;
  }

  public hasTerm(): boolean {
    return this.term.trim().length > 0;
  }

  public resetTerm(): void {
    this.term = '';
  }

  public parseUrlSearchParams(params: URLSearchParams): void {
    if (params.has('term')) {
      this.term = decodeURIComponent(params.get('term') as string).trim();
    }
  }

  public addToUrlSearchParams(builder: UrlQueryBuilder): void {
    if (this.hasTerm()) {
      builder.add('term', this._term.trim());
    }
  }

  public clone() {
    const q = new TermsQuery(this.id, this.type, this._empty);
    q.term = this.term;
    return q;
  }
}
