import MapConfig from '../../../../conf/map';
import TimelineConfig from '../../../../conf/timeline';
import {
  ConfigurationResponseFilter,
  ConfigurationResponseFilterMap,
  SearchRequestFilterBase,
  SearchRequestTermsFilter,
} from '../../api/responses';
import { Operators } from '../operators';
import { AbstractFilter, Filter } from './AbstractFilter';
import { RelationJoinFilter } from './RelationJoinFilter';
import { TermsFilter } from './TermsFilter';
import { ExcludingTermsFilter } from './ExcludingTermsFilter';
import { GeoBBoxFilter } from './GeoBBoxFilter';
import { GeoRelationFilter } from './GeoRelationFilter';
import { FilterType } from './FilterType';
import { GeoNoneFilter } from './GeoNoneFilter';
import { YearRangeFilter } from './YearRangeFilter';
import { YearRelationFilter } from './YearRelationFilter';
import { YearRangeNoneFilter } from './YearRangeNoneFilter';

export class FilterFactory {
  public static createInstanceFrom(
    filterJson: SearchRequestFilterBase,
    configuration: ConfigurationResponseFilter,
  ): Filter {
    switch (configuration.type) {
      case FilterType.relationJoin:
        return AbstractFilter.create(
          RelationJoinFilter,
          filterJson,
          configuration,
        );
      case FilterType.excludingTerms:
        return AbstractFilter.create(
          ExcludingTermsFilter,
          filterJson,
          configuration,
        );
      case FilterType.geoBBox:
        return AbstractFilter.create(GeoBBoxFilter, filterJson, configuration);
      case FilterType.geoRelation: {
        return AbstractFilter.create(
          GeoRelationFilter,
          filterJson,
          configuration,
        );
      }
      case FilterType.yearRange: {
        return AbstractFilter.create(
          YearRangeFilter,
          filterJson,
          configuration,
        );
      }
      case FilterType.yearRelation: {
        return AbstractFilter.create(
          YearRelationFilter,
          filterJson,
          configuration,
        );
      }
      default: {
        if (filterJson.id === MapConfig.noPositionFacet) {
          return AbstractFilter.create(
            GeoNoneFilter,
            filterJson,
            configuration,
          );
        }
        if (filterJson.id === TimelineConfig.noRangeFacet) {
          return AbstractFilter.create(
            YearRangeNoneFilter,
            filterJson,
            configuration,
          );
        }
        return AbstractFilter.create(TermsFilter, filterJson, configuration);
      }
    }
  }

  public static createInstanceFromId(
    filterId: string,
    configuration: ConfigurationResponseFilter,
  ): Filter {
    const filterJson: SearchRequestFilterBase = {
      id: filterId,
      operator: Operators.AND,
      empty: false,
    };

    return FilterFactory.createInstanceFrom(filterJson, configuration);
  }

  public static createTermsAndExcludeTermsFiltersFrom(
    filterJson: SearchRequestTermsFilter,
    config: ConfigurationResponseFilterMap,
  ): Filter[] {
    const filters: Filter[] = [];

    let id = filterJson.id;
    if (filterJson.terms.length > 0 && config.hasOwnProperty(id)) {
      filters.push(FilterFactory.createInstanceFrom(filterJson, config[id]));
    }
    id = `${filterJson.id}.ex`;
    if (filterJson.excludedTerms.length > 0 && config.hasOwnProperty(id)) {
      filters.push(FilterFactory.createInstanceFrom(filterJson, config[id]));
    }
    return filters;
  }
}
