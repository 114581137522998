import React from 'react';
import { sanitizeHTML } from '../../../../utils/sanitizeHTML';
import { PartSeparator, TextPart } from '../style';

interface Props {
  text: string;
}

export function AdditionalText(props: Props) {
  return (
    <>
      <PartSeparator> | </PartSeparator>
      <TextPart
        dangerouslySetInnerHTML={{ __html: sanitizeHTML(props.text) }}
      />
    </>
  );
}
