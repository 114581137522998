import { Operator, Operators } from '../../../search/operators';

export type SearchRequestFilterMap = Record<string, SearchRequestFilter>;

export type SearchRequestFilter =
  | SearchRequestGeoBBoxFilter
  | SearchRequestGeoNoneFilter
  | SearchRequestGeoRelationFilter
  | SearchRequestRelationJoinFilter
  | SearchRequestTermsFilter
  | SearchRequestYearRangeFilter
  | SearchRequestYearRangeNoneFilter;

export interface SearchRequestFilterBase {
  id: string;
  operator: Operator;
  empty: boolean;
}

export interface SearchRequestTermsFilter extends SearchRequestFilterBase {
  terms: string[];
  excludedTerms: string[];
}

export const emptyTermsFilter: SearchRequestTermsFilter = {
  id: '',
  terms: [],
  excludedTerms: [],
  operator: Operators.OR,
  empty: true,
};

export interface SearchRequestRelationJoinFilter
  extends SearchRequestFilterBase {
  entity: string;
  code: string;
  satzart: string;
  dir: string;
}

export const emptyRelationJoinFilter: SearchRequestRelationJoinFilter = {
  id: '',
  operator: Operators.OR,
  entity: '',
  code: '',
  satzart: '',
  dir: '',
  empty: true,
};

export interface SearchRequestGeoBBoxFilter extends SearchRequestFilterBase {
  bottom: number;
  left: number;
  top: number;
  right: number;
}

export const emptyGeoBBoxFilter: SearchRequestGeoBBoxFilter = {
  id: '',
  bottom: 0.0,
  left: 0.0,
  top: 0.0,
  right: 0.0,
  operator: Operators.OR,
  empty: false,
};

export interface SearchRequestGeoRelationFilter
  extends SearchRequestFilterBase {
  entityId: string;
  coordinates: string;
  relationCode?: string;
}

export interface SearchRequestGeoNoneFilter extends SearchRequestFilterBase {
  terms: string[];
}

export interface SearchRequestYearRangeFilter extends SearchRequestFilterBase {
  startYear: string;
  endYear: string;
}

export interface SearchRequestYearRangeNoneFilter
  extends SearchRequestFilterBase {
  terms: string[];
}
