import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';
import {
  ConfigurationResponseFilterMap,
  SearchResponse,
  SearchResponseDocument,
} from '../../model/api/responses';
import { SearchResult } from '../../model/entity/SearchResult';
import { contextPathMapper, SearchContext } from '../../model/search/context';
import { SearchRequestFactory } from '../../model/search/factories';
import { DisplayFilter } from '../../model/search/filters';
import { SearchRequest } from '../../model/search/request';
import { SearchConfigurationState } from '../SearchConfiguration';
import { initialState } from './slice';
import { SearchState } from './types';

// First select the relevant part from the state
export const selectSearch = (state: RootState) => state.search || initialState;
const selectSearchConfiguration = (state: RootState) =>
  state.searchConfiguration || initialState;

// --------------- selectors for state: searchContext ---------------
export const selectCurrentSearchContext = createSelector(
  [selectSearch],
  searchState => searchState.searchContext,
);

// --------------- selectors for state: searchRequest ---------------
export const selectSearchRequestJson = createSelector(
  [selectSearch],
  searchState => searchState.searchRequests[searchState.searchContext],
);

export const selectSearchRequest = createSelector(
  [selectSearch, selectSearchConfiguration],
  (
    searchState: SearchState,
    searchConfigurationState: SearchConfigurationState,
  ) =>
    SearchRequestFactory.createFromResponseRequest(
      searchState.searchRequests[searchState.searchContext],
      searchConfigurationState.searchConfigurations[searchState.searchContext],
    ),
);

export const selectFilterList = createSelector(
  [selectSearchRequest],
  searchRequest => searchRequest.filterList,
);

export const selectActiveFilters = createSelector(
  [selectSearchRequest],
  searchRequest => searchRequest.filterList.getSelectedFilterTerms(),
);

export const selectActiveFiltersCount = createSelector(
  [selectActiveFilters],
  filters => filters.length,
);

export const selectIsFacetSelected = (filterId: string, value: string) =>
  createSelector([selectFilterList], filterList =>
    filterList.isValueSelected(filterId, value),
  );

export const selectIsFacetValueExcluded = (facetId: string, value: string) =>
  createSelector([selectActiveFilters], (selectedFilters: DisplayFilter[]) =>
    selectedFilters.some(
      f => f.filter.id === `${facetId}.ex` && f.filter.isValueSelected(value),
    ),
  );

const selectFilterConfigurations = createSelector(
  [selectSearch, selectSearchConfiguration],
  (
    searchState: SearchState,
    searchConfigurationState: SearchConfigurationState,
  ) => {
    const searchContext: SearchContext = searchState.searchContext;
    return searchConfigurationState.searchConfigurations[searchContext]
      .filterConfigurations;
  },
);
export const selectCanFacetBeExcluded = (facetId: string) =>
  createSelector(
    [selectFilterConfigurations],
    (filterConfigs: ConfigurationResponseFilterMap) => {
      const id = `${facetId}.ex`;
      return filterConfigs.hasOwnProperty(id);
    },
  );

export const selectCurrentSort = createSelector(
  [selectSearchRequestJson],
  searchRequestJson => searchRequestJson.sort.id,
);

export const selectCurrentRows = createSelector(
  [selectSearchRequestJson],
  searchRequestJson => searchRequestJson.rows,
);

export const selectCurrentSearchUrl = createSelector(
  [selectCurrentSearchContext, selectSearchRequest],
  (searchContext: SearchContext, searchRequest: SearchRequest) => {
    const queryStr = searchRequest.buildUrlQueryString(true);
    const pathname: string = contextPathMapper[searchContext];
    return `/${pathname}${queryStr}`;
  },
);

// --------------- selectors for state: searchResponse ---------------

export const selectSearchResponse = createSelector(
  [selectSearch],
  (searchState: SearchState) => {
    return searchState.searchResponses[searchState.searchContext];
  },
);

export const selectSearchResponseForContext = (context: SearchContext) =>
  createSelector([selectSearch], (searchState: SearchState) => {
    return searchState.searchResponses[context];
  });

export const selectHits = createSelector(
  [selectSearchResponse],
  (searchResponse: SearchResponse | null) => {
    return searchResponse?.numFound;
  },
);

export const selectResultList = createSelector(
  [selectSearchResponse],
  (searchResponse: SearchResponse | null) => {
    if (searchResponse?.documents) {
      const documents = searchResponse.documents;
      return documents.map(
        (doc: SearchResponseDocument) => new SearchResult(doc),
      );
    }
    return [];
  },
);

export const selectResultsOrFilters = createSelector(
  [selectSearchResponse],
  (searchResponse: SearchResponse | null) => {
    if (!searchResponse) {
      return false;
    }
    const hasResults = searchResponse.numFound > 0;
    const hasFilters = Object.keys(searchResponse.request.filters).length > 0;
    return hasResults || hasFilters;
  },
);

export const selectHasResults = createSelector(
  [selectSearchResponse],
  (searchResponse: SearchResponse | null) => {
    if (!searchResponse) {
      return false;
    }
    return searchResponse.numFound > 0;
  },
);

export const selectHasResponse = createSelector(
  [selectSearchResponse],
  (searchResponse: SearchResponse | null) => !!searchResponse,
);

export const selectRequestTerm = createSelector(
  [selectSearchRequest],
  request => {
    return request?.query.term ?? undefined;
  },
);
