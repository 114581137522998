import { SubfieldData } from '../entity/SubfieldData';

export class Utils {
  public static isNegativeMatch(str: string) {
    return str.startsWith('-');
  }
  public static isOptionalMatch(str: string) {
    return str === '?';
  }
  public static isMatchAll(str: string) {
    return str === '*';
  }

  public static addIfNew(arr: string[], item: string) {
    if (!arr.includes(item)) {
      arr.push(item);
    }
    return arr;
  }

  public static removeMinusIfNegativeMatch(str: string) {
    return str.startsWith('-') ? str.substring(1) : str;
  }

  public static pipesToArray(str: string) {
    const aux = Utils.removeMinusIfNegativeMatch(str);
    return aux.split('|');
  }

  public static flattenMatches(matchesByField): any[] {
    return Object.keys(matchesByField).reduce(
      (acc: any[], fieldCode: string) => {
        if (Array.isArray(matchesByField[fieldCode])) {
          const matches: any[] = matchesByField[fieldCode].map((item: any) => ({
            ...item,
            field: fieldCode,
          }));
          return [...acc, ...matches];
        } else {
          return [...acc, { ...matchesByField[fieldCode], field: fieldCode }];
        }
      },
      [],
    );
  }

  public static splitByRecordIndex(data: SubfieldData[]): SubfieldData[][] {
    const dataByRecordIndexMap = data.reduce((acc: any, s: SubfieldData) => {
      const idx = s.sortIdx ?? s.recordIdx;
      if (!acc[idx]) {
        acc[idx] = [];
      }
      acc[idx].push(s);
      return acc;
    }, {});
    return Object.keys(dataByRecordIndexMap).map(
      idx => dataByRecordIndexMap[idx],
    );
  }

  // https://support.outermedia.de/browse/GND-125:
  // String.prototype.replaceAll doesn't work in all browsers
  public static replaceAll(
    data: string,
    searchValue: string,
    replaceValue: string,
  ) {
    let result = data;
    let b = true;
    while (b) {
      result = result.replace(searchValue, replaceValue);
      if (!result.includes(searchValue)) {
        b = false;
      }
    }
    return result;
  }

  public static validateCondition(
    condition: string,
    subfields: SubfieldData[],
    subfieldNames: string[],
  ): boolean {
    const [subfield, pipedValues] = condition.split(':');
    if (!subfield) return false;

    const subfieldShouldExist = !Utils.isNegativeMatch(subfield);
    const subfieldName = Utils.removeMinusIfNegativeMatch(subfield);
    if (
      (subfieldShouldExist && !subfieldNames.includes(subfieldName)) ||
      (!subfieldShouldExist && subfieldNames.includes(subfieldName))
    )
      return false;

    if (pipedValues === undefined) return true;

    const valuesShouldExist = !Utils.isNegativeMatch(pipedValues);
    const values = Utils.pipesToArray(pipedValues);
    const someValueExists: boolean = subfields.some(subfield =>
      values.includes(subfield.value),
    );

    return (
      (valuesShouldExist && someValueExists) ||
      (!valuesShouldExist && !someValueExists)
    );
  }
}
