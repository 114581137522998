import styled, { css } from 'styled-components/macro';
import { media } from '../../../styles';
import { rotation180Styles, ToggleProps, spin } from './utils';
import { ReactComponent as BigChevronUp } from './images/akkordeonpfeil.svg';
import { ReactComponent as ExternalLink } from './images/linkextern.svg';
import { ReactComponent as FiltersOverlayClose } from './images/arrow_right_24.svg';
import { ReactComponent as GndLogo } from './images/GND-Explorer_Logo.svg';
import { ReactComponent as GoToArrow } from './images/goto_off.svg';
import { ReactComponent as GraphCenter } from './images/center_24.svg';
import { ReactComponent as GraphSlider1 } from './images/slider-level-1.svg';
import { ReactComponent as GraphSlider2 } from './images/slider-level-2.svg';
import { ReactComponent as GraphSlider3 } from './images/slider-level-3.svg';
import { ReactComponent as GraphZoomIn } from './images/plus_24.svg';
import { ReactComponent as GraphZoomOut } from './images/minus_24.svg';
import { ReactComponent as Hexagon } from './images/hexagon.svg';
import { ReactComponent as Info } from './images/info.svg';
import { ReactComponent as LinkArrow } from './images/arrow_bold_10.svg';
import { ReactComponent as LinkChain } from './images/link-chain.svg';
import { ReactComponent as LoadMoreChevronDown } from './images/akkordeon_chevron_24.svg';
import { ReactComponent as Loader } from './images/loader.svg';
import { ReactComponent as MapLegendCheckbox } from './images/map-legend-checkbox.svg';
import { ReactComponent as NoResultsFound } from './images/hexagon-face.svg';
import { ReactComponent as OrcidIdentifier } from './images/orcid.svg';
import { ReactComponent as PaginationLast } from './images/chevron_last.svg';
import { ReactComponent as PaginationNext } from './images/chevron_next.svg';
import { ReactComponent as Reload } from './images/reload_24.svg';
import { ReactComponent as ScrollToTop } from './images/to_top.svg';
import { ReactComponent as Search } from './images/search.svg';
import { ReactComponent as SmallChevronDown } from './images/chevron_down.svg';
import { ReactComponent as TimelineLegendRadioButton } from './images/timeline-legend-radio-button.svg';
import { ReactComponent as XCloseBold } from './images/close_bold.svg';
import { ReactComponent as XFacetExclude } from './images/facet_exclude.svg';
import { ReactComponent as XSmall } from './images/icon_x_small.svg';

// export all EntityType icons
export * from './EntityIcon';

export const IconLoader = styled(Loader)`
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
`;

export const Logo = styled(GndLogo)`
  transition: width 0.5s;
  width: 150px;
  height: auto;

  ${media.tablet_portrait_up`
    width: 150px;
  `}

  ${media.tablet_landscape_up`
    width: 231px;
  `}

  ${media.desktop_up`
    width: 260px;
    max-width: 100%;
  `}
`;

export const IconGoToPage = styled(GoToArrow)`
  width: 25px;
  height: 50px;

  ${media.desktop_up`
    width: 50px;
    height: 50px;
  `};
`;

export const IconExternalLink = styled(ExternalLink)`
  width: 10px;
  height: 9px;
  margin-left: 4px;
  min-width: 10px;
  min-height: 9px;
`;

export const IconFiltersOverlayClose = styled(FiltersOverlayClose)`
  width: 24px;
  height: 24px;
`;

export const IconFacetExclude = styled(XFacetExclude)`
  width: 33px;
  height: 33px;
  ${media.tablet_landscape_up`
    width: 24px;
    height: 24px;
  `};
`;

export const IconFacetSearch = styled(Search)`
  width: 18px;
  height: 18px;
`;

export const IconFacetResetSearch = styled(XSmall)`
  width: 13px;
  height: 13px;
`;

export const IconInfo = styled(Info)`
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  margin-left: 5px;
  margin-right: 5px;

  color: ${props => props.theme.color.black};
  &:hover,
  &:visited {
    color: ${props => props.theme.color.blue_text};
  }
`;

export const IconLinkArrow = styled(LinkArrow)`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;

  // animation for fade-out-sliding-left in homepage teasers
  // transition: all 0.1s ease-in-out;
`;

export const IconLinkChain = styled(LinkChain)`
  width: 17px;
  height: 22px;
  min-width: 17px;
  min-height: 22px;
`;

export const IconResetSearch = styled(XSmall)`
  width: 11px;
  height: 11px;
  ${media.tablet_landscape_up`
    width: 16px;
    height: 16px;
  `}
`;

export const IconRemoveFilter = IconResetSearch;

export const IconSearch = styled(Search)`
  width: 21px;
  height: 21px;
  ${media.tablet_landscape_up`
    width: 26px;
    height: 26px;
  `}
`;

export const IconSearchSmall = styled(Search)`
  width: 12px;
  height: 12px;
`;

const pageLastSizes = css`
  width: 14px;
  height: 20px;
`;
export const IconPaginationFirst = styled(PaginationLast)`
  ${pageLastSizes};
  transform: rotate(180deg);
`;
export const IconPaginationLast = styled(PaginationLast)`
  ${pageLastSizes};
`;

const pageNextSizes = css`
  width: 12px;
  height: 20px;
`;
export const IconPaginationNext = styled(PaginationNext)`
  ${pageNextSizes};
`;
export const IconPaginationPrev = styled(PaginationNext)`
  ${pageNextSizes};
  transform: rotate(180deg);
`;

export const IconReload = styled(Reload)`
  width: 16px;
  height: 16px;
`;

export const IconScrollToTop = styled(ScrollToTop)`
  width: 56px;
  height: auto;
  min-width: 40px;
  max-width: 40px;
  ${media.desktop_up`
    max-width: 56px;
  `}
`;

export const IconToggleChevronDown = styled(SmallChevronDown)<ToggleProps>`
  ${rotation180Styles};
  width: 13px;
  height: 13px;
`;

export const IconLoadMoreChevron = styled(LoadMoreChevronDown)<ToggleProps>`
  ${rotation180Styles};
  width: 12px;
  height: 12px;
`;
export const IconFactsheetSectionChevronUp = styled(BigChevronUp)<ToggleProps>`
  ${rotation180Styles};
  width: 17px;
  height: 10px;
  min-width: 17px;
  min-height: 10px;
`;

export const IconChevronMapPopup = styled(LoadMoreChevronDown)<ToggleProps>`
  ${rotation180Styles};
  width: 24px;
  height: 24px;
  min-width: 24px;
`;

export const IconHexagon = styled(Hexagon)`
  width: 321px;
  height: 370px;
`;

export const IconMapLegendCheckbox = styled(MapLegendCheckbox)<{
  $active: boolean;
  $checked: boolean;
}>`
  width: 24px;
  height: 24px;
  ${props =>
    !props.$checked &&
    css`
      #cross {
        display: none;
      }
    `}

  ${props =>
    !props.$active &&
    css`
      #box {
        fill: ${props.theme.color.gray_bg_search_box};
      }
    `}

  ${props =>
    props.$checked &&
    !props.$active &&
    css`
      #cross > path {
        stroke: ${props.theme.color.gray_horizontal_separator};
      }
    `}
`;

export const IconNoResultsFound = styled(NoResultsFound)`
  width: 159.95px;
  height: 184.51px;
`;

export const IconOrcid = styled(OrcidIdentifier)`
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  margin-bottom: 4px;
`;

export const IconTimelineLegendRadioButton = styled(TimelineLegendRadioButton)<{
  $active: boolean;
  $checked: boolean;
}>`
  width: 24px;
  height: 24px;
  ${props =>
    !props.$checked &&
    css`
      #dot {
        display: none;
      }
    `}

  ${props =>
    !props.$active &&
    css`
      #box {
        fill: ${props.theme.color.gray_bg_search_box};
      }
    `}

  ${props =>
    props.$checked &&
    !props.$active &&
    css`
      #dot {
        stroke: ${props.theme.color.gray_horizontal_separator};
      }
    `}
`;

export const IconCloseInfopanel = styled(XCloseBold)`
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
`;

const graphButtonStyles = css`
  width: 36px;
  height: 36px;
  min-width: 36px;
  min-height: 36px;
`;
export const IconCenterGraph = styled(GraphCenter)`
  ${graphButtonStyles};
`;

export const IconZoomIn = styled(GraphZoomIn)`
  ${graphButtonStyles};
`;
export const IconZoomOut = styled(GraphZoomOut)`
  ${graphButtonStyles};
`;

const IconGraphLevel1 = styled(GraphSlider1)`
  width: 10px;
  height: auto;
`;
const IconGraphLevel2 = styled(GraphSlider2)`
  width: 16px;
  height: auto;
`;
const IconGraphLevel3 = styled(GraphSlider3)`
  width: 22px;
  height: auto;
`;

export const iconsGraphLevel = [
  IconGraphLevel1,
  IconGraphLevel2,
  IconGraphLevel3,
];
