import { call } from 'redux-saga/effects';
import { ResponseError } from '../../../../utils/request';
import { handleRequestError } from '../../../model/api/request';
import { SearchContext } from '../../../model/search/context';
import { actions } from '../slice';

export function* handleSearchError(
  error: ResponseError | string,
  context: SearchContext,
) {
  yield call(handleRequestError, actions.searchError, error, { context });
  return;
}
