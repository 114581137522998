import { css } from 'styled-components/macro';
import { media } from './media';
import * as styleGuide from './typography-styleguide';

/**
 * ---------------------------------------------------------------
 * The fonts as used by our components.
 * ---------------------------------------------------------------
 */

// Headers
export const font_H1 = styleGuide.font_h1;
export const font_H2 = styleGuide.font_h2;
export const font_H3 = styleGuide.font_h3;
export const font_H3_Editorial = styleGuide.font_h3_redaktionell;
export const font_H4 = styleGuide.font_h4;

// Content (Body, Labels, Link)
export const font_Body = styleGuide.font_body_regular;
export const font_Body_Semibold = styleGuide.font_body_semibold;
export const font_Paragraph = styleGuide.font_paragraph;
const linkColors = css`
  color: ${props => props.theme.color.blue_text};
  &:visited {
    color: ${props => props.theme.color.blue_text};
  }
`;
export const font_Link = css`
  ${styleGuide.font_body_regular};
  ${linkColors};
`;
export const font_Label1 = styleGuide.font_label1;
export const font_Label2 = styleGuide.font_label2;
export const font_Label3 = styleGuide.font_label3;

export const font_Button_ShowMore = font_Label2;

export const font_EntityType = font_Body;
export const font_LangMenu = styleGuide.font_lang_menu;

export const font_SearchHits = css`
  ${styleGuide.font_mobile_h1};
  ${media.tablet_landscape_up`
    ${styleGuide.font_h1_search};
  `};
`;
export const font_SearchHitsSmall = styleGuide.font_infobox_h2;

export const font_SearchResultTitle = styleGuide.font_search_result_name;
export const font_SearchResultTitleComplement =
  styleGuide.font_search_result_name_complement;
export const font_FacetTitle = styleGuide.font_desktop_h3;
export const font_SelectedFiltersLinkButton =
  styleGuide.font_selected_filters_link_button;

export const font_FiltersOverlayTitle = styleGuide.font_mobile_h1;

export const font_DetailsPagination = styleGuide.font_details_pagination;
export const font_LinkCopiedTooltip = styleGuide.font_link_copied_tooltip;

export const font_InfopanelTitle = styleGuide.font_infopanel_title;
export const font_InfopanelTitleNoLink = css`
  ${styleGuide.font_infopanel_title};
  color: ${props => props.theme.color.black};
`;
export const font_Mengenentitaet_Link_To_Search = css`
  ${font_Link};
  ${styleGuide.font_mengenentitaet_link_to_search};
  span {
    ${linkColors};
  }
`;

export const font_GraphToolbarHint = styleGuide.font_graph_toolbar_hint;

export const font_ImageCopyrights = css`
  ${styleGuide.font_copyrights};
  a {
    ${linkColors};
  }
`;

export const font_RootNode = styleGuide.font_rootnode;
export const font_EntityHeaderCreators =
  styleGuide.font_entity_header_geistige_schoepfer;

export const font_HierarchyRoot = styleGuide.font_hierarchy_root;
export const font_HierarchyNode = styleGuide.font_hierarchy_node;
export const font_HierarchyMeta = styleGuide.font_label3;
export const font_HierarchyRelation = styleGuide.font_hierarchy_relation;
export const font_HierarchyAdditional = css`
  ${styleGuide.font_label3};
  color: ${props => props.theme.color.gray_super_dark};
`;

export const font_MapPopupTitle = styleGuide.font_infobox_h2;

export const font_TimelineAxis = styleGuide.font_timeline_axis;
export const font_Footnote = styleGuide.font_footnote;
export const font_GoToPageInput = styleGuide.font_GoToPageInput;
