import styled, { css } from 'styled-components/macro';
import { media } from '../../../styles';
import { contentLayoutShiftRightStyles } from '../PageAside';

export const PageGrid = styled.div`
  min-height: 100vh;

  display: -ms-grid;
  display: grid;

  grid-template-rows: auto 1fr auto;
  -ms-grid-rows: auto 1fr auto;

  grid-column-gap: 0;
  grid-template-columns: 1fr;
  -ms-grid-columns: 1fr;

  grid-template-areas:
    'page--header'
    'page--main'
    'page--footer'
    'page--scrollUp';
`;

export const PageMain = styled.main<{ $searchPage?: boolean }>`
  grid-area: page--main;
  max-width: 100vw;
  background-color: ${props => props.theme.color.light_gray};
  ${props =>
    props.$searchPage &&
    css`
      background: linear-gradient(
        180deg,
        ${props => props.theme.color.gray_gradient_dark} 0%,
        5%,
        ${props => props.theme.color.gray_bg_search_box} 10%
      );
    `}
  ${contentLayoutShiftRightStyles};
`;

export const pageContentPadding = css`
  padding-left: ${props => props.theme.pagePadding.mobile_left}rem;
  padding-right: ${props => props.theme.pagePadding.mobile_right}rem;

  ${media.tablet_portrait_up`
    padding-left: ${props => props.theme.pagePadding.tablet_portrait_left}rem;
    padding-right: ${props => props.theme.pagePadding.tablet_portrait_right}rem;
  `};

  ${media.tablet_landscape_up`
    padding-left: ${props => props.theme.pagePadding.tablet_landscape}rem;
    padding-right: ${props => props.theme.pagePadding.tablet_landscape}rem;
  `}

  ${media.desktop_up`
    padding-left: ${props => props.theme.pagePadding.desktop}rem;
    padding-right: ${props => props.theme.pagePadding.desktop}rem;
  `}
`;

export const pageContentStyles = css`
  margin-right: auto;
  margin-left: auto;
  ${pageContentPadding};
  max-width: ${props => props.theme.pageContentMaxWidth}px;
`;
