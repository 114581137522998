import { IconCodes, IconConfiguration } from '../../../conf/icons';

export function resolveGraphNodeColor(icon: string): string {
  return icon && IconConfiguration.color.hasOwnProperty(icon)
    ? IconConfiguration.color[icon]
    : '#000';
}

export function resolveSearchListEntityIconComponent(icon?: string) {
  return icon &&
    IconConfiguration.components.withBackground.hasOwnProperty(icon)
    ? IconConfiguration.components.withBackground[icon]
    : null;
}

export function resolveHierarchyRootIconComponent(icon?: string) {
  return resolveSearchListEntityIconComponent(icon);
}

export function resolveHierarchyIconComponent(icon?: string) {
  return icon &&
    IconConfiguration.components.hierarchySmall.hasOwnProperty(icon)
    ? IconConfiguration.components.hierarchySmall[icon]
    : null;
}

export function resolveIconSvgRelations(icon?: string) {
  return icon &&
    IconConfiguration.components.relationsGraphSVG.hasOwnProperty(icon)
    ? IconConfiguration.components.relationsGraphSVG[icon]
    : null;
}

export function resolveIconSvg(collapsed: boolean) {
  if (
    !IconConfiguration.components.relationsGraphSVG.hasOwnProperty('open') ||
    !IconConfiguration.components.relationsGraphSVG.hasOwnProperty('close')
  )
    throw new Error('could not find svg');
  return collapsed
    ? IconConfiguration.components.relationsGraphSVG.open
    : IconConfiguration.components.relationsGraphSVG.close;
}

export function resolveIconCode(satzart: string): IconCodes {
  if (!IconConfiguration.mapSatzartToIconCode.hasOwnProperty(satzart)) {
    throw new Error(`could not find satzart for facet satzart.${satzart}`);
  }
  return IconConfiguration.mapSatzartToIconCode[satzart];
}

export function resolveSatzartFacetIconProps(iconCode: IconCodes) {
  const color = IconConfiguration.color[iconCode];
  const Icon = IconConfiguration.components.satzartFacet[iconCode];
  return {
    color,
    Icon,
  };
}
