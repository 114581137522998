import { BaseType } from 'd3-selection';
import TimelineConfig from '../../../conf/timeline';
import { defaultTheme } from '../../../styles';
import { TimelineBar, TimelineBucket } from './TimelineChart';

export class TimelineUtils {
  public static getMaxCount(data: TimelineBar[]): number {
    return Math.max(...data.map((b: TimelineBar) => b.count));
  }

  public static computeBarHeightForCount(
    graphHeight: number,
    facetCount: number,
    maxCount: number,
  ): number {
    if (facetCount === 0) {
      return 0;
    }
    return Math.max(2, (facetCount * graphHeight) / maxCount);
  }

  public static getTimelineFacetIdHasRelation(relationCode: string) {
    return TimelineConfig.facets.hasRelation.replace('{rel}', relationCode);
  }

  public static applyMobileStyles() {
    const isBelowPortraitWidth: boolean =
      window.innerWidth < defaultTheme.breakpoints.tablet_portrait;
    const isFiltersPanelOpen: boolean = document.body.classList.contains(
      defaultTheme.classes.hasFiltersPanel,
    );
    return isBelowPortraitWidth || isFiltersPanelOpen;
  }

  public static getTransformValues(selection) {
    const transform = selection.attr('transform');
    const m = transform.match(/translate\((-?[0-9.?]*),[ ]?(-?[0-9.?]*)\)/);
    if (m && m[1] !== undefined && m[2] !== undefined) {
      const x = parseFloat(m[1]);
      const y = parseInt(m[2]);
      return { x, y };
    }
    return null;
  }

  public static getSelectionLimits(
    selection,
  ): { x1: number; x2: number } | null {
    if (!selection) return null;
    const node: BaseType = selection.node();
    if (node) {
      const element = node as Element;
      const rect = element.getBoundingClientRect();
      return { x1: rect.x, x2: rect.x + rect.width };
    }
    return null;
  }

  public static resolveStartYear(yearToTruncate, earliestYear) {
    return Math.max(yearToTruncate, earliestYear);
  }

  public static resolveEndYear(yearToTruncate, selectedEndYear) {
    const currentYear = new Date().getFullYear();
    return Math.min(selectedEndYear, Math.min(yearToTruncate, currentYear));
  }

  public static resolveStartAndEndYears(
    bucket: TimelineBucket,
    nextBucket: TimelineBucket,
    gap: number,
    minYear: number,
    maxYear: number,
  ) {
    return {
      start: TimelineUtils.resolveStartYear(bucket.year, minYear),
      end: TimelineUtils.resolveEndYear(
        nextBucket?.year ?? bucket.year + gap,
        maxYear,
      ),
    };
  }
}
