import { css, keyframes } from 'styled-components/macro';

export const spin = keyframes`
  from {
    transform: rotate(0deg);
   }
  to {
    transform: rotate(359deg);
  }
`;

// Toggle
// const rotate180 = keyframes`
//   from {transform: rotate(0deg);}
//   to {transform: rotate(180deg);}
// `;
// const rotate180Reverse = keyframes`
//   from {transform: rotate(180deg);}
//   to {transform: rotate(360deg);}
// `;
export interface ToggleProps {
  $active?: boolean;
}
export const rotation180Styles = css<ToggleProps>`
  // todo only animate when state changes but not on initial state
  // animation: $ {(props: ToggleProps) => props.active === 'true' ? rotate180Reverse : rotate180} 300ms linear;
  transform: ${p => (p.$active ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

// animation used in homepage teaser links with arrow
export const fadeOutSlidingRight = css`
  transform: translateX(8px);
  opacity: 0;
`;
