import styled from 'styled-components/macro';

export const IconButton = styled.button`
  cursor: pointer;
  border: none;
  background: transparent;
  display: block;
  margin: 0;
  padding: 0;
`;
