import { css } from 'styled-components/macro';
import { defaultTheme } from './defaultTheme';

export const fontFaces = css`
  @font-face {
    font-family: 'EuclidCircularA';
    font-style: normal;
    font-weight: ${defaultTheme.font.weight.light};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-Light-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-Light-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-Light-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-Light-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-Light-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-Light-WebXL.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'EuclidCircularA';
    font-style: italic;
    font-weight: ${defaultTheme.font.weight.light};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-LightItalic-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-LightItalic-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-LightItalic-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-LightItalic-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-LightItalic-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-LightItalic-WebXL.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'EuclidCircularA';
    font-style: normal;
    font-weight: ${defaultTheme.font.weight.regular};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-Regular-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-Regular-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-Regular-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-Regular-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-Regular-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-Regular-WebXL.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'EuclidCircularA';
    font-style: italic;
    font-weight: ${defaultTheme.font.weight.regular};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-RegularItalic-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-RegularItalic-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-RegularItalic-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-RegularItalic-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-RegularItalic-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-RegularItalic-WebXL.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'EuclidCircularA';
    font-style: normal;
    font-weight: ${defaultTheme.font.weight.medium};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-Medium-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-Medium-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-Medium-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-Medium-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-Medium-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-Medium-WebXL.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'EuclidCircularA';
    font-style: italic;
    font-weight: ${defaultTheme.font.weight.medium};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-MediumItalic-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-MediumItalic-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-MediumItalic-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-MediumItalic-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-MediumItalic-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-MediumItalic-WebXL.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'EuclidCircularA';
    font-style: normal;
    font-weight: ${defaultTheme.font.weight.semibold};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-Semibold-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-Semibold-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-Semibold-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-Semibold-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-Semibold-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-Semibold-WebXL.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'EuclidCircularA';
    font-style: italic;
    font-weight: ${defaultTheme.font.weight.semibold};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-SemiboldItalic-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-SemiboldItalic-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-SemiboldItalic-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-SemiboldItalic-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-SemiboldItalic-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-SemiboldItalic-WebXL.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'EuclidCircularA';
    font-style: normal;
    font-weight: ${defaultTheme.font.weight.bold};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-Bold-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-Bold-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-Bold-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-Bold-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-Bold-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-Bold-WebXL.svg') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: 'EuclidCircularA';
    font-style: italic;
    font-weight: ${defaultTheme.font.weight.bold};
    font-display: swap;
    src: url('/fonts/EuclidCircularA-BoldItalic-WebXL.eot'); /* IE9 Compat Modes */
    src: url('/fonts/EuclidCircularA-BoldItalic-WebXL.eot?#iefix') format('embedded-opentype'),
      /* IE6-IE8 */
      url('/fonts/EuclidCircularA-BoldItalic-WebXL.woff2') format('woff2'),
      /* Super Modern Browsers */
      url('/fonts/EuclidCircularA-BoldItalic-WebXL.woff') format('woff');
      /* Modern Browsers */
      url('/fonts/EuclidCircularA-BoldItalic-WebXL.ttf') format('truetype');
      /* Safari, Android, iOS */
      url('/fonts/EuclidCircularA-BoldItalic-WebXL.svg') format('svg'); /* Legacy iOS */
  }
`;
