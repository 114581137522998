import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../store';

const selectRouter = (state: RootState) => state.router;

export const selectRouterSearch = createSelector(
  [selectRouter],
  routerState => {
    let queryStr = routerState?.location?.search || '';
    if (queryStr.startsWith('?')) {
      queryStr = queryStr.substring(1);
    }
    return queryStr;
  },
);

export const selectRouterHash = createSelector([selectRouter], routerState => {
  return routerState?.location?.hash || '';
});
