import styled, { css } from 'styled-components/macro';
import { ReactComponent as Bauwerk } from './images/Bauwerk_18.svg';
import { ReactComponent as Ereignis } from './images/Ereignis_18.svg';
import { ReactComponent as Geografikum } from './images/Geografikum_18.svg';
import { ReactComponent as Organisation } from './images/Organisation_18.svg';
import { ReactComponent as Person } from './images/Person_18.svg';
import { ReactComponent as Sachbegriff } from './images/Schlagwort_18.svg';
import { ReactComponent as Veranstaltung } from './images/Veranstaltung_18.svg';
import { ReactComponent as Werk } from './images/Werk_18.svg';

export type EntityIconSize = 'small' | 'details' | undefined;

interface Props {
  $size?: EntityIconSize;
}
const iconStyles = css<Props>`
  &:hover {
    cursor: help;
  }
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;

  ${props =>
    props.$size &&
    props.$size === 'small' &&
    css`
      width: 12px;
      height: 12px;
      min-width: 12px;
      min-height: 12px;
    `};
  ${props =>
    props.$size &&
    props.$size === 'details' &&
    css`
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
    `};
`;
export const IconBauwerk = styled(Bauwerk)<Props>`
  ${iconStyles}
`;
export const IconEreignis = styled(Ereignis)<Props>`
  ${iconStyles}
`;
export const IconGeografikum = styled(Geografikum)<Props>`
  ${iconStyles}
`;
export const IconOrganisation = styled(Organisation)<Props>`
  ${iconStyles}
`;
export const IconPerson = styled(Person)<Props>`
  ${iconStyles}
`;
export const IconSachbegriff = styled(Sachbegriff)<Props>`
  ${iconStyles}
`;
export const IconVeranstaltung = styled(Veranstaltung)<Props>`
  ${iconStyles}
`;
export const IconWerk = styled(Werk)<Props>`
  ${iconStyles}
`;
