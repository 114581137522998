import { DegreeMinSec, LatLonDms } from './types';
import { TFunction } from 'i18next';

/**
 * Converts decimal degrees to degrees minutes seconds.
 * @param dd the decimal degrees value.
 * @param isLon specifies whether the decimal degrees value is a longitude.
 * @return object with direction, degrees, minutes and seconds
 */
export function decimalCoordinates2Dms(dd, isLon): DegreeMinSec {
  const dir = dd < 0 ? (isLon ? 'W' : 'S') : isLon ? 'E' : 'N';

  const absDd = Math.abs(dd);
  const deg = absDd | 0;
  const frac = absDd - deg;
  const min = (frac * 60) | 0;
  let sec = frac * 3600 - min * 60;
  // Round it to 2 decimal points.
  sec = Math.round(sec * 100) / 100;
  return { deg, min, sec, dir };
}

export function latLon2Dms(lat: number, lon: number): LatLonDms {
  return {
    latitude: decimalCoordinates2Dms(lat, false),
    longitude: decimalCoordinates2Dms(lon, true),
  };
}

export function dmsToString(t: TFunction, dms: DegreeMinSec) {
  return t('Coordinates.dms', {
    direction: dms.dir,
    degrees: dms.deg,
    minutes: dms.min,
    seconds: dms.sec,
  });
}

export function latLonDmsToString(t: TFunction, coordinates: LatLonDms) {
  const latitude = dmsToString(t, coordinates.latitude);
  const longitude = dmsToString(t, coordinates.longitude);
  return `${latitude} ${longitude}`;
}
