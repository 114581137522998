import { EntityResponseField } from '../api/responses';
import { SubfieldData } from './SubfieldData';
import { JsonFieldsProcessor } from './JsonFieldsProcessor';

export class EntityFields {
  protected _list: SubfieldData[] = [];
  protected _mapByField: Record<string, SubfieldData[]> = {};
  protected _mapByName: Record<string, SubfieldData[]> = {};
  protected _mapByRecIdx: Record<string, SubfieldData[]> = {};

  public static initFromEntityJson(json: EntityResponseField[]) {
    const processor = new JsonFieldsProcessor();
    processor.process(json);
    const ef = new EntityFields();
    ef.list = processor.list;
    ef.mapByField = processor.mapByField;
    ef.mapByName = processor.mapByName;
    ef.mapByRecIdx = processor.mapByRecIdx;
    return ef;
  }

  set list(list: SubfieldData[]) {
    this._list = list;
  }

  get mapByField() {
    return this._mapByField;
  }

  set mapByField(map: Record<string, SubfieldData[]>) {
    this._mapByField = map;
  }

  set mapByName(map: Record<string, SubfieldData[]>) {
    this._mapByName = map;
  }

  set mapByRecIdx(map: Record<string, SubfieldData[]>) {
    this._mapByRecIdx = map;
  }

  public hasField(field: string): boolean {
    return this._mapByField.hasOwnProperty(field);
  }

  public getSubfields(field: string): SubfieldData[] {
    return this._mapByField[field] || [];
  }

  public hasSubfield(field: string, subfieldName: string): boolean {
    return this.getSubfields(field).some(item => item.name === subfieldName);
  }

  protected getDataForRecordIndexes(indexes: number[]) {
    const usedIndexes: number[] = [];
    return indexes.reduce((acc: SubfieldData[], idx: number) => {
      if (!usedIndexes.includes(idx)) {
        usedIndexes.push(idx);
        return [...acc, ...this._mapByRecIdx[idx]];
      }
      return acc;
    }, []);
  }

  protected getRecordIndexesWithMatchingName(
    field: string,
    subfieldName: string,
  ): number[] {
    return this.getSubfields(field)
      .filter(item => item.name === subfieldName)
      .map(item => item.recordIdx);
  }

  public getAllSubfieldsMatchingName(
    field: string,
    subfieldName: string,
  ): SubfieldData[] {
    return this.getDataForRecordIndexes(
      this.getRecordIndexesWithMatchingName(field, subfieldName),
    );
  }

  public getAllSubfieldsNotMatchingName(
    field: string,
    subfieldName: string,
  ): SubfieldData[] {
    const indexesToExclude = this.getRecordIndexesWithMatchingName(
      field,
      subfieldName,
    );
    return this.getSubfields(field).filter(
      item => !indexesToExclude.includes(item.recordIdx),
    );
  }

  public getSubfieldValuesForMatchingName(
    field: string,
    subfieldName: string,
  ): string[] {
    return this.getSubfields(field).reduce(
      (acc: string[], item: SubfieldData) => {
        if (item.name === subfieldName) {
          acc.push(item.value);
        }
        return acc;
      },
      [],
    );
  }

  public findSubfieldWithValue(
    field: string,
    subfieldName: string,
    value: string,
  ): SubfieldData | undefined {
    return this.getAllSubfieldsMatchingName(field, subfieldName).find(
      item => item.value === value,
    );
  }

  public hasSubfieldsMatchingValues(
    field: string,
    subfieldName: string,
    values: string[],
  ): boolean {
    return this.getSubfields(field).some(
      item => item.name === subfieldName && values.includes(item.value),
    );
  }

  public getAllSubfieldsMatchingValues(
    field: string,
    subfieldName: string,
    values: string[],
  ): SubfieldData[] {
    if (!this._mapByName[subfieldName]) {
      return [];
    }
    return this.getDataForRecordIndexes(
      this._mapByName[subfieldName]
        .filter(item => item.field === field && values.includes(item.value))
        .map(item => item.recordIdx),
    );
  }

  public getAllSubfieldsNotMatchingValues(
    field: string,
    subfieldName: string,
    values: string[],
  ): SubfieldData[] {
    const indexesToInclude = this._mapByField[field].reduce(
      (acc: number[], item: SubfieldData) => {
        if (item.name === subfieldName && !values.includes(item.value)) {
          if (!acc.includes(item.recordIdx)) {
            acc.push(item.recordIdx);
          }
        }
        return acc;
      },
      [],
    );
    return this.getSubfields(field).filter(item =>
      indexesToInclude.includes(item.recordIdx),
    );
  }

  public getAllSubfieldsMatchingValuesOptional(
    field: string,
    subfieldName: string,
    values: string[],
  ): SubfieldData[] {
    const indexesToExclude: number[] = [];
    // exclude all records that don't match the value
    this.getSubfields(field).forEach((item: SubfieldData) => {
      if (
        item.name === subfieldName &&
        !values.includes(item.value) &&
        !indexesToExclude.includes(item.recordIdx)
      ) {
        indexesToExclude.push(item.recordIdx);
      }
    });
    // return all non-excluded indexes
    return this.getSubfields(field).filter(
      item => !indexesToExclude.includes(item.recordIdx),
    );
  }

  public getAllSubfieldsNotMatchingValuesOptional(
    field: string,
    subfieldName: string,
    values: string[],
  ): SubfieldData[] {
    const indexesToExclude: number[] = [];
    // exclude all records that match the value
    this.getSubfields(field).forEach((item: SubfieldData) => {
      if (
        item.name === subfieldName &&
        values.includes(item.value) &&
        !indexesToExclude.includes(item.recordIdx)
      ) {
        indexesToExclude.push(item.recordIdx);
      }
    });
    // return all non-excluded indexes
    return this.getSubfields(field).filter(
      item => !indexesToExclude.includes(item.recordIdx),
    );
  }

  /**
   * RELATION-CODE methods
   * */
  public getAllRelations(field: string): SubfieldData[] {
    return this.getAllSubfieldsMatchingName(field, '4');
  }

  public getAllNonRelations(field: string): SubfieldData[] {
    return this.getAllSubfieldsNotMatchingName(field, '4');
  }

  public hasSubfieldsMatchingRelations(
    field: string,
    relationCodes: string[],
  ): boolean {
    return this.hasSubfieldsMatchingValues(field, '4', relationCodes);
  }

  public getAllSubfieldsMatchingRelations(
    field: string,
    relationCodes: string[],
  ): SubfieldData[] {
    return this.getAllSubfieldsMatchingValues(field, '4', relationCodes);
  }

  public getAllSubfieldsNotMatchingRelations(
    field: string,
    relationCodes: string[],
  ): SubfieldData[] {
    return this.getAllSubfieldsNotMatchingValues(field, '4', relationCodes);
  }

  public getAllSubfieldsMatchingRelationsOptional(
    field: string,
    relationCodes: string[],
  ): SubfieldData[] {
    return this.getAllSubfieldsMatchingValuesOptional(
      field,
      '4',
      relationCodes,
    );
  }

  public getAllSubfieldsNotMatchingRelationsOptional(
    field: string,
    relationCodes: string[],
  ): SubfieldData[] {
    return this.getAllSubfieldsNotMatchingValuesOptional(
      field,
      '4',
      relationCodes,
    );
  }

  /**
   * IDENTIFIER methods
   * */
  public getAllSubfieldsMatchingIdentifiers(
    field: string,
    identifiers: string[],
  ): SubfieldData[] {
    const subfield = field === '006Y' ? 'S' : 'a';
    return this.getAllSubfieldsMatchingValues(field, subfield, identifiers);
  }

  public getAllSubfieldsNotMatchingIdentifiers(
    field: string,
    identifiers: string[],
  ): SubfieldData[] {
    const subfield = field === '006Y' ? 'S' : 'a';
    return this.getAllSubfieldsNotMatchingValues(field, subfield, identifiers);
  }

  /**
   * RECORD_TYPE methods
   * */
  protected getSatzartSubfields(): SubfieldData[] {
    return this.getSubfields('002@');
  }

  protected getMatchingSatzart(value: string): SubfieldData[] {
    return this.getSatzartSubfields().filter(
      item => item.name === '0' && item.value.startsWith(value),
    );
  }

  public hasRecordTypes(recordType: string[]): boolean {
    const regex = new RegExp(recordType.map(rt => `^${rt}`).join('|'));
    return this.getSatzartSubfields().some(
      item => item.name === '0' && regex.test(item.value),
    );
  }
}
