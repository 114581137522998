/**
 * Create the store with dynamic reducers
 */

import { configureStore } from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';
import { createReduxHistory, routerMiddleware } from './history';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = getDefaultMiddleware =>
    getDefaultMiddleware().concat(sagaMiddleware).concat(routerMiddleware);

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];

  const store = configureStore({
    reducer: createReducer(),
    middleware: middlewares,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });

  return { store, history: createReduxHistory(store) };
}
