import { TermsFacet } from './TermsFacet';
import {
  ConfigurationResponseTermsFacet,
  FacetResponseItem,
  SearchRequestFacet,
} from '../../api/responses';

export class RangeErrorFacet extends TermsFacet {
  private _totalBucketsWithRangeError: number = 0;

  public init(
    facetResponseJson: FacetResponseItem,
    facetRequestJson: SearchRequestFacet,
    config: ConfigurationResponseTermsFacet,
  ): void {
    super.init(facetResponseJson, facetRequestJson, config);
    this._totalBucketsWithRangeError = this._buckets[0]?.count ?? 0;
  }

  get count(): number {
    return this._totalBucketsWithRangeError;
  }
}
