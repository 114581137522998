import {
  ConfigurationResponseFacet,
  SearchRequestFacet,
} from '../../../api/responses';
import { UrlQueryBuilder } from '../../request';

export abstract class RequestFacet {
  protected _id: string;
  protected _type: string;

  public abstract addToRequestQuery(builder: UrlQueryBuilder): void;

  public static create<F extends RequestFacet>(
    FacetInstance: new (
      config: ConfigurationResponseFacet,
      responseRequestFacet?: SearchRequestFacet,
    ) => F,
    config: ConfigurationResponseFacet,
    responseRequestFacet?: SearchRequestFacet,
  ): F {
    const facet: F = new FacetInstance(config, responseRequestFacet);
    return facet;
  }

  protected constructor(id: string, type: string) {
    this._id = id;
    this._type = type;
  }

  get id(): string {
    return this._id;
  }

  get type(): string {
    return this._type;
  }
}
