import { css } from 'styled-components/macro';
import { media } from './media';

/**
 * ---------------------------------------------------------------
 * The fonts as defined in the styleguide.
 *
 * For easier maintenance, ensure names match exactly the ones in
 * the styleguide.
 * ---------------------------------------------------------------
 */

export const font_h1 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  font-size: ${props => props.theme.font.size._24}rem;
  line-height: ${30 / 24};
  color: ${props => props.theme.color.black};

  ${media.desktop_up`
    font-size: ${props => props.theme.font.size._36}rem;
    line-height: ${36 / 36};
  `};
`;

export const font_h1_search = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  font-size: ${props => props.theme.font.size._30}rem;
  line-height: ${36 / 30};
  color: ${props => props.theme.color.black};
`;

export const font_mobile_h1 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  font-size: ${props => props.theme.font.size._24}rem;
  line-height: ${30 / 24};
  color: ${props => props.theme.color.black};
`;

export const font_h2 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._25}rem;
  line-height: ${30 / 25};
  color: ${props => props.theme.color.black};
  letter-spacing: 2px;
  text-transform: uppercase;
`;

export const font_infobox_h2 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  font-size: ${props => props.theme.font.size._18}rem;
  line-height: ${23 / 18};
  letter-spacing: 0.5px;
  color: ${props => props.theme.color.black};
`;

export const font_h3 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._18}rem;
  line-height: ${22 / 18};
  color: ${props => props.theme.color.black};
  letter-spacing: 1.125px;
  text-transform: uppercase;
`;

export const font_h3_redaktionell = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._23}rem;
  line-height: ${27 / 23};
  color: ${props => props.theme.color.black};
  letter-spacing: 0.2px;
`;

export const font_desktop_h3 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._18}rem;
  line-height: ${22 / 18};
  color: ${props => props.theme.color.black};
  text-transform: uppercase;
`;

export const font_h4 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.bold};
  font-size: ${props => props.theme.font.size._17}rem;
  line-height: ${22 / 17};
  color: ${props => props.theme.color.black};
`;

const font_body = css`
  font-family: ${props => props.theme.font.family};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${22 / 16};
  color: ${props => props.theme.color.black};
`;
export const font_body_regular = css`
  ${font_body};
  font-weight: ${props => props.theme.font.weight.regular};
`;
export const font_body_semibold = css`
  ${font_body};
  font-weight: ${props => props.theme.font.weight.semibold};
`;

export const font_paragraph = css`
  ${font_body_regular};
  ${media.mobile_up`
    font-size: ${props => props.theme.font.size._20}rem;
    line-height: ${25 / 20};
  `};
`;

export const font_label1 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  font-size: ${props => props.theme.font.size._17}rem;
  line-height: ${22 / 17};
  color: ${props => props.theme.color.black};
`;

export const font_label2 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._14}rem;
  line-height: ${22 / 14};
  color: ${props => props.theme.color.black};
`;

export const font_label3 = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._14}rem;
  line-height: ${22 / 14};
  letter-spacing: 0.3;
  color: ${props => props.theme.color.black};
`;

export const font_details_pagination = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._11}rem;
  line-height: ${19 / 11};
  color: ${props => props.theme.color.black};

  ${media.desktop_up`
    font-size: ${props => props.theme.font.size._14}rem;
    line-height: ${19 / 14};
  `};
`;

export const font_lang_menu = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._13}rem;
  line-height: ${22 / 13};
  color: ${props => props.theme.color.black};

  ${media.tablet_landscape_up`
    font-size: ${props => props.theme.font.size._16}rem;
    line-height: ${22 / 16};
  `};
`;

export const font_link_copied_tooltip = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._14}rem;
  line-height: ${19 / 14};
  color: ${props => props.theme.color.white};
`;

export const font_infopanel_title = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  font-size: ${props => props.theme.font.size._25}rem;
  line-height: ${31 / 25};
  color: ${props => props.theme.color.blue_text};
`;

export const font_graph_toolbar_hint = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${22 / 16};
  color: ${props => props.theme.color.gray_bg_copy_tooltip};
`;

export const font_copyrights = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._10}rem;
  line-height: ${13 / 10};
  color: rgba(0, 0, 0, 0.7);
  text-shadow: 0px 0px 4px rgba(255, 255, 255, 0.5);

  ${media.tablet_portrait_up`
    font-size: ${props => props.theme.font.size._12}rem;
    line-height: ${15 / 12};
  `};
`;

export const font_rootnode = css`
  ${font_body_regular};
  font-size: ${props => props.theme.font.size._20}rem;
  line-height: ${22 / 20};
`;

export const font_entity_header_geistige_schoepfer = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  color: ${props => props.theme.color.black};
  font-size: ${props => props.theme.font.size._18}rem;
  line-height: ${20 / 18};

  ${media.desktop_up`
    font-size: ${props => props.theme.font.size._24}rem;
    line-height: ${27 / 24};
  `};
`;

export const font_search_result_name = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  font-size: ${props => props.theme.font.size._18}rem;
  line-height: ${22 / 18};
  letter-spacing: 0.5px;
  color: ${props => props.theme.color.black};
`;

export const font_search_result_name_complement = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${22 / 16};
  letter-spacing: 0.2px;
  color: ${props => props.theme.color.black};
`;

export const font_selected_filters_link_button = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._14}rem;
  line-height: ${22 / 14};
  color: ${props => props.theme.color.blue_text};
`;

export const font_hierarchy_root = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.semibold};
  font-size: ${props => props.theme.font.size._19}rem;
  line-height: ${26 / 19};
  letter-spacing: 0.2px;
`;

export const font_hierarchy_node = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._16}rem;
  line-height: ${18 / 16};
`;

export const font_hierarchy_relation = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._12}rem;
  line-height: ${15 / 12};
  letter-spacing: 0.3px;
  color: ${props => props.theme.color.gray_bg_copy_tooltip};

  text-shadow:
    -1px -1px 0 ${props => props.theme.color.white},
    1px -1px 0 ${props => props.theme.color.white},
    -1px 1px 0 ${props => props.theme.color.white},
    1px 1px 0 ${props => props.theme.color.white};
`;

export const font_mengenentitaet_link_to_search = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.medium};
  font-size: ${props => props.theme.font.size._17}rem;
  line-height: ${22 / 17};
  letter-spacing: 0.2px;
`;

export const font_timeline_axis = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._11}rem;
  line-height: ${19 / 11};
  color: ${props => props.theme.color.black};

  ${media.tablet_portrait_up`
    font-size: ${props => props.theme.font.size._14}rem;
    line-height: ${19 / 14};
  `};
`;

export const font_footnote = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._12}rem;
  line-height: ${15 / 12};
  color: ${props => props.theme.color.blue_logo};
`;

export const font_GoToPageInput = css`
  font-family: ${props => props.theme.font.family};
  font-weight: ${props => props.theme.font.weight.regular};
  font-size: ${props => props.theme.font.size._12}rem;
  line-height: ${16 / 12};
  letter-spacing: 0;
  color: ${props => props.theme.color.black};

  ${media.desktop_up`
    font-size: ${props => props.theme.font.size._14}rem;
    line-height: ${16 / 14};
  `};
`;
