import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '../../locales/i18n';

export function useLanguageSwitcher() {
  const { i18n } = useTranslation();
  const currentI18nLanguage = i18n.language;
  const { pathname } = useLocation();
  const langPathParam: string | undefined = SUPPORTED_LANGUAGES.find(
    (lang: string) => pathname.startsWith(`/${lang}/`),
  );

  useEffect(() => {
    if (langPathParam && currentI18nLanguage !== langPathParam) {
      i18n.changeLanguage(langPathParam);
    } else if (!langPathParam && currentI18nLanguage !== DEFAULT_LANGUAGE) {
      i18n.changeLanguage(DEFAULT_LANGUAGE);
    }
  }, [i18n, langPathParam, currentI18nLanguage]);
}
