import styled from 'styled-components/macro';
import { media } from '../../../styles';
import { Grid } from '../Grid';
import { contentLayoutShiftRightStyles } from '../PageAside';
import { pageContentStyles } from '../PageLayout/style';

export const Header = styled.header`
  grid-area: page--header;
  background-color: ${props => props.theme.color.white};
  padding-bottom: ${props => props.theme.spacing._25}rem;

  ${media.tablet_portrait_up`
    padding-bottom: ${props => props.theme.spacing._52}rem;
  `};

  ${media.tablet_landscape_up`
    padding-bottom: ${props => props.theme.spacing._59}rem;
  `};
  ${contentLayoutShiftRightStyles};
`;

export const HeaderGrid = styled(Grid)`
  ${pageContentStyles};
  grid-row-gap: ${props => props.theme.spacing._16}rem;
  align-items: center;

  grid-column-gap: ${props => props.theme.spacing._15}rem;
  grid-template-columns: auto 1fr;
  -ms-grid-columns: auto ${props => props.theme.spacing._15}rem 1fr;

  grid-template-areas:
    'logo language-switcher'
    'search-box search-box';

  ${media.tablet_portrait_up`
    grid-row-gap: ${props => props.theme.spacing._20}rem;
    grid-column-gap: ${props => props.theme.spacing._38}rem;
    -ms-grid-columns: auto ${props => props.theme.spacing._38}rem 1fr;

    grid-template-areas:
      '. language-switcher'
      'logo search-box';
  `};

  ${media.desktop_up`
    grid-row-gap: ${props => props.theme.spacing._14}rem;
    grid-column-gap: ${props => props.theme.spacing._36}rem;
    grid-template-columns: 3fr 7fr 2fr;
    -ms-grid-columns: 3fr ${props => props.theme.spacing._10}rem 7fr ${props =>
      props.theme.spacing._10}rem 2fr;

    grid-template-areas:
      '. . language-switcher'
      'logo search-box .';
  `};
`;
