import TimelineConfig from '../../../../conf/timeline';
import { UrlQueryBuilder } from '../request';
import { AbstractFilter, Filter } from './AbstractFilter';
import {
  DisplayFilter,
  DisplayFilterType,
  DisplayFilterYearRange,
} from './DisplayFilter';

export interface YearRangeFilterValue {
  relationCode: string;
  startYear: string;
  endYear: string;
}

export class YearRangeFilter extends AbstractFilter<YearRangeFilterValue> {
  protected _relationCode: string = '';
  protected _startYear: string = '';
  protected _endYear: string = '';

  public init(data: any): void {
    this._relationCode = data.id
      ? data.id.replace(`${TimelineConfig.filter}_`, '')
      : '';
    this._startYear = `${data.startYear}` ?? '';
    this._endYear = `${data.endYear}` ?? '';
  }

  get relationCode(): string {
    return this._relationCode;
  }

  set relationCode(relationCode: string) {
    this._relationCode = relationCode;
  }

  get startYear(): string {
    return this._startYear;
  }

  set startYear(year: string) {
    this._startYear = year;
  }

  get endYear(): string {
    return this._endYear;
  }

  set endYear(year: string) {
    this._endYear = year;
  }

  protected get filterValue(): YearRangeFilterValue {
    return {
      relationCode: this._relationCode,
      startYear: this._startYear,
      endYear: this._endYear,
    };
  }

  parseUrlSearchParams(params: URLSearchParams): void {
    this.relationCode = this._relationCode ?? TimelineConfig.relationAll;
    const start = params.getAll(this.getParamName('start'));
    const end = params.getAll(this.getParamName('end'));
    if (start.length && end.length) {
      this.startYear = start[0];
      this.endYear = end[0];
    }
  }

  addToUrlSearchParams(builder: UrlQueryBuilder): void {
    if (this._startYear && parseInt(this._startYear) > -9999) {
      builder.add(
        this.getParamName('start'),
        encodeURIComponent(this._startYear),
      );
    }
    if (this._endYear && parseInt(this._endYear) < 9999) {
      builder.add(this.getParamName('end'), encodeURIComponent(this._endYear));
    }
  }

  isEmpty(): boolean {
    return !this._startYear || !this._endYear || !this._relationCode;
  }

  setValue(value: YearRangeFilterValue): void {
    this._relationCode = value.relationCode;
    this._startYear = value.startYear;
    this._endYear = value.endYear;
  }

  removeValue(): Filter {
    return this.resetValues();
  }

  resetValues(): Filter {
    this._relationCode = '';
    this._startYear = '';
    this._endYear = '';
    return this;
  }

  addToSelectedFilterTerms(list: DisplayFilter[]): void {
    const filter: DisplayFilterYearRange = {
      filter: this,
      type: DisplayFilterType.yearRange,
      relation: this._relationCode,
      startYear: parseInt(this._startYear),
      endYear: parseInt(this._endYear),
    };
    list.push(filter);
  }

  isValueSelected(value: YearRangeFilterValue): boolean {
    return (
      this._relationCode === value.relationCode &&
      this._startYear === value.startYear &&
      this._endYear === value.endYear
    );
  }

  getSelectedFilterTerms(): string[] {
    return [];
  }

  clone(): Filter {
    const filter = new YearRangeFilter(this.id, this.type);
    filter.relationCode = this.relationCode;
    filter.startYear = this.startYear;
    filter.endYear = this.endYear;
    return filter;
  }

  protected getParamName(suffix: 'start' | 'end'): string {
    return YearRangeFilter.getFilterParamName(this._relationCode, suffix);
  }

  public static getFilterIdForRelation(relation: string): string {
    return `${TimelineConfig.filter}_${relation}`;
  }

  public static getFilterParamName(
    relation: string,
    suffix: 'start' | 'end',
  ): string {
    return `f.${TimelineConfig.filter}_${relation}.${suffix}`;
  }
}
