import { takeLatest, call, put } from 'redux-saga/effects';
import * as api from '../../../api';
import { handleRequestError } from '../../../model/api/request';
import { actions } from '../slice';

export function* loadServicesVersionData() {
  yield takeLatest(actions.loadServiceVersions.type, loadServicesVersions);
}

function* loadServicesVersions() {
  try {
    const jsonResponse = yield call(api.fetchAppVersions);
    yield put(actions.loadServiceVersionsSuccess({ json: jsonResponse }));
  } catch (error: any) {
    yield call(handleRequestError, actions.loadServiceVersionsError, error);
  }
}
