import MapConfig from '../../../../conf/map';
import TimelineConfig from '../../../../conf/timeline';
import {
  ConfigurationResponseFacet,
  SearchRequestFacet,
  FacetResponseItem,
  ConfigurationResponseFilterMap,
  SearchRequestFilterBase,
} from '../../api/responses';
import { Facet } from './Facet';
import { FacetTypes } from './FacetType';
import { GeoErrorFacet } from './GeoErrorFacet';
import { GeoFacet } from './GeoFacet';
import { RangeErrorFacet } from './RangeErrorFacet';
import { TermsFacet } from './TermsFacet';
import { YearRangeFacet } from './YearRangeFacet';

export class FacetFactory {
  public static createInstanceFrom(
    facetResponseJson: FacetResponseItem,
    facetRequestJson: SearchRequestFacet,
    facetConfiguration: ConfigurationResponseFacet,
    filter: SearchRequestFilterBase,
    filterConfigurations: ConfigurationResponseFilterMap,
  ): Facet {
    let instance = {
      [FacetTypes.terms]: TermsFacet,
      [FacetTypes.staticTerms]: TermsFacet,
      [FacetTypes.hierarchicalPath]: TermsFacet,
      [FacetTypes.yearRangeRelation]: YearRangeFacet,
    }[facetConfiguration.type];

    if (MapConfig.facets.includes(facetConfiguration.id)) {
      instance = GeoFacet;
    }

    if (MapConfig.noPositionFacet.includes(facetConfiguration.id)) {
      instance = GeoErrorFacet;
    }

    if (TimelineConfig.noRangeFacet.includes(facetConfiguration.id)) {
      instance = RangeErrorFacet;
    }

    return Facet.create(
      instance,
      facetResponseJson,
      facetRequestJson,
      facetConfiguration,
      filter,
      filterConfigurations,
    );
  }
}
