import { EntityResponseField, EntityResponseSubfield } from '../api/responses';
import { SubfieldData } from './SubfieldData';

export class JsonFieldsProcessor {
  protected _list: SubfieldData[] = [];
  protected _mapByField: Record<string, SubfieldData[]> = {};
  protected _mapByName: Record<string, SubfieldData[]> = {};
  protected _mapByRecIdx: Record<string, SubfieldData[]> = {};

  get list(): SubfieldData[] {
    return this._list;
  }

  get mapByField(): Record<string, SubfieldData[]> {
    return this._mapByField;
  }
  get mapByName(): Record<string, SubfieldData[]> {
    return this._mapByName;
  }
  get mapByRecIdx(): Record<string, SubfieldData[]> {
    return this._mapByRecIdx;
  }

  public process(json: EntityResponseField[]): void {
    this._list = json.reduce(
      (
        acc: SubfieldData[],
        fieldJson: EntityResponseField,
        recordIdx: number,
      ) => {
        const field = fieldJson.key;
        fieldJson.subs.forEach(
          (sub: EntityResponseSubfield, subfieldIdx: number) => {
            acc.push({ ...sub, field, recordIdx, subfieldIdx });
          },
        );
        return acc;
      },
      [],
    );
    this._list.forEach((item: SubfieldData) => {
      this.addToMapByField(item);
      this.addToMapByName(item);
      this.addToMapByRecIdx(item);
    });
  }

  protected addToMapByField(item: SubfieldData) {
    const field = item.field;
    if (!this._mapByField[field]) {
      this._mapByField[field] = [];
    }
    this._mapByField[field].push(item);
  }

  protected addToMapByName(item: SubfieldData) {
    const name = item.name;
    if (!this._mapByName[name]) {
      this._mapByName[name] = [];
    }
    this._mapByName[name].push(item);
  }

  protected addToMapByRecIdx(item: SubfieldData) {
    const recordIdx = item.recordIdx;
    if (!this._mapByRecIdx[recordIdx]) {
      this._mapByRecIdx[recordIdx] = [];
    }
    this._mapByRecIdx[recordIdx].push(item);
  }
}
