import styled, { css } from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';
import { font_Body_Semibold, font_Link } from '../../../styles';

export const linkInteractionStyles = css`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
export const linkStyles = css`
  ${font_Link};
`;

export const Link = styled.a`
  ${linkStyles};
  ${linkInteractionStyles};
`;

export const StyledLinkWithIcon = styled(Link)`
  display: flex;
  align-items: baseline;
`;

export const FooterNavLink = styled.a`
  ${font_Body_Semibold};
  ${linkInteractionStyles};
`;

export const FooterNavNoLink = styled.span`
  ${font_Body_Semibold};
  text-decoration: underline;
`;

export const RouterLink = styled(ReactRouterLink)`
  ${linkStyles};
  ${linkInteractionStyles};
  display: inline-block;
  white-space: nowrap;
`;
