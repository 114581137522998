// eslint-disable-next-line import/no-anonymous-default-export
export default {
  satzart: {
    collapsed: false,
    namespaces: {
      title: 'facet',
      values: 'satzart',
    },
    search: false,
  },
  entitycode: {
    collapsed: false,
    namespaces: {
      title: 'facet',
      values: 'entityCodes',
    },
    search: true,
  },
  gnd_systematik: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'gndSystematic',
    },
    search: true,
  },
  ddc_notation: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'ddcNotations',
    },
    search: true,
  },
  relationcodes: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'relationCodes',
    },
    search: true,
  },
  gndmul: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'gndmul',
      //values: 'relationCodes',
      //values: 'identifierSources',
      //values: 'languageCodes',
    },
    search: true,
  },
  identifikatoren: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'identifierSources',
    },
    search: true,
  },
  land: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'countryCodes',
    },
    search: true,
  },
  sprachencode: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'languageCodes',
    },
    search: true,
  },
  thema: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'topics',
    },
    search: true,
  },
  form: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'forms',
    },
    search: true,
  },
  instrumente_besetzung: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'musicalInstruments',
    },
    search: true,
  },
  musicalkey: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'musicalKeys',
    },
    search: true,
  },
  exil: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'exiles',
    },
    search: true,
  },
  beruf: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'professions',
    },
    search: true,
  },
  inhaltstyp: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'contentTypes',
    },
    search: false,
  },
  teilbestandskennzeichen: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'partialStock',
    },
    search: false,
  },
  nutzungskennzeichen: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'usageIndicator',
    },
    search: false,
  },
  zeit: {
    collapsed: true,
    namespaces: {
      title: 'facet',
    },
    search: true,
  },
  katalogisierungsquelle: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'catalogingSources',
    },
    search: false,
  },
  katalogisierende_institution: {
    collapsed: true,
    namespaces: {
      title: 'facet',
      values: 'catalogingInstitutions',
    },
    search: true,
  },
  fields_ss: {
    collapsed: false,
    namespaces: {
      title: 'facet',
    },
    search: false,
  },
};
