import styled, { css } from 'styled-components/macro';
import { Link as ReactRouterLink } from 'react-router-dom';
import { font_LangMenu, media } from '../../../styles';
import { UL, LI } from '../HTML';

const linkInteractionStyles = css`
  content: '';
  position: absolute;
  height: 2px;
  bottom: -2px;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.color.black};

  ${media.tablet_landscape_up`
    height: 3px;
    bottom: -2px;
  `}
`;

export const Nav = styled.nav`
  grid-area: language-switcher;
  justify-self: end;
  align-self: flex-start;
  overflow: hidden;
`;

export const List = styled(UL)`
  display: flex;
`;

export const ListItem = styled(LI)`
  flex: 1 0 auto;
  border-left: 1px solid ${props => props.theme.color.gray_language_border};
  &:last-child {
    border-right: 1px solid ${props => props.theme.color.gray_language_border};
  }

  span {
    position: relative;
  }
`;

const buttonStyles = css`
  ${font_LangMenu};
  display: inline-block;
  text-transform: uppercase;
  padding: ${props => props.theme.spacing._6}rem
    ${props => props.theme.spacing._14}rem
    ${props => props.theme.spacing._10}rem;

  ${media.tablet_landscape_up`
    padding: ${props => props.theme.spacing._11}rem
      ${props => props.theme.spacing._17}rem ${props =>
        props.theme.spacing._4}rem;
  `}
`;

export const NoLinkButton = styled.div`
  ${buttonStyles};
  font-weight: ${props => props.theme.font.weight.medium};

  span::after {
    ${linkInteractionStyles};
  }
`;
export const LinkButton = styled(ReactRouterLink)`
  ${buttonStyles};
  cursor: pointer;
  text-decoration: none;

  &:hover > span::after {
    ${linkInteractionStyles};
  }
`;
