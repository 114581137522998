import { autosuggestSaga, autosuggestSagaKey } from 'app/features/Autosuggest';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useInjectSaga } from 'redux-injectors';
import { searchTermSaga, searchTermSagaKey } from '../../features/Search';
import { AutoSuggest } from '../Autosuggest';
import { IconSearch, IconResetSearch } from '../Icon';
import { useSearchBoxHandlers } from './handlers';
import { messages } from './messages';
import { useSearchBoxState } from './state';
import { Wrapper, Button, Input, OuterWrapper } from './style';

export function SearchBox() {
  useInjectSaga({ key: searchTermSagaKey, saga: searchTermSaga });
  useInjectSaga({ key: autosuggestSagaKey, saga: autosuggestSaga });
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const autoSuggestRef = useRef(null);
  const state = useSearchBoxState();
  const handlers = useSearchBoxHandlers(state, inputRef, autoSuggestRef);

  return (
    <OuterWrapper $isFocused={state.focus}>
      <Wrapper>
        <Input
          name="search-input"
          ref={inputRef}
          autoComplete="off"
          type="text"
          value={state.term}
          onChange={handlers.onTermChange}
          onKeyUp={handlers.onInputKeyUp}
          onFocus={handlers.onInputFocus}
          onBlur={handlers.onInputBlur}
          aria-label={t(messages.inputAriaLabel)}
        />

        <Button
          name="search-reset-button"
          onClick={handlers.onTermReset}
          aria-hidden={!state.term}
          aria-label={t(messages.resetButtonTooltip)}
        >
          <IconResetSearch title={t(messages.resetButtonTooltip)} />
        </Button>

        <Button
          name="search-button"
          onClick={handlers.onSearch}
          aria-label={t(messages.searchButtonTooltip)}
        >
          <IconSearch title={t(messages.searchButtonTooltip)} />
        </Button>
      </Wrapper>

      {state.showSuggestions && (
        <AutoSuggest
          ref={autoSuggestRef}
          term={state.term}
          onSuggestionSelected={handlers.onSelectSuggestion}
          isFocused={state.listFocus}
          onBlur={handlers.onListBlur}
          onFocus={handlers.onListFocus}
        />
      )}
    </OuterWrapper>
  );
}
